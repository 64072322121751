import React, { useState, useEffect, useRef } from 'react';
import './AiStartPageNew.css';
import '../AiDataNew/AiDataNew.css';
import { openModal, showSignUpForm, showSignInForm, updateLogoutModalActive } from "../../redux/slices/formDetails";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { saveAiNewAsync, showAiNewresponse, createAiNewAsync, getWebsiteDataAsync } from "../../redux/slices/AiNewReducer";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";
import { createDuonut } from "../../services/activity";
import { addUserActivity, selectUserActivity, removeUserActivity, updateUserActivityElements, setUserActivity } from '../../redux/slices/userActivity';
// import { frontendUrl } from "../../services/constants";
import { EditText, EditTextarea } from "react-edit-text";
import { domain } from "../../services/constants";
import axios from "axios";
import { getFileSizeFromBase64 } from "../../utils";
import { getOpenEndedAiQuestionsAsync, getOpenEndedAiImageAsync } from "../../redux/slices/AiDuonutNewReducer";
import { toggleCreateFromScratchModalDuonut } from '../../redux/slices/CreateFromScratchReducer';
import { toggleChangeDuonutNameModal } from '../../redux/slices/DuonutNameReducer';
import { CSSTransition } from "react-transition-group";
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
// import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import MenuLeftBar from '../../Components/MenuLeftBar/MenuLeftBar';
import { creditDeduction } from '../../services/duonut';

var duonutId;
const AiStartPageNew = () => {
    const { isLoggedIn, credit } = useSelector((state) => state.user);
    const { settings } = useSelector(state => state.duonutSettings);
    // const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openLogout, setopenLogout] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const importref = useRef(null);
    const importref1 = useRef(null);

    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);

    useOutsideAlerter(logoutRef, handelClose);

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    const [errMess, seterrMess] = useState("");
    const [upgradeMess, setupgradeMess] = useState(false);
    const [selectedPage, setselectedPage] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(true); // State to toggle sidebar
    const [selectedType, setselectedType] = useState(0);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [title, settitle] = useState('');
    const [videotitle, setVideotitle] = useState('');
    const [videoObjective, setvideoObjective] = useState('');
    const [videoFeedbackDesire, setvideoFeedbackDesire] = useState('');
    const [uploadedVideoFile, setUploadedVideoFile] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [isUploaded, setisUploaded] = useState(false);
    const [preview, setPreview] = useState(null);
    // const { modalActive, modalAgain } = useSelector((state) => state.createFromScratch);
    const [isTransitionUp, setIsTransitionUp] = useState(true);
    // const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [typeformUrl, settypeformUrl] = useState("");

    useEffect(() => {
        if (!isLoggedIn) {
            setselectedPage(1);
        } else {
            dispatch(saveAiNewAsync());
        }
    }, [isLoggedIn]);
    const aiData = useSelector(showAiNewresponse);
    const aiData1 = aiData[0];

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Small screens: width <= 768px
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        setselectedPage(1);
        // if (aiData1) {
        //     if (aiData1.empty_message) {
        //         setselectedPage(1);
        //     } else if (!userProAccess) {
        //         navigate('/aidata');
        //     } else {
        //         if (userProAccess && userAccountType === "pro" && aiData1.length >= 12) {
        //             navigate('/aidata');
        //         } else if (userProAccess && userAccountType === "plus" && aiData1.length >= 5) {
        //             navigate('/aidata');
        //         } else if (userAccountType === "free") {
        //             navigate('/aidata');
        //         } else if (userProAccess && userAccountType === "enterprise" && aiData1.length >= 20) {
        //             navigate('/aidata');
        //         } else {
        //             setselectedPage(1);
        //         }
        //     }
        // }
    }, [aiData1])


    const handleGPTQuestionChange = (event) => {
        setgptQuestionInput(event.target.value);
        // console.log(event);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("gptBtn").click();
        }
    };

    const handleWebsiteGPTQuestionChange = (event) => {
        setgptQuestionInput(event.target.value);
        // console.log(event);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("websiteGptBtn").click();
        }
    };

    const handleClick = () => dispatch(openModal());

    const handleGPTSubmit = () => {
        const cost = 50;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }
            let formattedUrl = gptQuestionInput.trim();
            // Regular expression to validate URL
            const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;

            if (urlRegex.test(gptQuestionInput)) {
                // console.log('URL is valid:', gptQuestionInput);
            } else {
                seterrMess("Enter valid URL");
                setTimeout(() => {
                    seterrMess("");
                }, 3000); //miliseconds
                return null;
            }

            // let formattedUrl = gptQuestionInput.trim(); // Trim any extra spaces
            // Check if URL starts without "https://"
            if (!formattedUrl.startsWith('https://') && !formattedUrl.startsWith('http://')) {
                // console.log('Adding "https://" to the URL');
                formattedUrl = `https://${formattedUrl}`;
            }

            // console.log('Validated URL:', formattedUrl);

            setselectedPage(21);
            let timeoutId = setTimeout(() => {
                setselectedPage(22)
            }, 5000); //miliseconds

            // var ai_Data = {
            //     websiteUrl: formattedUrl,
            // }
            const formData = new FormData();
            formData.append('websiteUrl', formattedUrl);
            formData.append('cost', cost);
            dispatch(createAiNewAsync(formData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    window.location = "/aidata";
                } else if (res.status === "error") {
                    // console.log("qwerty")
                    setselectedPage(1);
                    // To cancel the timeout before it completes
                    clearTimeout(timeoutId);
                    setupgradeMess(true);
                    setTimeout(() => {
                        setupgradeMess(false);
                    }, 20000); //miliseconds
                }
            }).catch((e) => {
                console.log(e.message, e);
                setselectedPage(1);
                // To cancel the timeout before it completes
                clearTimeout(timeoutId);
                seterrMess("Error scanning the URL: access denied or AI access not permitted. Please contact the site administrator..");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
            });
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };

    const handleGPTSubmit1 = () => {
        const cost = 50;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }

            if (uploadedFile.length > 0) {
                // console.log('valid:');
            } else {
                seterrMess("Please upload files");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            if (title === "") {
                seterrMess("Please enter the Title/Company name.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            setselectedPage(21);
            let timeoutId = setTimeout(() => {
                setselectedPage(22)
            }, 5000); //miliseconds

            const formData = new FormData();
            uploadedFile.forEach((file, index) => {
                formData.append('uploadedFile', file); // Append each file individually
            });
            formData.append('title', title);
            formData.append('cost', cost);
            // console.log(formData)

            dispatch(createAiNewAsync(formData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    window.location = "/aidata";
                } else if (res.status === "error") {
                    // console.log("qwerty")
                    setselectedPage(1);
                    setUploadedFile([]);
                    // To cancel the timeout before it completes
                    clearTimeout(timeoutId);
                    setupgradeMess(true);
                    setTimeout(() => {
                        setupgradeMess(false);
                    }, 20000); //miliseconds
                } else if (res?.status === "invalid") {
                    setselectedPage(1);
                    setUploadedFile([]);
                    // To cancel the timeout before it completes
                    clearTimeout(timeoutId);
                    seterrMess(res?.message);
                    setTimeout(() => {
                        seterrMess("");
                    }, 5000); //miliseconds
                }
            }).catch((e) => {
                console.log(e.message, e);
                setUploadedFile([]);
                setselectedPage(1);
                // To cancel the timeout before it completes
                clearTimeout(timeoutId);
                seterrMess("Error scanning the file: Please upload a text-based document only.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
            });
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };

    const handleWebsiteGPTSubmit = () => {
        const cost = 20;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }
        if (credit >= cost) {
            let formattedUrl = gptQuestionInput.trim();
            // Regular expression to validate URL
            const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;

            if (urlRegex.test(gptQuestionInput)) {
                // console.log('URL is valid:', gptQuestionInput);
            } else {
                seterrMess("Enter valid URL");
                setTimeout(() => {
                    seterrMess("");
                }, 3000); //miliseconds
                return null;
            }

            // let formattedUrl = gptQuestionInput.trim(); // Trim any extra spaces
            // Check if URL starts without "https://"
            if (!formattedUrl.startsWith('https://') && !formattedUrl.startsWith('http://')) {
                // console.log('Adding "https://" to the URL');
                formattedUrl = `https://${formattedUrl}`;
            }

            // console.log('Validated URL:', formattedUrl);

            // setselectedPage(21);
            // let timeoutId = setTimeout(() => {
            //     setselectedPage(22)
            // }, 5000); //miliseconds

            setisLoading(true);
            const formData = new FormData();
            formData.append('websiteUrl', formattedUrl);

            dispatch(getWebsiteDataAsync(formData)).then((res) => {
                // console.log(res);
                if (res?.title && res?.description) {
                    setVideotitle(res.title);
                    setvideoObjective(res.description);
                    setisUploaded(true);
                    setisLoading(false);
                } else if (res.status === "error") {
                    // console.log("qwerty")
                    setselectedPage(1);
                    // To cancel the timeout before it completes
                    // clearTimeout(timeoutId);
                }
            }).catch((e) => {
                console.log(e.message, e);
                setselectedPage(1);
                // To cancel the timeout before it completes
                // clearTimeout(timeoutId);
                seterrMess("Error scanning the URL: access denied or AI access not permitted. Please contact the site administrator..");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
            });
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleGetProClick = () => {
        dispatch(toggleProMessageModalDuonut(true));
        dispatch(updateProMessageModalMessage('scan more websites'));
    };

    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        // console.log(event);
        // console.log(event.target.value);

        if (file) {
            const fileSize = file.size; // size in bytes
            const fileSizeInKB = fileSize / 1024; // size in KB
            const totalSizeInKB = uploadedFile.reduce((acc, file) => acc + file.size / 1024, 0);
            if (uploadedFile.some(f => f.name === file.name)) {
                seterrMess("This file has already been uploaded.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); // milliseconds
                return null;
            }
            // Check if the new file plus existing files exceeds 5 MB
            if (totalSizeInKB + fileSizeInKB > 10240) {
                seterrMess("Total upload size cannot exceed 10 MB.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); // milliseconds
                return null;
            }
        }

        setUploadedFile([...uploadedFile, file]);
        event.target.value = null;
    };
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
            return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        } else if (sizeInBytes >= 1024) {
            return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else {
            return sizeInBytes + " Bytes";
        }
    };

    const handleUploadVideoFile = (event) => {
        const file = event.target.files[0];
        // console.log(event);
        // console.log(event.target.value);

        if (file) {
            const fileSize = file.size; // size in bytes
            const fileSizeInKB = fileSize / 1024; // size in KB
            if (fileSizeInKB > 10240) {
                seterrMess("File size cannot exceed 10 MB.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); // milliseconds
                return null;
            }
        }

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            event.target.value = null;
            return null;
        }

        if (file.type.startsWith('image/')) {
            setisLoading(true);
            var reader = new FileReader();
            reader.onloadend = function () {
                var get_ans = {
                    image_uri: reader.result,
                }

                dispatch(getOpenEndedAiImageAsync(get_ans)).then((res1) => {
                    // console.log(res1);
                    if (res1?.image_description) {
                        // console.log(res1?.image_description);
                        setvideoObjective(res1?.image_description);
                        setPreview(reader.result);
                        setisLoading(false);
                        if (res1?.image_title) {
                            setVideotitle((res1?.image_title)?.replace(/[^a-zA-Z0-9\s_-]/g, "")?.trim());
                        }
                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });
                getFileSizeFromBase64(reader.result)
            };
            reader.readAsDataURL(file);

        } else {
            var reader = new FileReader();
            reader.onloadend = function () {
                setPreview(reader.result);
                setisLoading(false);
                getFileSizeFromBase64(reader.result)
            };
            reader.readAsDataURL(file);
        }

        setUploadedVideoFile(file);
    };

    const handleGPTSubmitVideo = (type) => {
        const cost = 20;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }

            if (uploadedVideoFile) {
                // console.log('valid:');
            } else {
                seterrMess("Please upload files");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            if (videotitle === "") {
                seterrMess("Please enter the Title/Company name.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            setselectedPage(23);
            let timeoutId = setTimeout(() => {
                setselectedPage(24)
            }, 5000); //miliseconds

            handleCreateDuonut(type);
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };

    let interval = null;
    const handleCreateDuonut = (type) => {
        const controller = new AbortController();
        interval = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval);
                createDuonut({
                    name: videotitle ? videotitle : "Untitled", duonut_category: 1, duonut_setting: {
                        ...settings, viewer_sliding: true,
                        show_ai_welcomepage: true,
                        ai_welcomepage_title: videotitle ? videotitle : "Untitled",
                        ai_welcomepage_description: `Thank you for taking the time to participate in this survey. There are no right or wrong answers - we just want to hear your honest thoughts and opinions. \n\n For this interactive survey, you'll be asked a few questions and you can type your responses or select the given answer options.`,
                    }
                }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;

                    var fileType;
                    if (uploadedVideoFile.type.startsWith('image/')) {
                        fileType = "image";
                    } else {
                        fileType = "video";
                    }

                    var reader = new FileReader();
                    reader.onloadend = function () {
                        // adds image_data in question_id
                        // console.log(e.target.files[0],reader.result);

                        const video_data = {
                            duonut_id: duonutId,
                            name: uploadedVideoFile.name,
                            uri: reader.result,
                        }
                        axios({
                            method: "POST",
                            url: `${domain}/duonut/upload_files`,
                            headers: {
                                "Content-Type": "application/json"
                            },
                            data: JSON.stringify(video_data),
                        }).then((res) => {
                            //console.log(res.data.file_url);
                            handleUpdateDuonut(res.data.file_url, fileType, type);

                        }).catch((e) => {
                            console.log(e.message, e);
                        });

                        getFileSizeFromBase64(reader.result)
                    };
                    reader.readAsDataURL(uploadedVideoFile);


                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval)
        };
    }

    const handleUpdateDuonut = (videoUrl, fileType, type) => {
        const cost = 20;
        var get_ans = {
            objective: videoObjective,
            feedbackDesired: videoFeedbackDesire,
            fileType: fileType,
        }

        dispatch(getOpenEndedAiQuestionsAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.questions) {
                // console.log(res?.questions);

                dispatch(setUserActivity({
                    activities: [{
                        elements: [],
                        backgroundColor: "white",
                        backgroundImage: "",
                    }]
                }));

                if (fileType === "image") {
                    var masterData = {
                        elements: [
                            {
                                id: 1,
                                elementType: 'Text',
                                transparency: 1,
                                zIndex: 1,
                                order: 1,
                                posX: 0,
                                posY: 30,
                                height: "auto",
                                width: "100%",
                                elementAttributes: {
                                    color: '#000000', text: videotitle ? videotitle : "Set Title", formattedText: [], fontFamily: "Poppins",
                                    fontWeight: 800, fontSize: 24, textAlign: "center"
                                },
                            },
                            {
                                id: 2,
                                elementType: 'Image',
                                transparency: 1,
                                zIndex: 2,
                                order: 2,
                                posX: 150,
                                posY: 100,
                                height: 350,
                                width: 700,
                                elementAttributes: {
                                    url: videoUrl,
                                },
                            },
                            {
                                id: 3,
                                elementType: 'Button',
                                transparency: 1,
                                zIndex: 3,
                                order: 3,
                                posX: 410,
                                posY: 500,
                                height: "auto",
                                width: 200,
                                elementAttributes: {
                                    color: '#ffffff', text: "Next", fontFamily: "Poppins",
                                    fontWeight: 600, fontSize: 16, backgroundColor: "#000000", border: "1px solid #000000",
                                    textAlign: "center", borderRadius: "5px"
                                },
                                elementHoverAttributes: {
                                    color: '#ffffff', backgroundColor: "blue", border: "1px solid #000000"
                                },
                            }
                        ],
                        backgroundColor: "#ffffff",
                        backgroundImage: "",
                        name: "Step 1",
                    };
                    dispatch(addUserActivity({ ...masterData, name: `Step 1` }));
                } else {
                    var masterData = {
                        elements: [
                            {
                                id: 1,
                                elementType: 'Text',
                                transparency: 1,
                                zIndex: 1,
                                order: 1,
                                posX: 0,
                                posY: 30,
                                height: "auto",
                                width: "100%",
                                elementAttributes: {
                                    color: '#000000', text: videotitle ? videotitle : "Set Title", formattedText: [], fontFamily: "Poppins",
                                    fontWeight: 800, fontSize: 24, textAlign: "center"
                                },
                            },
                            {
                                id: 2,
                                elementType: 'Video',
                                transparency: 1,
                                zIndex: 2,
                                order: 2,
                                posX: 150,
                                posY: 100,
                                height: 350,
                                width: 800,
                                elementAttributes: {
                                    url: videoUrl, type: "video",
                                },
                            },
                            {
                                id: 3,
                                elementType: 'Button',
                                transparency: 1,
                                zIndex: 3,
                                order: 3,
                                posX: 410,
                                posY: 500,
                                height: "auto",
                                width: 200,
                                elementAttributes: {
                                    color: '#ffffff', text: "Next", fontFamily: "Poppins",
                                    fontWeight: 600, fontSize: 16, backgroundColor: "#000000", border: "1px solid #000000",
                                    textAlign: "center", borderRadius: "5px"
                                },
                                elementHoverAttributes: {
                                    color: '#ffffff', backgroundColor: "blue", border: "1px solid #000000"
                                },
                            }
                        ],
                        backgroundColor: "#ffffff",
                        backgroundImage: "",
                        name: "Step 1",
                    };
                    dispatch(addUserActivity({ ...masterData, name: `Step 1` }));
                }

                res?.questions.map((item, i) => {
                    var masterData = {
                        elements: [
                            {
                                id: 1,
                                elementType: 'QuestionAnswer',
                                transparency: 1,
                                zIndex: 1,
                                order: 1,
                                posX: 100,
                                posY: 100,
                                height: "auto",
                                width: 800,
                                elementAttributes: {
                                    text: item[0] ? item[0] : "Type your question?",
                                    height: "400px",
                                    color: '#000000', fontFamily: "Poppins",
                                    fontWeight: 600, fontSize: 24,
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #F0EAEA",
                                    textAlign: "left", borderRadius: "0px",
                                    placeholder: "Type your answer here.",
                                    openEnded: true,
                                    openEndedObjective: videoObjective,
                                    openEndedFeedbackDesired: videoFeedbackDesire,
                                },
                            },
                        ],
                        backgroundColor: "#ffffff",
                        backgroundImage: "",
                        name: "Step 2",
                    };
                    dispatch(addUserActivity({ ...masterData, name: `Step ${i + 2}` }));
                })

                dispatch(removeUserActivity({ idx: 0 }));
                dispatch(selectUserActivity(0));
                const name = type === 20 ? "Logo Feedback" : type === 21 ? "Creative Image Feedback" : "Creative Video Feedback";
                var creditData = {
                    name: name,
                    message: `Creating Duonut - ${videotitle} - ${duonutId}`,
                    cost: cost,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });


                setTimeout(() => {
                    setisLoading(false);
                    navigate(`/design/${duonutId}`);
                }, 1000);

            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    const handleWebsiteGenerateDuonut = () => {
        const cost = 20;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }

            if (videotitle === "") {
                seterrMess("Please enter the Company name.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            setselectedPage(25);
            let timeoutId = setTimeout(() => {
                setselectedPage(26)
            }, 5000); //miliseconds

            handleWebsiteCreateDuonut();
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }
    };

    let interval1 = null;
    const handleWebsiteCreateDuonut = () => {
        const controller = new AbortController();
        interval1 = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval1);
                createDuonut({
                    name: videotitle ? videotitle : "Untitled", duonut_category: 1, duonut_setting: {
                        ...settings, viewer_sliding: true,
                        show_ai_welcomepage: true,
                        ai_welcomepage_title: videotitle ? videotitle : "Untitled",
                        ai_welcomepage_description: `Thank you for taking the time to participate in this survey. There are no right or wrong answers - we just want to hear your honest thoughts and opinions. \n\n For this interactive survey, you'll be asked a few questions and you can type your responses or select the given answer options.`,
                    }
                }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval1);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;

                    handleWebsiteUpdateDuonut();

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval1)
        };
    }
    function isLink(text) {
        const urlPattern = /(https?:\/\/|www\.|[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6})/i;
        return urlPattern.test(text);
    }
    const handleWebsiteUpdateDuonut = () => {
        const cost = 20;
        var get_ans = {
            objective: videoObjective,
            feedbackDesired: videoFeedbackDesire,
            fileType: `website url: ${gptQuestionInput}`,
        }

        dispatch(getOpenEndedAiQuestionsAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.questions) {
                // console.log(res?.questions);

                dispatch(setUserActivity({
                    activities: [{
                        elements: [],
                        backgroundColor: "white",
                        backgroundImage: "",
                    }]
                }));

                var masterData = {
                    elements: [
                        {
                            id: 1,
                            elementType: 'Text',
                            transparency: 1,
                            zIndex: 1,
                            order: 1,
                            posX: 130,
                            posY: 80,
                            height: "auto",
                            width: "750px",
                            elementAttributes: {
                                color: '#000000', text: "Go to the homepage and examine the messaging to understand people's reactions.", formattedText: [], fontFamily: "Poppins",
                                fontWeight: 800, fontSize: 24, textAlign: "center"
                            },
                        },
                        {
                            id: 2,
                            elementType: 'Text',
                            transparency: 1,
                            zIndex: 2,
                            order: 2,
                            posX: 210,
                            posY: 260,
                            height: "auto",
                            width: "600px",
                            elementAttributes: {
                                color: isLink(gptQuestionInput) ? 'blue' : '#000000',
                                text: gptQuestionInput,
                                formattedText: [{
                                    "insert": gptQuestionInput,
                                    "attributes": {
                                        "font_link": gptQuestionInput,
                                        "font_color": isLink(gptQuestionInput) ? 'blue' : 'inherit',
                                        "font_underline": isLink(gptQuestionInput)
                                    }
                                }],
                                fontFamily: "Poppins",
                                fontWeight: 600, fontSize: 22, textAlign: "center"
                            },
                        },
                        {
                            id: 3,
                            elementType: 'Button',
                            transparency: 1,
                            zIndex: 3,
                            order: 3,
                            posX: 410,
                            posY: 500,
                            height: "auto",
                            width: 200,
                            elementAttributes: {
                                color: '#ffffff', text: "Next", fontFamily: "Poppins",
                                fontWeight: 600, fontSize: 16, backgroundColor: "#000000", border: "1px solid #000000",
                                textAlign: "center", borderRadius: "5px"
                            },
                            elementHoverAttributes: {
                                color: '#ffffff', backgroundColor: "blue", border: "1px solid #000000"
                            },
                        }
                    ],
                    backgroundColor: "#ffffff",
                    backgroundImage: "",
                    name: "Step 1",
                };
                dispatch(addUserActivity({ ...masterData, name: `Step 1` }));


                res?.questions.map((item, i) => {
                    var masterData = {
                        elements: [
                            {
                                id: 1,
                                elementType: 'QuestionAnswer',
                                transparency: 1,
                                zIndex: 1,
                                order: 1,
                                posX: 100,
                                posY: 100,
                                height: "auto",
                                width: 800,
                                elementAttributes: {
                                    text: item[0] ? item[0] : "Type your question?",
                                    height: "400px",
                                    color: '#000000', fontFamily: "Poppins",
                                    fontWeight: 600, fontSize: 24,
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #F0EAEA",
                                    textAlign: "left", borderRadius: "0px",
                                    placeholder: "Type your answer here.",
                                    openEnded: true,
                                    openEndedObjective: videoObjective,
                                    openEndedFeedbackDesired: videoFeedbackDesire,
                                },
                            },
                        ],
                        backgroundColor: "#ffffff",
                        backgroundImage: "",
                        name: "Step 2",
                    };
                    dispatch(addUserActivity({ ...masterData, name: `Step ${i + 2}` }));
                })

                dispatch(removeUserActivity({ idx: 0 }));
                dispatch(selectUserActivity(0));
                var creditData = {
                    name: "Website Feedback",
                    message: `Creating Duonut - ${videotitle} - ${duonutId}`,
                    cost: cost,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setTimeout(() => {
                    setisLoading(false);
                    navigate(`/design/${duonutId}`);
                }, 1000);

            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    const handleCustomerGenerateDuonut = () => {
        const cost = 20;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }

            if (videotitle === "") {
                seterrMess("Please enter the Product / service.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
                return null;
            }

            setselectedPage(27);
            let timeoutId = setTimeout(() => {
                setselectedPage(28)
            }, 5000); //miliseconds

            handleCustomerCreateDuonut();
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }
    };

    let interval2 = null;
    const handleCustomerCreateDuonut = () => {
        const controller = new AbortController();
        interval2 = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval2);
                createDuonut({
                    name: videotitle ? videotitle : "Untitled", duonut_category: 1, duonut_setting: {
                        ...settings, viewer_sliding: true,
                        show_ai_welcomepage: true,
                        ai_welcomepage_title: videotitle ? videotitle : "Untitled",
                        ai_welcomepage_description: `Thank you for taking the time to participate in this survey. There are no right or wrong answers - we just want to hear your honest thoughts and opinions. \n\n For this interactive survey, you'll be asked a few questions and you can type your responses or select the given answer options.`,
                    }
                }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval2);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;

                    handleCustomerUpdateDuonut();

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval2)
        };
    }

    const handleCustomerUpdateDuonut = () => {
        const cost = 20;
        var get_ans = {
            objective: videoObjective,
            feedbackDesired: videoFeedbackDesire,
            fileType: `product name: ${videotitle}`,
        }

        dispatch(getOpenEndedAiQuestionsAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.questions) {
                // console.log(res?.questions);

                dispatch(setUserActivity({
                    activities: [{
                        elements: [],
                        backgroundColor: "white",
                        backgroundImage: "",
                    }]
                }));

                res?.questions.map((item, i) => {
                    var masterData = {
                        elements: [
                            {
                                id: 1,
                                elementType: 'QuestionAnswer',
                                transparency: 1,
                                zIndex: 1,
                                order: 1,
                                posX: 100,
                                posY: 100,
                                height: "auto",
                                width: 800,
                                elementAttributes: {
                                    text: item[0] ? item[0] : "Type your question?",
                                    height: "400px",
                                    color: '#000000', fontFamily: "Poppins",
                                    fontWeight: 600, fontSize: 24,
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #F0EAEA",
                                    textAlign: "left", borderRadius: "0px",
                                    placeholder: "Type your answer here.",
                                    openEnded: true,
                                    openEndedObjective: videoObjective,
                                    openEndedFeedbackDesired: videoFeedbackDesire,
                                },
                            },
                        ],
                        backgroundColor: "#ffffff",
                        backgroundImage: "",
                        name: "Step 1",
                    };
                    dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
                })

                dispatch(removeUserActivity({ idx: 0 }));
                dispatch(selectUserActivity(0));

                var creditData = {
                    name: "Customer Expirence",
                    message: `Creating Duonut - ${videotitle} - ${duonutId}`,
                    cost: cost,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setTimeout(() => {
                    setisLoading(false);
                    navigate(`/design/${duonutId}`);
                }, 1000);

            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }


    const handleVisualStimuliGenerateDuonut = () => {
        const cost = 20;
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (credit >= cost) {
            // if (!isLoggedIn) {
            //     handleClick();
            //     dispatch(showSignInForm());
            //     return null;
            // }

            // if (videotitle === "") {
            //     seterrMess("Please enter the Product / service.");
            //     setTimeout(() => {
            //         seterrMess("");
            //     }, 5000); //miliseconds
            //     return null;
            // }

            setselectedPage(29);
            let timeoutId = setTimeout(() => {
                setselectedPage(30)
            }, 5000); //miliseconds

            handleVisualStimuliCreateDuonut();
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }
    };

    let interval3 = null;
    const handleVisualStimuliCreateDuonut = () => {

        const controller = new AbortController();
        interval3 = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval3);
                createDuonut({
                    name: "Visual Stimuli", duonut_category: 1, duonut_setting: {
                        ...settings, viewer_sliding: true,
                        open_ended_conversation_limit: 2,
                        show_ai_welcomepage: true,
                        ai_welcomepage_title: "Visual Stimuli",
                        ai_welcomepage_description: `Thank you for taking the time to participate in this survey. There are no right or wrong answers - we just want to hear your honest thoughts and opinions.\n\n For this interactive survey, you'll be asked a few questions and you can type your responses or select the given answer options.`,
                    }
                }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval3);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;

                    handleVisualStimuliUpdateDuonut();

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval3)
        };

    }

    const handleVisualStimuliUpdateDuonut = () => {
        const cost = 20;
        dispatch(setUserActivity({
            activities: [{
                elements: [],
                backgroundColor: "white",
                backgroundImage: "",
            }]
        }));

        var masterData = {
            elements: [
                {
                    id: 1,
                    elementType: 'RadioImage',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 200,
                    posY: 150,
                    height: "auto",
                    width: 'auto',
                    elementAttributes: {
                        text: "Which of the following would you purchase?",
                        options: ["Strut", "LuxeStep", "Velvet"],
                        optionsImage: ["https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/17.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/18.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/19.png"],
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16, backgroundColor: "#FFFFFF",
                        border: "0px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "5px 5px 5px 5px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        imageWidth: "150px",
                        imagePadding: "5px 15px 5px 5px",
                        fieldPosition: "row",
                        gap: "25px",
                        openEnded: true,
                        openEndedObjective: videoObjective,
                        openEndedFeedbackDesired: videoFeedbackDesire,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                },
            ],
            backgroundColor: "#ffffff",
            backgroundImage: "",
            name: "Step 1",
        };
        dispatch(addUserActivity({ ...masterData, name: `Step 1` }));
        var masterData2 = {
            elements: [
                {
                    id: 2,
                    elementType: 'RadioMultiImage',
                    transparency: 2,
                    zIndex: 2,
                    order: 2,
                    posX: 50,
                    posY: 10,
                    height: "auto",
                    width: '920px',
                    elementAttributes: {
                        text: "Which of the following would you purchase?",
                        options: ["Strut", "LuxeStep", "Velvet", "Gait", "Solace", "Stride", "Evoque", "Arcus"],
                        description: ["Step out with bold confidence in every stride.", "Luxury meets comfort in every graceful step.", "Smooth as velvet, crafted for elegance and ease.", "Perfectly balanced to match your every move.", "Find comfort and style in perfect harmony.", "Empower your steps with unmatched precision.", "Designed to evoke style and sophistication.", "Inspired by the beauty of arches in motion."],
                        description2: ["Price: $85", "Price: $120", "Price: $100", "Price: $95", "Price: $110", "Price: $90", "Price: $130", "Price: $105"],
                        optionsImage: ["https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/17.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/18.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/19.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/20.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/21.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/22.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/23.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/24.png"],
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 400, fontSize: 16, backgroundColor: "#FFFFFF",
                        border: "0px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "5px 5px 5px 5px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        imageWidth: "130px",
                        imagePadding: "5px 15px 5px 5px",
                        fieldPosition: "row",
                        gap: "20px",
                        descriptionFontSize: 12,
                        descriptionFontWeight: "400",
                        descriptionColor: '#9B9B9B',
                        description2FontSize: 16,
                        description2FontWeight: "400",
                        description2Color: '#000000',
                        openEnded: true,
                        openEndedObjective: videoObjective,
                        openEndedFeedbackDesired: videoFeedbackDesire,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                },
            ],
            backgroundColor: "#ffffff",
            backgroundImage: "",
            name: "Step 2",
        };
        dispatch(addUserActivity({ ...masterData2, name: `Step 2` }));

        dispatch(removeUserActivity({ idx: 0 }));
        dispatch(selectUserActivity(0));

        var creditData = {
            name: "Visual Stimuli",
            message: `Creating Duonut - Visual Stimuli - ${duonutId}`,
            cost: cost,
        }
        creditDeduction(creditData, (err, res) => {
            if (err) return console.log(err.response);
            // console.log(res);
        });

        setTimeout(() => {
            setisLoading(false);
            navigate(`/design/${duonutId}`);
        }, 1000);

    }

    const handleButtonClick = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        document.getElementById('fileInput').click();
    };
    const handleUploadButtonClick = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        const cost = 20;
        if (credit >= cost) {
            document.getElementById('GPT-input_box3').click();
        }
        else {
            seterrMess("There is no sufficient credit to perform this action.");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }
    }

    const handleTypeSelect = (type) => {
        setIsTransitionUp(true); // Transition up
        setselectedType(type); // Set the selected type
    };

    const handleBackButton = () => {
        setIsTransitionUp(false); // Transition down

        setTimeout(() => {
            // setselectedType(0); // Reset the selected type after transition completes
            const t = selectedType === 11 || selectedType === 12 ? 0 : selectedType === 10 || selectedType === 20 || selectedType === 30 || selectedType === 40 || selectedType === 50 ? 0 : 0;
            setselectedType(t);
        }, 200);
    };

    const resetImage = () => {
        setPreview(null); // Reset preview
        setUploadedVideoFile(""); // Reset uploaded file

        document.querySelector('#GPT-input_box3').value = '';
        // document.querySelector('#GPT-input_box3').click();
    };

    const convertbtn = () => {
        const typeformRegex = /https:\/\/[a-z0-9]+\.typeform\.com\/to\/([A-Za-z0-9]+)/;

        const match = typeformUrl.match(typeformRegex);

        if (match && match[1]) {
            // Extracted ID
            // console.log(`Extracted ID: ${match[1]}`);
            importref1.current.click();

            const token = localStorage.getItem("access");
            axios({
                method: "POST",
                url: `${domain}/duonut/get_typeform_project`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    project_id: match[1],
                },
            }).then(data => {
                // console.log('typeform Data:', data?.data?.typeform_response);
                handleTypeformCreateDuonut(data?.data?.typeform_response);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        } else {
            // Invalid URL
            seterrMess("URL not valid");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };

    let interval4 = null;
    const handleTypeformCreateDuonut = (typeformData) => {

        const controller = new AbortController();
        interval4 = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval4);
                createDuonut({
                    name: typeformData?.title, duonut_category: 1, duonut_setting: {
                        ...settings, viewer_sliding: true,
                    }
                }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval4);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;

                    handleTypeformUpdateDuonut(typeformData);

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval4)
        };

    }

    const handleTypeformUpdateDuonut = (typeformData) => {
        // const cost = 20;
        dispatch(setUserActivity({
            activities: [{
                elements: [],
                backgroundColor: "white",
                backgroundImage: "",
            }]
        }));

        typeformData?.fields.map((item, i) => {
            const element = selectElement(item);
            var masterData = {
                elements: [element],
                backgroundColor: "#ffffff",
                backgroundImage: "",
                name: "Step 1",
            };
            dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
        })

        dispatch(removeUserActivity({ idx: 0 }));
        dispatch(selectUserActivity(0));

        // var creditData = {
        //     name: "Import",
        //     message: `Import Typeform Form to Duonut - ${duonutId}`,
        //     cost: cost,
        // }
        // creditDeduction(creditData, (err, res) => {
        //     if (err) return console.log(err.response);
        //     // console.log(res);
        // });

        setTimeout(() => {
            setisLoading(false);
            navigate(`/design/${duonutId}`);
        }, 1000);

    }

    function selectElement(jsonElement) {

        if (jsonElement?.type === "opinion_scale" || jsonElement?.type === "nps") {
            var element = {
                id: 1,
                elementType: 'Nps',
                transparency: 1,
                zIndex: 1,
                order: 1,
                posX: 100,
                posY: 200,
                height: "auto",
                width: "auto",
                elementAttributes: {
                    text: jsonElement?.title,
                    color: "white",
                    maxLength: jsonElement?.properties?.steps - 1,
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: 16,
                    backgroundColor: "#4a90e2",
                    textAlign: "center",
                    borderRadius: '10px',
                    margin: "5px 5px 5px 5px",
                    padding: "0px 0px 0px 0px",
                    labelFontSize: 20,
                    labelFontWeight: "400",
                    labelColor: '#000000',
                    size: 70,
                    gap: "30px",
                    openEnded: false,
                    openEndedObjective: "",
                    openEndedFeedbackDesired: "",
                },
                elementHoverAttributes: {
                    backgroundColor: "black", selectedBackgroundColor: "#7ed321"
                },
            }
            return element;
        }

        if (jsonElement?.type === "multiple_choice") {
            var element = {
                id: 1,
                elementType: 'RadioGroup',
                transparency: 1,
                zIndex: 1,
                order: 1,
                posX: 230,
                posY: 100,
                height: "auto",
                width: 550,
                elementAttributes: {
                    text: jsonElement?.title,
                    options: jsonElement?.properties?.choices.map((item) => item.label),
                    other: false,
                    color: '#ffffff', fontFamily: "Poppins",
                    fontWeight: 600, fontSize: 16,
                    backgroundColor: "#000000",
                    border: "1px solid #000000",
                    textAlign: "center", borderRadius: "5px",
                    margin: "5px 5px 5px 5px",
                    padding: "10px 10px 10px 10px",
                    labelFontSize: 20,
                    labelFontWeight: "400",
                    labelColor: '#000000',
                    gap: "25px",
                    openEnded: false,
                    openEndedObjective: "",
                    openEndedFeedbackDesired: "",
                },
                elementHoverAttributes: {
                    color: '#ffffff', backgroundColor: "#3184c9", border: "1px solid #000000"
                },
            }
            return element;
        }

        if (jsonElement?.type === "yes_no") {
            var element = {
                id: 1,
                elementType: 'RadioGroup',
                transparency: 1,
                zIndex: 1,
                order: 1,
                posX: 310,
                posY: 160,
                height: "auto",
                width: 400,
                elementAttributes: {
                    text: jsonElement?.title,
                    options: ["Yes", "No"],
                    other: false,
                    color: '#ffffff', fontFamily: "Poppins",
                    fontWeight: 600, fontSize: 16,
                    backgroundColor: "#000000",
                    border: "1px solid #000000",
                    textAlign: "center", borderRadius: "5px",
                    margin: "5px 5px 5px 5px",
                    padding: "10px 10px 10px 10px",
                    labelFontSize: 20,
                    labelFontWeight: "400",
                    labelColor: '#000000',
                    gap: "25px",
                    openEnded: false,
                    openEndedObjective: "",
                    openEndedFeedbackDesired: "",
                },
                elementHoverAttributes: {
                    color: '#ffffff', backgroundColor: "#3184c9", border: "1px solid #000000"
                },
            }
            return element;
        }

        if (jsonElement?.type === "dropdown") {
            var element = {
                id: 1,
                elementType: 'Dropdown',
                transparency: 1,
                zIndex: 1,
                order: 1,
                posX: 260,
                posY: 210,
                height: "auto",
                width: 500,
                elementAttributes: {
                    text: jsonElement?.title,
                    options: jsonElement?.properties?.choices.map((item) => item.label),
                    fieldPosition: "column",
                    color: '#000000', fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 18, backgroundColor: "#ffffff",
                    border: "1px solid #000000", gap: "30px",
                    textAlign: "left", borderRadius: "5px",
                },
            }
            return element;
        }

        if (jsonElement?.type === "long_text") {
            var element = {
                id: 1,
                elementType: 'TextArea',
                transparency: 1,
                zIndex: 1,
                order: 1,
                posX: 100,
                posY: 200,
                height: "auto",
                width: 800,
                elementAttributes: {
                    color: '#000000', text: jsonElement?.title, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 18, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column", gap: "30px",
                    placeholder: "Type your text here."
                },
            }
            return element;
        }

        // Return an empty array or null if 'fields' not found in any format
        return {};
    }


    return (
        // <div className='ASPN1App'>
        <div className='duonutContainer' style={{ display: "flex" }}>
            <MenuLeftBar />

            <button ref={importref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="importModal" tabIndex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "550px" }}>
                    <div className="modal-content" style={{ marginTop: "30vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={importref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div>
                                <div style={{ padding: "1rem 0rem", fontSize: "1rem", fontWeight: "600" }}>Enter Typeform URL</div>
                                <input
                                    style={{ maxWidth: " 45rem", height: "7vh", fontSize: "16px" }}
                                    id="GPT-input_box"
                                    className='Ai-input_box'
                                    type="text"
                                    placeholder="https://myusername.typeform.com/to/ITPUqM"
                                    autoFocus
                                    onKeyUp={(e) => {
                                        settypeformUrl(e.target.value);
                                    }}
                                />
                            </div>

                            <br></br>
                            {errMess && <div style={{ color: "red", padding: "5px" }}>{errMess}</div>}

                            <div style={{ width: "100%", textAlign: "center" }}>
                                <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                    onClick={() => importref1.current.click()}>Cancel</button>
                                <button className='QRC1Continuebtn' onClick={() => convertbtn()}>Convert to Duonut</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ASPN1template' style={{ minWidth: "95%", overflowY: "scroll" }}>

                <div className='ASPN1container'>
                    {selectedPage === 0 &&
                        <div style={{ position: "absolute", display: "flex", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                            <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
                        </div>}
                    {selectedPage === 1 && <div className='' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <CSSTransition
                            in={selectedType === 0}
                            timeout={200}
                            classNames={isTransitionUp ? "slide-up" : "slide-down"}
                            unmountOnExit
                        >
                            <div className={`transition-div ${selectedType === 0 ? "in" : "out"}`}
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh" }}>
                                <div className="CFS1MainContainer market-research" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "25px", margin: "0 15%" }}>
                                    <h1 style={{ marginTop: "2rem" }}>Choose any task</h1>
                                    <div className="CFS1templateContainer row" style={{ marginTop: "5rem",marginTop: '2rem',
                                                display: 'flex',
                                                flexDirection: 'column' }}>
                                            <div  className='col-lg' style={{display: 'flex',justifyContent:"center"}}>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => navigate("/ai")} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> */}
                                                        <img src="/assets/icons/ai-survey-maker.png" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }} atl="ai-survey-maker">AI Survey Maker</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Create AI-powered surveys quickly and gather insightful responses with ease.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 30 </span>
                                                    </div>
                                                </div>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(11)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>NPS</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Instantly understand your website’s appeal with feedback from buyers.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 50 </span>
                                                    </div>
                                                </div>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(12)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-upload" aria-hidden="true" style={{ color: "#337ab7", fontSize: "1.5rem", paddingBottom: "5px" }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Upload</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Easily upload your documents and get quick feedback.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 50 </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  className='col-lg-4' style={{display: 'flex'}}>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }} onClick={() => setselectedType(30)}>
                                                        {/* <img src="/assets/icons/nps-logo.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} /> */}
                                                        <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "1.5rem", color: "#337ab7", paddingBottom: "5px" }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Website feedback</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Get instant feedback on your website from your target buyers.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />&nbsp; 20 </span>
                                                    </div>
                                                </div>
                                                {/*<div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(20)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Logo Feedback</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Gather feedback on your logo designs.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                                    </div>
                                                </div> */}
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(21)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-file-image-o" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Creative Image Feedback</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 18px", margin: "0px 10px" }}>Gather feedback on your images or other designs.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                                    </div>
                                                </div>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(22)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-file-video-o" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Creative Video Feedback</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Gather feedback on your videos designs.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  className='col-lg' style={{display: 'flex', justifyContent:"center"}}>
                                            <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(40)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> */}
                                                        <img src="/assets/icons/customerExp.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Customer experience</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Get in-depth feedback from customers on your product or service.</span>
                                                        <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                                    </div>
                                                </div>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => handleVisualStimuliGenerateDuonut()} style={{ height: "14rem", gap: "6px", fontSize: "20px", textAlign: "center", padding: "10px 5px" }}>
                                                        {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> */}
                                                        <img src="/assets/icons/visualstimuli.svg" alt="duonut" loading="lazy" style={{ width: "50px" }} />
                                                        <span style={{ color: "#337ab7", fontWeight: "bold", }}>Visual Stimuli</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px", }}>Ask open ended questions to test and iterate your product & messaging</span>
                                                        <span style={{ fontSize: "14px", padding: '15px 0px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />&nbsp; 20 </span>
                                                    </div>
                                                </div>
                                                {/* <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => window.open("https://duonut.com/templates", "_blank")} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-empire" aria-hidden="true" style={{ fontSize: "2.5rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Templates</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Create traditional surveys with closed-choice questions and typed responses</span>
                                                    </div>
                                                </div>
                                                <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}
                                                        style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="none">
                                                            <path fill="#3184C9" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Start from scratch</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Build your vision and from the ground up with no limits.</span>
                                                    </div>
                                                </div>*/}
                                                {/* <div className="CFS1templateBlock col-lg-3" style={{ width: "14rem" }}>
                                                    <div className="CFS1startscratch box_shadow" onClick={() => importref.current.click()} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "2.5rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        <span style={{ color: "#337ab7", fontWeight: "bold" }}>Import it now</span>
                                                        <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Import Typeform surveys to your Duonut.</span>
                                                    </div>
                                                </div>  */}
                                            </div>
                                        </div>

                                </div>


                                {/* Previous seperate sections  */}
                                {/* <div className="CFS1MainContainer market-research" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Market Research</h1>
                                    <div>Choose a Market Research Template</div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => handleVisualStimuliGenerateDuonut()} style={{ height: "14rem", gap: "6px", fontSize: "20px", textAlign: "center", padding: "10px 5px" }}>
                                                {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                                <img src="/assets/icons/visualstimuli.svg" alt="duonut" loading="lazy" style={{ width: "50px" }} />
                                                <span style={{ color: "#337ab7", fontWeight: "bold", }}>Visual Stimuli</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px", }}>Ask open ended questions to test and iterate your product & messaging</span>
                                                <span style={{ fontSize: "14px", padding: '15px 0px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />&nbsp; 20 </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="CFS1MainContainer communication-feedback" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Outward Communication Feedback</h1>
                                    <div>Choose a Outward Communication Feedback Template</div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>

                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }} onClick={() => setselectedType(30)}>
                                                {/* <img src="/assets/icons/nps-logo.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} /> 
                                                <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "1.5rem", color: "#337ab7", paddingBottom: "5px" }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Website feedback</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Get instant feedback on your website from your target buyers.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />&nbsp; 20 </span>
                                            </div>
                                        </div>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(12)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-upload" aria-hidden="true" style={{ color: "#337ab7", fontSize: "1.5rem", paddingBottom: "5px" }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Upload</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Easily upload your documents and get quick feedback.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 50 </span>
                                            </div>
                                        </div>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(20)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Logo Feedback</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Gather feedback on your logo designs.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="CFS1MainContainer creative-feedback" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Ads & Creative Feedback</h1>
                                    <div>Choose an Ads & Creative Feedback Template </div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>

                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(21)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-file-image-o" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Creative Image Feedback</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 18px", margin: "0px 10px" }}>Gather feedback on your images or other designs.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                            </div>
                                        </div>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(22)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-file-video-o" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Creative Video Feedback</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Gather feedback on your videos designs.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="CFS1MainContainer service-feedback" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Post Sale/Service Feedback</h1>
                                    <div>Choose a Post Sale/Service Feedback Template</div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>

                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(11)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>NPS</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Instantly understand your website’s appeal with feedback from buyers.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 50 </span>
                                            </div>
                                        </div>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(40)} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                                <img src="/assets/icons/customerExp.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Customer experience</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Get in-depth feedback from customers on your product or service.</span>
                                                <span style={{ fontSize: "14px", paddingTop: '15px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20 </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="CFS1MainContainer custom" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Custom</h1>
                                    <div>Choose a Custom Template for your feedback</div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>

                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => window.open("https://duonut.com/templates", "_blank")} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-empire" aria-hidden="true" style={{ fontSize: "2.5rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Traditional Surveys & Forms</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Create traditional surveys with closed-choice questions and typed responses</span>
                                            </div>
                                        </div>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}
                                                style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="none">
                                                    <path fill="#3184C9" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                                </svg>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Start from scratch</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Build your vision and from the ground up with no limits.</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="CFS1MainContainer" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", paddingBottom: "25px" }}>
                                    <h1 style={{ marginTop: "1rem" }}>Import</h1>
                                    <div>Have a Typeform URL? </div>
                                    <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>
                                        <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                            <div className="CFS1startscratch box_shadow" onClick={() => importref.current.click()} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "2.5rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                <span style={{ color: "#337ab7", fontWeight: "bold" }}>Import it now</span>
                                                <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Import Typeform surveys to your Duonut.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>


                        </CSSTransition>
                        {/* <CSSTransition
                            in={selectedType === 1}
                            timeout={200}
                            classNames={isTransitionUp ? "slide-up" : "slide-down"}
                            unmountOnExit
                        >
                            <div className={`transition-div ${selectedType === 1 ? "in" : "out"}`}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                                <h1 style={{ marginTop: "2rem" }}>Templates</h1>
                                <div>Choose one of the options below.</div>
                                <div className="CFS1templateContainer" style={{ width: "65vw", margin: "auto" }}>
                                    <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                        <div className="CFS1startscratch box_shadow" style={{ height: "13rem", gap: "6px", fontSize: "20px" }} onClick={() => setselectedType(10)}>
                                            <img src="/assets/icons/nps-logo.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>NPS</span>
                                            <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Track NPS(net proctor score) and uncover insights with follow-up questions.</span>
                                        </div>
                                    </div>
                                    <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                        <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(20)} style={{ height: "13rem", gap: "6px", fontSize: "20px" }}>
                                            <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>Creative Feedback</span>
                                            <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Gather feedback on your images, videos, or other designs.</span>
                                        </div>
                                    </div>


                                    <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                        <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(40)} style={{ height: "13rem", gap: "6px", fontSize: "20px" }}>
                                            {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                            <img src="/assets/icons/customerExp.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>Customer experience</span>
                                            <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Get in-depth feedback from customers on your product or service.</span>
                                        </div>
                                    </div>
                                    <div className="CFS1templateBlock" style={{ width: "14rem" }}>
                                        <div className="CFS1startscratch box_shadow" onClick={() => navigate("/design")} style={{ height: "13rem", gap: "6px", fontSize: "20px" }}>
                                            {/* <i className="fa fa-magic" aria-hidden="true" style={{ fontSize: "2rem", paddingBottom: "5px", color: "#337ab7", }}></i> 
                                            <img src="/assets/icons/t-survey.svg" alt="duonut" loading="lazy" style={{ width: "60px" }} />
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>Traditional Surveys</span>
                                            <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Create traditional surveys with closed-choice questions and typed responses</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition> */}

                        {/* {selectedType === 10 && (
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                                <h1 style={{ marginTop: "2rem" }}>NPS</h1>
                                <p style={{ padding: "10px" }}>Enter your website url or upload documents upto 10mb and Duonut AI will generate a summary about your company.<br /> This summary will be used to generate questions to ask your customers.</p>
                                <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>
                                    <div className="CFS1templateBlock" style={{ width: "20rem" }}>
                                        <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(11)} style={{ fontSize: "20px" }}>
                                            <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "1.5rem", color: "#337ab7", paddingBottom: "5px" }}></i>
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>Website URL</span>
                                            <span style={{ fontSize: "16px", padding: "5px 20px", margin: "0px 10px" }}>Instantly understand your website’s appeal with feedback from buyers.</span>
                                        </div>
                                    </div>
                                    <div className="CFS1templateBlock" style={{ width: "20rem" }}>
                                        <div className="CFS1startscratch box_shadow" onClick={() => setselectedType(12)} style={{ fontSize: "20px" }}>
                                            <i className="fa fa-upload" aria-hidden="true" style={{ color: "#337ab7", fontSize: "1.5rem", paddingBottom: "5px" }}></i>
                                            <span style={{ color: "#337ab7", fontWeight: "bold" }}>Upload</span>
                                            <span style={{ fontSize: "16px", padding: "5px 20px", margin: "0px 10px" }}>Easily upload your documents and get quick feedback.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )} */}

                        {selectedType === 11 && (
                            <div>
                                <div style={{ width: "45rem" }}>
                                    <h3 style={{ fontWeight: "bold", }}>Website URL</h3>
                                    <p style={{ padding: "10px 0px 40px 0" }}>Scan your website within seconds...</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }} className='GPT-Input'>

                                    <input
                                        style={{ maxWidth: " 45rem", height: "7vh", fontSize: "18px" }}
                                        id="GPT-input_box"
                                        className='Ai-input_box'
                                        type="text"
                                        placeholder="Enter your company website to get started..."
                                        autoFocus
                                        onKeyUp={(e) => {
                                            e.preventDefault();

                                            // Retrieve and trim the input text for both typing and pasting actions
                                            let text = e.target.value.trim();

                                            // If the event is a paste, capture the pasted text directly from clipboard data
                                            if (e.type === "paste") {
                                                text = (e.clipboardData || window.clipboardData).getData("text").trim();
                                            }

                                            // Update the input field value and trigger the change handler
                                            e.target.value = text;
                                            handleGPTQuestionChange({ target: { value: text } });
                                        }}
                                    />
                                    {/* <button
                                        type='submit'
                                        id="gptBtn"
                                        style={{
                                            backgroundColor: isButtonHovered ? "#337ab7" : "transparent",
                                            border: "1px solid rgba(149, 149, 161, 0.2)",
                                            borderRadius: "0px 5px 5px 0px",
                                            cursor: "pointer",
                                            width: "5vw"
                                        }}
                                        onMouseEnter={(event) => {
                                            if (event.currentTarget === event.target) {
                                                setIsButtonHovered(true);
                                            }
                                        }}
                                        onMouseLeave={(event) => {
                                            if (event.currentTarget === event.target) {
                                                setIsButtonHovered(false);
                                            }
                                        }}
                                        onClick={() => handleGPTSubmit()}
                                    >
                                        {isButtonHovered ? (
                                            <img src="/assets/icons/white_svg.svg" style={{ width: "2.5rem" }} alt="duonut" loading="lazy" />
                                        ) : (
                                            <img src="/assets/icons/blue_icon.svg" style={{ width: "2.5rem" }} alt="duonut" loading="lazy" />
                                        )}
                                    </button> */}
                                </div>
                            </div>
                        )}

                        {selectedType === 12 && (
                            <div>
                                <div style={{ width: "45rem" }}>
                                    <h3 style={{ fontWeight: "bold", }}>Upload Documents</h3>
                                    <p style={{ padding: "10px 0px 40px 0" }}>Upload documents upto 10MB, you can upload multiple documents too.</p>
                                </div>
                                <div>

                                    <input
                                        id="GPT-input_box"
                                        className='Ai-input_box'
                                        style={{ width: "100%", maxWidth: " 45rem", margin: "5px auto", borderRadius: "5px", height: "7vh", fontSize: "18px" }}
                                        type="text"
                                        placeholder="Enter the Title/Company here"
                                        required
                                        onKeyUp={e => settitle(e.target.value)}
                                    />
                                    {/* <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }} className='GPT-Input'>
                                        <input
                                            className='Ai-input_box'
                                            id="GPT-input_box2"
                                            type="file"
                                            accept=".pdf, .doc, .docx"
                                            placeholder='Choose File'
                                            required
                                            style={{ width: "100%", margin: "10px 0px", maxWidth: " 45rem", height: "7vh", fontSize: "18px" }}
                                            onChange={e => handleUploadFile(e)}
                                        />
                                    </div> */}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="GPT-Input">
                                        <button
                                            className="custom-btn"
                                            onClick={handleButtonClick}
                                            // onClick={e => handleUploadFile(e)}
                                            style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#546e7a",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                borderRadius: "5px",
                                                margin: "10px 0px",
                                                width: "45rem",
                                            }}
                                        >
                                            Choose File
                                        </button>
                                        <input
                                            className="Ai-input_box"
                                            id="fileInput"
                                            type="file"
                                            accept=".pdf, .doc, .docx"
                                            style={{ display: "none" }} // Hide the default file input
                                            onChange={e => handleUploadFile(e)}
                                            multiple
                                        />
                                    </div>
                                    {uploadedFile.length > 0 && (
                                        <div style={{ maxWidth: "45rem", width: "100%", margin: "auto", textAlign: 'center' }}>
                                            <h3 style={{ padding: "5px", margin: "1rem 0px 1rem 0px", color: "#3498DF" }}>Uploaded File</h3>
                                            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left", border: "1px solid rgba(149, 149, 161, 0.2)" }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", width: "75%", fontSize: "14px" }}>File Name</th>
                                                        <th style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>File Size</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: "16px" }}>
                                                    {uploadedFile?.map((file, index) => (
                                                        <tr key={index}>
                                                            <td style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>{file.name}</td>
                                                            <td style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>{formatFileSize(file.size)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>


                        )}

                        {selectedType === 20 && (<>
                            {!isUploaded &&
                                <div>
                                    <div style={{ width: "45rem" }}>
                                        <h3 style={{ fontWeight: "bold", paddingBottom: "10px" }}>Upload Logo</h3>
                                        <p style={{ paddingBottom: "40px" }}>(Supports format PNG, JPEG, JPG, JFIF and WEBP)</p>
                                    </div>
                                    <div className='GPT-Input' style={{ display: "flex", flexDirection: "column", padding: "20px", justifyContent: "center", alignItems: "center", border: "1px solid #ccc", borderRadius: "5px" }}>
                                        <button
                                            onClick={handleUploadButtonClick}
                                            style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#546e7a",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                borderRadius: "5px",
                                                margin: "10px 0px",
                                            }}>
                                            Upload
                                        </button>
                                        <input
                                            className='Ai-input_box'
                                            id="GPT-input_box3"
                                            type="file"
                                            accept="image/*"
                                            placeholder='Choose Your Logo'
                                            required
                                            style={{ display: "none" }}
                                            onChange={e => handleUploadVideoFile(e)}
                                        />
                                        {isLoading ? (
                                            <img
                                                alt="loader"
                                                src={require('../../assets/Images/loader.gif')}
                                                style={{ margin: 'auto', height: '2.5rem', padding: "20px" }}
                                            />
                                        ) : (
                                            preview && (
                                                <>
                                                    {preview.startsWith('data:image') ? (
                                                        <>
                                                            <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />
                                                            <button
                                                                onClick={resetImage}
                                                                style={{
                                                                    marginTop: "10px",
                                                                    padding: "5px 10px",
                                                                    backgroundColor: "#ff5c5c",
                                                                    color: "white",
                                                                    border: "none",
                                                                    cursor: "pointer",
                                                                    fontSize: "14px",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <video controls src={preview} style={{ width: '300px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )
                                        )}
                                    </div>
                                    {/* {preview && (
                                        <>
                                            {preview.startsWith('data:image') ? (
                                                <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />
                                            ) : (
                                                <video controls src={preview} style={{ width: '300px', height: 'auto' }} />
                                            )}
                                        </>
                                    )} */}
                                </div>}
                            {isUploaded &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div className="" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "flex-start", width: "45rem", marginBottom: "25px" }}>
                                        <div style={{ textAlign: "left" }}>
                                            <h3>Creative Feedback</h3>
                                            <p>Tell us a more about your creative.</p>
                                        </div>
                                        <div>
                                            {preview && (
                                                <>
                                                    {preview.startsWith('data:image') ? (
                                                        <img src={preview} alt="Preview" style={{ width: '100px', height: 'auto' }} />
                                                    ) : (
                                                        <video controls src={preview} style={{ width: '100px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <h4 style={{ textAlign: "left", width: "45rem" }}>Enter the Title</h4>
                                    {/* <input
                                        id="GPT-input_box3"
                                        className='Ai-input_box'
                                        style={{ fontSize: "16px", width: "100%", maxWidth: "45rem", margin: "5px auto", borderRadius: "5px" }}
                                        type="text"
                                        placeholder="Eg: Duonut/Flipkart"
                                        required
                                        onKeyUp={e => setVideotitle(e.target.value)}
                                    /> */}

                                    <EditTextarea rows={1}
                                        id="GPT-input_box3"
                                        value={videotitle}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box3`).select(), 100); }}
                                        onChange={(e) => (setVideotitle(e.target.value))}
                                        placeholder="Eg: Duonut/Flipkart"
                                    />
                                    <div style={{ width: "45rem" }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                            <h4>Description</h4>
                                            <p>Please describe the content of your asset. This information will provide context to the Duonut AI.</p>
                                        </div>
                                        <EditTextarea rows={2}
                                            id="objective-input-field"
                                            value={videoObjective}
                                            style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                            onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                            onChange={(e) => (setvideoObjective(e.target.value))}
                                            placeholder="Enter Description of creative"
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "45rem",
                                        margin: "15px 0px 10px 0px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                            <h4>Feedback Desired</h4>
                                            <p>What feedback are you looking for?</p>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the overall visual attractiveness of the creative asset.")}>
                                                    Visual appeal</label>
                                                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the clarity and impact of the message conveyed.")}>
                                                    Message clarity</label>
                                                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on how well the asset aligns with our brand identity.")}>
                                                    Brand consistency</label>
                                            </div>
                                        </div>

                                    </div>
                                    <EditTextarea rows={2}
                                        id="Feedback-input-field"
                                        value={videoFeedbackDesire}
                                        style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                        placeholder="Write your Feedback desired."
                                    />
                                </div>}
                        </>
                        )}
                        {selectedType === 21 && (<>
                            {!isUploaded &&
                                <div>
                                    <div style={{ width: "45rem" }}>
                                        <h3 style={{ fontWeight: "bold", paddingBottom: "10px" }}>Upload Image</h3>
                                        <p style={{ paddingBottom: "40px" }}>(Supports format PNG, JPEG, JPG, JFIF and WEBP)</p>
                                    </div>
                                    <div className='GPT-Input' style={{ display: "flex", flexDirection: "column", padding: "20px", justifyContent: "center", alignItems: "center", border: "1px solid #ccc", borderRadius: "5px" }}>
                                        <button
                                            onClick={handleUploadButtonClick}
                                            style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#546e7a",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                borderRadius: "5px",
                                                margin: "10px 0px",
                                            }}>
                                            Upload
                                        </button>
                                        <input
                                            className='Ai-input_box'
                                            id="GPT-input_box3"
                                            type="file"
                                            accept="image/*"
                                            placeholder='Choose Image File'
                                            required
                                            style={{ display: "none" }}
                                            onChange={e => handleUploadVideoFile(e)}
                                        />
                                        {isLoading ? (
                                            <img
                                                alt="loader"
                                                src={require('../../assets/Images/loader.gif')}
                                                style={{ margin: 'auto', height: '2.5rem', padding: "20px" }}
                                            />
                                        ) : (
                                            preview && (
                                                <>
                                                    {preview.startsWith('data:image') ? (
                                                        <>  <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />
                                                            <button
                                                                onClick={resetImage}
                                                                style={{
                                                                    marginTop: "10px",
                                                                    padding: "5px 10px",
                                                                    backgroundColor: "#ff5c5c",
                                                                    color: "white",
                                                                    border: "none",
                                                                    cursor: "pointer",
                                                                    fontSize: "14px",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <video controls src={preview} style={{ width: '300px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )
                                        )}
                                    </div>
                                    {/* {preview && (
                                        <>
                                            {preview.startsWith('data:image') ? (
                                                <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />
                                            ) : (
                                                <video controls src={preview} style={{ width: '300px', height: 'auto' }} />
                                            )}
                                        </>
                                    )} */}
                                </div>}
                            {isUploaded &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div className="" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "flex-start", width: "45rem", marginBottom: "25px" }}>
                                        <div style={{ textAlign: "left" }}>
                                            <h3>Creative Feedback</h3>
                                            <p>Tell us a more about your creative.</p>
                                        </div>
                                        <div>
                                            {preview && (
                                                <>
                                                    {preview.startsWith('data:image') ? (
                                                        <img src={preview} alt="Preview" style={{ width: '100px', height: 'auto' }} />
                                                    ) : (
                                                        <video controls src={preview} style={{ width: '100px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <h4 style={{ textAlign: "left", width: "45rem" }}>Enter the Title</h4>
                                    {/* <input
                                        id="GPT-input_box3"
                                        className='Ai-input_box'
                                        style={{ fontSize: "16px", width: "100%", maxWidth: "45rem", margin: "5px auto", borderRadius: "5px" }}
                                        type="text"
                                        placeholder="Eg: Duonut/Flipkart"
                                        required
                                        onKeyUp={e => setVideotitle(e.target.value)}
                                    /> */}

                                    <EditTextarea rows={1}
                                        id="GPT-input_box3"
                                        value={videotitle}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box3`).select(), 100); }}
                                        onChange={(e) => (setVideotitle(e.target.value))}
                                        placeholder="Eg: Duonut/Flipkart"
                                    />
                                    <div style={{ width: "45rem" }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                            <h4>Description</h4>
                                            <p>Please describe the content of your asset. This information will provide context to the Duonut AI.</p>
                                        </div>
                                        <EditTextarea rows={2}
                                            id="objective-input-field"
                                            value={videoObjective}
                                            style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                            onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                            onChange={(e) => (setvideoObjective(e.target.value))}
                                            placeholder="Enter Description of creative"
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "45rem",
                                        margin: "15px 0px 10px 0px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                            <h4>Feedback Desired</h4>
                                            <p>What feedback are you looking for?</p>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the overall visual attractiveness of the creative asset.")}>
                                                    Visual appeal</label>
                                                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the clarity and impact of the message conveyed.")}>
                                                    Message clarity</label>
                                                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on how well the asset aligns with our brand identity.")}>
                                                    Brand consistency</label>
                                            </div>
                                        </div>

                                    </div>
                                    <EditTextarea rows={2}
                                        id="Feedback-input-field"
                                        value={videoFeedbackDesire}
                                        style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                        placeholder="Write your Feedback desired."
                                    />
                                </div>}
                        </>
                        )}
                        {selectedType === 22 && (<>
                            {!isUploaded &&
                                <div>
                                    <div style={{ width: "45rem" }}>
                                        <h3 style={{ fontWeight: "bold", paddingBottom: "10px" }}>Upload Video</h3>
                                        <p style={{ paddingBottom: "40px" }}>(Supports both format MP3 and MP4. And maximum assest size to upload can be 10MB. )</p>
                                    </div>
                                    <div className='GPT-Input' style={{ display: "flex", flexDirection: "column", padding: "20px", justifyContent: "center", alignItems: "center", border: "1px solid #ccc", borderRadius: "5px" }}>
                                        <button
                                            onClick={handleUploadButtonClick}
                                            style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#546e7a",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                                fontSize: "18px",
                                                borderRadius: "5px",
                                                margin: "10px 0px",
                                            }}>
                                            Upload
                                        </button>
                                        <input
                                            className='Ai-input_box'
                                            id="GPT-input_box3"
                                            type="file"
                                            accept="video/*"
                                            placeholder='Choose Video File'
                                            required
                                            style={{ display: "none" }}
                                            onChange={e => handleUploadVideoFile(e)}
                                        />
                                        {isLoading ? (
                                            <img
                                                alt="loader"
                                                src={require('../../assets/Images/loader.gif')}
                                                style={{ margin: 'auto', height: '2.5rem', padding: "20px" }}
                                            />
                                        ) : (
                                            preview && (
                                                <>
                                                    {/* Check if the preview is an image */}
                                                    {preview.startsWith("data:image") ? (
                                                        <img src={preview} alt="Preview" style={{ width: "300px", height: "auto" }} />
                                                    ) : (
                                                        // If not an image, show the video
                                                        <video controls src={preview} style={{ width: "300px", height: "auto" }} />
                                                    )}
                                                    {/* Cancel Button */}
                                                    <button
                                                        onClick={resetImage}
                                                        style={{
                                                            marginTop: "10px",
                                                            padding: "5px 10px",
                                                            backgroundColor: "#ff5c5c",
                                                            color: "white",
                                                            border: "none",
                                                            cursor: "pointer",
                                                            fontSize: "14px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            )
                                        )}
                                    </div>
                                    {/* {preview && (
                                        <>
                                            {preview.startsWith('data:image') ? (
                                                <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />
                                            ) : (
                                                <video controls src={preview} style={{ width: '300px', height: 'auto' }} />
                                            )}
                                        </>
                                    )} */}
                                </div>}
                            {isUploaded &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div className="" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "flex-start", width: "45rem", marginBottom: "25px" }}>
                                        <div style={{ textAlign: "left" }}>
                                            <h3>Creative Feedback</h3>
                                            <p>Tell us a more about your creative.</p>
                                        </div>
                                        <div>
                                            {preview && (
                                                <>
                                                    {preview.startsWith('data:image') ? (
                                                        <img src={preview} alt="Preview" style={{ width: '100px', height: 'auto' }} />
                                                    ) : (
                                                        <video controls src={preview} style={{ width: '100px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <h4 style={{ textAlign: "left", width: "45rem" }}>Enter the Title</h4>
                                    {/* <input
                                        id="GPT-input_box3"
                                        className='Ai-input_box'
                                        style={{ fontSize: "16px", width: "100%", maxWidth: "45rem", margin: "5px auto", borderRadius: "5px" }}
                                        type="text"
                                        placeholder="Eg: Duonut/Flipkart"
                                        required
                                        onKeyUp={e => setVideotitle(e.target.value)}
                                    /> */}

                                    <EditTextarea rows={1}
                                        id="GPT-input_box3"
                                        value={videotitle}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box3`).select(), 100); }}
                                        onChange={(e) => (setVideotitle(e.target.value))}
                                        placeholder="Eg: Duonut/Flipkart"
                                    />
                                    <div style={{ width: "45rem" }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                            <h4>Description</h4>
                                            <p>Please describe the content of your asset. This information will provide context to the Duonut AI.</p>
                                        </div>
                                        <EditTextarea rows={2}
                                            id="objective-input-field"
                                            value={videoObjective}
                                            style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                            onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                            onChange={(e) => (setvideoObjective(e.target.value))}
                                            placeholder="Enter Description of creative"
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "45rem",
                                        margin: "15px 0px 10px 0px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                            <h4>Feedback Desired</h4>
                                            <p>What feedback are you looking for?</p>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the overall visual attractiveness of the creative asset.")}>
                                                    Visual appeal</label>
                                                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the clarity and impact of the message conveyed.")}>
                                                    Message clarity</label>
                                                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on how well the asset aligns with our brand identity.")}>
                                                    Brand consistency</label>
                                            </div>
                                        </div>

                                    </div>
                                    <EditTextarea rows={2}
                                        id="Feedback-input-field"
                                        value={videoFeedbackDesire}
                                        style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                        placeholder="Write your Feedback desired."
                                    />
                                </div>}
                        </>
                        )}

                        {selectedType === 30 && (<>
                            {(!isUploaded && !isLoading) ?
                                <div>
                                    <div style={{ width: "45rem" }}>
                                        <h3 style={{ fontWeight: "bold", }}>Website URL</h3>
                                        <p style={{ padding: "10px 0px 40px 0" }}>What's the URL of your website?</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }} className='GPT-Input'>

                                        <input
                                            style={{ maxWidth: " 45rem", height: "7vh", fontSize: "18px" }}
                                            id="GPT-website-input_box"
                                            className='Ai-input_box'
                                            type="text"
                                            placeholder="https://duonut.com"
                                            autoFocus
                                            onKeyUp={(e) => {
                                                e.preventDefault();

                                                // Retrieve and trim the input text for both typing and pasting actions
                                                let text = e.target.value.trim();

                                                // If the event is a paste, capture the pasted text directly from clipboard data
                                                if (e.type === "paste") {
                                                    text = (e.clipboardData || window.clipboardData).getData("text").trim();
                                                }

                                                // Update the input field value and trigger the change handler
                                                e.target.value = text;
                                                handleWebsiteGPTQuestionChange({ target: { value: text } });
                                            }}
                                        />
                                        {/* <button
                                            type='submit'
                                            id="websiteGptBtn"
                                            style={{
                                                backgroundColor: isButtonHovered ? "#337ab7" : "transparent",
                                                border: "1px solid rgba(149, 149, 161, 0.2)",
                                                borderRadius: "0px 5px 5px 0px",
                                                cursor: "pointer",
                                                width: "5vw"
                                            }}
                                            onMouseEnter={(event) => {
                                                if (event.currentTarget === event.target) {
                                                    setIsButtonHovered(true);
                                                }
                                            }}
                                            onMouseLeave={(event) => {
                                                if (event.currentTarget === event.target) {
                                                    setIsButtonHovered(false);
                                                }
                                            }}
                                            onClick={() => handleWebsiteGPTSubmit()}
                                        >
                                            {isButtonHovered ? (
                                                <img src="/assets/icons/white_svg.svg" style={{ width: "2.5rem" }} alt="duonut" loading="lazy" />
                                            ) : (
                                                <img src="/assets/icons/blue_icon.svg" style={{ width: "2.5rem" }} alt="duonut" loading="lazy" />
                                            )}
                                        </button> */}
                                    </div>
                                </div>
                                : (!isUploaded && isLoading) ?
                                    <img
                                        alt="loader"
                                        src={require('../../assets/Images/loader.gif')}
                                        style={{ margin: 'auto', height: '2.5rem', padding: "20px" }}
                                    />
                                    : <></>
                            }
                            {isUploaded &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ width: "45rem" }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                            <h4 style={{ textAlign: "left", width: "45rem" }}>Company / brand</h4>
                                            <p>What's the name of your company or brand?</p>
                                        </div>

                                        <EditTextarea rows={1}
                                            id="GPT-input_box4"
                                            value={videotitle}
                                            style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                            onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box4`).select(), 100); }}
                                            onChange={(e) => (setVideotitle(e.target.value))}
                                            placeholder="Eg: Duonut/Flipkart"
                                        />
                                    </div>
                                    <div style={{ width: "45rem" }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                            <h4>Description</h4>
                                            <p>What do you sell? This information will provide context to the AI interviewer.</p>
                                        </div>
                                        <EditTextarea rows={2}
                                            id="objective-input-field"
                                            value={videoObjective}
                                            style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                            onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                            onChange={(e) => (setvideoObjective(e.target.value))}
                                            placeholder="Enter Description"
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "45rem",
                                        margin: "15px 0px 10px 0px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                        <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                            <h4>Feedback Desired</h4>
                                            <p>What feedback are you looking for?</p>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the usability of the signup process.")}>
                                                    Sign up experience</label>
                                                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like to understand people's reactions to messaging on the homepage.")}>
                                                    Homepage messaging</label>
                                                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                    onClick={() => setvideoFeedbackDesire("I'd like feedback on the visual design of the website.")}>
                                                    Design feedback</label>
                                            </div>
                                        </div>

                                    </div>
                                    <EditTextarea rows={2}
                                        id="Feedback-input-field"
                                        value={videoFeedbackDesire}
                                        style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                        placeholder="Write your Feedback desired."
                                    />
                                </div>}
                        </>
                        )}

                        {selectedType === 40 && (<>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ width: "45rem" }}>
                                    <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                        <h4 style={{ textAlign: "left", width: "45rem" }}>Product / service</h4>
                                        <p>What's the name of your product or service?</p>
                                    </div>

                                    <EditTextarea rows={1}
                                        id="GPT-input_box5"
                                        value={videotitle}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box5`).select(), 100); }}
                                        onChange={(e) => (setVideotitle(e.target.value))}
                                        placeholder=""
                                    />
                                </div>
                                <div style={{ width: "45rem" }}>
                                    <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                        <h4>Description</h4>
                                        <p>What does your product or service do? Please be specific.</p>
                                    </div>
                                    <EditTextarea rows={2}
                                        id="objective-input-field"
                                        value={videoObjective}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoObjective(e.target.value))}
                                        placeholder=""
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "45rem",
                                    margin: "15px 0px 10px 0px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                        <h4>Feedback Desired</h4>
                                        <p>What feedback are you looking for?</p>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to gauge customers' satisfaction with their overall experience.")}>
                                                Overall satisfaction</label>
                                            <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to gather suggestions from my customers to improve my product or service.")}>
                                                Areas for improvement</label>
                                            <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to understand how easy it is for my customers to complete a desired action.")}>
                                                Customer effort</label>
                                        </div>
                                    </div>

                                </div>
                                <EditTextarea rows={2}
                                    id="Feedback-input-field"
                                    value={videoFeedbackDesire}
                                    style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                    onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                    onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                    placeholder="Write your Feedback desired."
                                />
                            </div>
                        </>
                        )}

                        {selectedType === 50 && (<>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ width: "45rem" }}>

                                    {/* <button onClick={() => { window.location.href = 'https://duonut.com/templates/t/visual-stimuli-example'; }}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s", right: "20px", top: "10px", position: "fixed", width: "15%"
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} >
                                        Example
                                    </button> */}
                                    <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                        <h4 style={{ textAlign: "left", width: "45rem" }}>Product Variant Selector</h4>
                                        <p>What's the name of your product or service?</p>
                                        <p style={{ color: "gray", fontSize: "12px" }}>What is the customer-facing name of your product?</p>
                                    </div>

                                    <EditTextarea rows={1}
                                        id="GPT-input_box5"
                                        value={videotitle}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", height: "20px", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#GPT-input_box5`).select(), 100); }}
                                        onChange={(e) => (setVideotitle(e.target.value))}
                                        placeholder=""
                                    />
                                </div>
                                <div style={{ width: "45rem" }}>
                                    <div style={{ textAlign: "left", maxWidth: "45rem", margin: "25px 0px" }}>
                                        <h4>Description</h4>
                                        <p>What does your product or service do? Please be specific.</p>
                                        <p style={{ color: "gray", fontSize: "12px" }}>You can add a detailed description of your product, brand, or category to share with our AI agent. You can also add features or benefits.</p>
                                    </div>
                                    <EditTextarea rows={2}
                                        id="objective-input-field"
                                        value={videoObjective}
                                        style={{ fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", maxWidth: "45rem", margin: "5px auto", }}
                                        onEditMode={() => { setTimeout(() => document.querySelector(`#objective-input-field`).select(), 100); }}
                                        onChange={(e) => (setvideoObjective(e.target.value))}
                                        placeholder=""
                                    />
                                </div>
                                {/* <div style={{
                                    display: "flex",
                                    width: "45rem",
                                    margin: "15px 0px 10px 0px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <div style={{ textAlign: "left", maxWidth: "45rem", marginTop: "10px" }}>
                                        <h4>Feedback Desired</h4>
                                        <p>What feedback are you looking for?</p>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to gauge customers' satisfaction with their overall experience.")}>
                                                Overall satisfaction</label>
                                            <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to gather suggestions from my customers to improve my product or service.")}>
                                                Areas for improvement</label>
                                            <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                                                onClick={() => setvideoFeedbackDesire("I'd like to understand how easy it is for my customers to complete a desired action.")}>
                                                Customer effort</label>
                                        </div>
                                    </div>

                                </div>
                                <EditTextarea rows={2}
                                    id="Feedback-input-field"
                                    value={videoFeedbackDesire}
                                    style={{ maxWidth: "45rem", fontSize: "16px", textAlign: "left", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "100%", margin: "5px auto", }}
                                    onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                                    onChange={(e) => (setvideoFeedbackDesire(e.target.value))}
                                    placeholder="Write your Feedback desired."
                                /> */}
                            </div>
                        </>
                        )}

                        {selectedType !== 0 && (
                            <div style={{
                                cursor: "pointer", padding: "5px", display: "flex",
                                justifyContent: "center", alignItems: "center"
                            }}>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        backgroundColor: "transparent",
                                        color: "#3184C9",
                                        border: "1px solid #3184C9",
                                        borderRadius: "5px",
                                        padding: "15px",
                                        marginTop: "25px",
                                        width: "100px",
                                        marginRight: "20px",
                                        transition: "color 0.3s, border 0.3s" // Smooth transition for color and border
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.color = "#0E2C45"; // Dark blue on hover
                                        e.currentTarget.style.border = "1px solid #0E2C45"; // Dark blue border on hover
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.color = "#3184C9"; // Original color on leave
                                        e.currentTarget.style.border = "1px solid #3184C9"; // Original border on leave
                                    }}
                                    onClick={() => { setisUploaded(false); setPreview(false); handleBackButton() }} // Go back to selection screen
                                    disabled={isLoading}
                                >
                                    Back
                                </button>
                                {selectedType === 11 && (
                                    <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s"
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"}
                                        onClick={() => handleGPTSubmit()}
                                    >
                                        Generate
                                    </button>
                                )}
                                {selectedType === 12 && ( // Show Generate button only for file upload
                                    <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleGPTSubmit1()} // Function to handle submission
                                    >
                                        Generate
                                    </button>
                                )}
                                {(selectedType === 20 || selectedType === 21 || selectedType === 22) && (<>
                                    {!isUploaded && <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: !preview ? "#b8d3e9" : "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        disabled={!preview}
                                        onMouseEnter={(e) => !preview ? e.currentTarget.style.backgroundColor = "#b8d3e9" : e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => !preview ? e.currentTarget.style.backgroundColor = "#b8d3e9" : e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => setisUploaded(true)} // Function to handle submission
                                    >
                                        Next
                                    </button>}
                                    {isUploaded && <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleGPTSubmitVideo(selectedType)} // Function to handle submission
                                    >
                                        Generate
                                    </button>}
                                </>
                                )}
                                {selectedType === 30 && (<>
                                    {isUploaded ? (<button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleWebsiteGenerateDuonut()} // Function to handle submission
                                        disabled={isLoading}
                                    >
                                        Generate
                                    </button>)
                                        : (
                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "16px",
                                                    backgroundColor: "#3184C9",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                    padding: "15px",
                                                    marginTop: "25px",
                                                    width: "100px",
                                                    transition: "background-color 0.3s"
                                                }}
                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"}
                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"}
                                                onClick={() => handleWebsiteGPTSubmit()}
                                            >
                                                Generate
                                            </button>
                                        )}
                                </>
                                )}

                                {selectedType === 40 && (<>
                                    <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleCustomerGenerateDuonut()} // Function to handle submission
                                    >
                                        Generate
                                    </button>
                                </>
                                )}

                                {selectedType === 50 && (<>
                                    <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleVisualStimuliGenerateDuonut()} // Function to handle submission
                                    >
                                        Generate
                                    </button>
                                </>
                                )}
                                {selectedType === 50 && (
                                    <button onClick={() => {
                                        window.open(
                                            'https://duonut.com/templates/t/visual-stimuli-example',
                                            '_blank'
                                        );
                                    }}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "50%",
                                            transition: "background-color 0.3s", marginLeft: '20px',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} >
                                        See Example
                                    </button>
                                )}

                            </div>
                        )}
                        {(selectedType === 11 || selectedType === 12) && (
                            <>
                                <span style={{ fontSize: '18px', margin: "20px" }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 50  will be utilized for this operation.</span>
                            </>
                        )}
                        {(selectedType === 20 || selectedType === 21 || selectedType === 22 || selectedType === 30 || selectedType === 40 || selectedType === 50) && (
                            <>
                                <span style={{ fontSize: "18px", margin: '20px' }}><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" /> &nbsp; 20  will be utilized for this operation.</span>
                            </>
                        )}
                        {errMess && <div style={{ color: "red", padding: "5px" }}>{errMess}</div>}
                        {upgradeMess && <div style={{ margin: "1rem auto", width: "fit-content", padding: "5px", background: "#3184c9", color: 'white', textAlign: "center", borderRadius: "5px" }}>
                            Upgrade your account to scan more websites
                            <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
                                onClick={() => handleGetProClick()} >Upgrade Now</button>
                        </div>}

                        {aiData1 && aiData1?.length >= 1 && selectedType === 11 && <div style={{ marginTop: "1rem" }}>Already have Company Details?
                            <button className='start_page_btn'
                                onClick={() => navigate("/aidata")} >Click here</button>
                        </div>}

                    </div>}


                    {selectedPage === 21 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is gathering insights about your company to become an expert on your products.<br /> Please avoid refreshing the page or clicking ‘back’ until we’re done.</div>
                    </div>}
                    {selectedPage === 22 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI will suggest questions for your customers, <br />and you can also add your own and send NPS surveys to your users.</div>
                    </div>}
                    {selectedPage === 23 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is analyzing your content for creative insights—please hold tight!<br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {selectedPage === 24 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div clascsName='loading-text'>Duonut AI is analyzing your content for creative insights—please hold tight!<br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {selectedPage === 25 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is gathering insights from your website — Hang tight!<br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {selectedPage === 26 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is gathering insights from your website — Hang tight!<br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {selectedPage === 27 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is crafting a customer experience questionnaire for your users. <br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {selectedPage === 28 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is crafting a customer experience questionnaire for your users. <br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                    {/* {selectedPage === 29 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is creating open ended questions to test and iterate your product & messaging <br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>} */}
                    {selectedPage === 30 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is creating open ended questions to test and iterate your product & messaging <br /> Please avoid refreshing the page or clicking 'back' until we're done.</div>
                    </div>}
                </div>

            </div>
        </div >
    );

}

export default AiStartPageNew;