import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./CreateFromScratch.css";
import { motion, AnimatePresence } from "framer-motion";
import { toggleCreateFromScratchModalDuonut, toggleCreateFromScratchModalAgain, toggleTemplateModal } from "../../../redux/slices/CreateFromScratchReducer";
// import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
// import useOutsideAlerter from "../../../hooks/useOutsideAlerter"
// import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import { backendUrl, frontendUrl } from "../../../services/constants";
import { toggleChangeDuonutNameModal } from "../../../redux/slices/DuonutNameReducer";
import { domain } from "../../../services/constants";
import axios from "axios";
import { createDuonut } from "../../../services/activity";
import { addUserActivity, removeUserActivity, selectUserActivity, setUserActivity } from "../../../redux/slices/userActivity";


var duonutId;
export default function CreateFromScratch() {
    const dispatch = useDispatch();
     const { isLoggedIn, credit } = useSelector((state) => state.user);
    const { modalActive, modalAgain } = useSelector((state) => state.createFromScratch);
     const { settings } = useSelector(state => state.duonutSettings);
    // const { width } = useWindowDimensions();
    // const location = useLocation();
    const navigate = useNavigate();
    // const templateref = useRef(null);
    // const templateref1 = useRef(null);
    // const templateopenref = useRef(null)
    // const [selectedItem, setselectedItem] = useState(1);
    // const [selectedId, setselectedId] = useState(0);
    // const [selectedDisplayId, setselectedDisplayId] = useState(0);
    // const [selectedName, setselectedName] = useState("");
    // const [mouseEnterId, setmouseEnterId] = useState(0);
    const importref = useRef(null);
    const importref1 = useRef(null);
    const [typeformUrl, settypeformUrl] = useState("");
    const [errMess, seterrMess] = useState("");
     const [isLoading, setisLoading] = useState(false);
        const [isUploaded, setisUploaded] = useState(false);

    const handleClose = () => {
        dispatch(toggleCreateFromScratchModalDuonut(false));
        dispatch(toggleCreateFromScratchModalAgain(true));
    };



    // const onClickTemplateButton = (id, name) => {
    //     templateref1.current.click();
    //     dispatch(toggleCreateFromScratchModalDuonut(false));
    //     dispatch(updateDuonutDefaultName(name));
    //     dispatch(updateDuonutNavigateUrl(`/design?selectedDuonutId=${id}`));
    //     dispatch(toggleChangeDuonutNameModal(true));
    //     // window.open(`/design?selectedDuonutId=${id}`);
    //     //   window.open('/duonut/' + key);
    //     // navigate('/duonut/' + key);
    //     // window.location.reload();
    // }

    // const handleCloseModal = () => {
    //     templateref1.current.click();
    // }
    // useOutsideAlerter(templateopenref, handleCloseModal);

    // useEffect(() => {
    //     if (width <= 768) {
    //         setselectedItem(2);
    //     }
    // }, [width]);

    // const onClickPreview = (id, displayId, name) => {
    //     // console.log(masterData)
    //     setselectedId(id);
    //     setselectedDisplayId(displayId);
    //     setselectedName(name);
    //     setTimeout(() => {
    //         templateref.current.click();
    //     }, 500); //miliseconds
    // }

    const convertbtn = () => {
        const typeformRegex = /https:\/\/[a-z0-9]+\.typeform\.com\/to\/([A-Za-z0-9]+)/;

        const match = typeformUrl.match(typeformRegex);

        if (match && match[1]) {
            // Extracted ID
            // console.log(`Extracted ID: ${match[1]}`);
            importref1.current.click();

            const token = localStorage.getItem("access");
            axios({
                method: "POST",
                url: `${domain}/duonut/get_typeform_project`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    project_id: match[1],
                },
            }).then(data => {
                // console.log('typeform Data:', data?.data?.typeform_response);
                handleTypeformCreateDuonut(data?.data?.typeform_response);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        } else {
            // Invalid URL
            seterrMess("URL not valid");
            setTimeout(() => {
                seterrMess("");
            }, 5000);
        }

    };
     let interval4 = null;
        const handleTypeformCreateDuonut = (typeformData) => {
    
            const controller = new AbortController();
            interval4 = setInterval(() => {
                if (isLoggedIn) {
                    setisLoading(true);
                    clearInterval(interval4);
                    createDuonut({
                        name: typeformData?.title, duonut_category: 1, duonut_setting: {
                            ...settings, viewer_sliding: true,
                        }
                    }, controller, (err, res) => {
                        // setLoading(false);
                        clearInterval(interval4);
                        if (err) {
                            setisLoading(false);
                            console.log(err);
                            return;
                        }
                        // console.log('DUONUT CREATED --- ');
                        duonutId = res?.data?.id;
    
                        handleTypeformUpdateDuonut(typeformData);
    
                    });
                }
            }, 10);
    
            return () => {
                controller.abort();
                clearInterval(interval4)
            };
    
        }
    
        const handleTypeformUpdateDuonut = (typeformData) => {
            // const cost = 20;
            dispatch(setUserActivity({
                activities: [{
                    elements: [],
                    backgroundColor: "white",
                    backgroundImage: "",
                }]
            }));
    
            typeformData?.fields.map((item, i) => {
                const element = selectElement(item);
                var masterData = {
                    elements: [element],
                    backgroundColor: "#ffffff",
                    backgroundImage: "",
                    name: "Step 1",
                };
                dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
            })
    
            dispatch(removeUserActivity({ idx: 0 }));
            dispatch(selectUserActivity(0));
    
            // var creditData = {
            //     name: "Import",
            //     message: `Import Typeform Form to Duonut - ${duonutId}`,
            //     cost: cost,
            // }
            // creditDeduction(creditData, (err, res) => {
            //     if (err) return console.log(err.response);
            //     // console.log(res);
            // });
    
            setTimeout(() => {
                setisLoading(false);
                navigate(`/design/${duonutId}`);
            }, 1000);
    
        }
        function selectElement(jsonElement) {

            if (jsonElement?.type === "opinion_scale" || jsonElement?.type === "nps") {
                var element = {
                    id: 1,
                    elementType: 'Nps',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 100,
                    posY: 200,
                    height: "auto",
                    width: "auto",
                    elementAttributes: {
                        text: jsonElement?.title,
                        color: "white",
                        maxLength: jsonElement?.properties?.steps - 1,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: 16,
                        backgroundColor: "#4a90e2",
                        textAlign: "center",
                        borderRadius: '10px',
                        margin: "5px 5px 5px 5px",
                        padding: "0px 0px 0px 0px",
                        labelFontSize: 20,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        size: 70,
                        gap: "30px",
                        openEnded: false,
                        openEndedObjective: "",
                        openEndedFeedbackDesired: "",
                    },
                    elementHoverAttributes: {
                        backgroundColor: "black", selectedBackgroundColor: "#7ed321"
                    },
                }
                return element;
            }
    
            if (jsonElement?.type === "multiple_choice") {
                var element = {
                    id: 1,
                    elementType: 'RadioGroup',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 230,
                    posY: 100,
                    height: "auto",
                    width: 550,
                    elementAttributes: {
                        text: jsonElement?.title,
                        options: jsonElement?.properties?.choices.map((item) => item.label),
                        other: false,
                        color: '#ffffff', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16,
                        backgroundColor: "#000000",
                        border: "1px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "10px 10px 10px 10px",
                        labelFontSize: 20,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        gap: "25px",
                        openEnded: false,
                        openEndedObjective: "",
                        openEndedFeedbackDesired: "",
                    },
                    elementHoverAttributes: {
                        color: '#ffffff', backgroundColor: "#3184c9", border: "1px solid #000000"
                    },
                }
                return element;
            }
    
            if (jsonElement?.type === "yes_no") {
                var element = {
                    id: 1,
                    elementType: 'RadioGroup',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 310,
                    posY: 160,
                    height: "auto",
                    width: 400,
                    elementAttributes: {
                        text: jsonElement?.title,
                        options: ["Yes", "No"],
                        other: false,
                        color: '#ffffff', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16,
                        backgroundColor: "#000000",
                        border: "1px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "10px 10px 10px 10px",
                        labelFontSize: 20,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        gap: "25px",
                        openEnded: false,
                        openEndedObjective: "",
                        openEndedFeedbackDesired: "",
                    },
                    elementHoverAttributes: {
                        color: '#ffffff', backgroundColor: "#3184c9", border: "1px solid #000000"
                    },
                }
                return element;
            }
    
            if (jsonElement?.type === "dropdown") {
                var element = {
                    id: 1,
                    elementType: 'Dropdown',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 260,
                    posY: 210,
                    height: "auto",
                    width: 500,
                    elementAttributes: {
                        text: jsonElement?.title,
                        options: jsonElement?.properties?.choices.map((item) => item.label),
                        fieldPosition: "column",
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 400, fontSize: 18, backgroundColor: "#ffffff",
                        border: "1px solid #000000", gap: "30px",
                        textAlign: "left", borderRadius: "5px",
                    },
                }
                return element;
            }
    
            if (jsonElement?.type === "long_text") {
                var element = {
                    id: 1,
                    elementType: 'TextArea',
                    transparency: 1,
                    zIndex: 1,
                    order: 1,
                    posX: 100,
                    posY: 200,
                    height: "auto",
                    width: 800,
                    elementAttributes: {
                        color: '#000000', text: jsonElement?.title, fontFamily: "Poppins",
                        fontWeight: 400, fontSize: 18, backgroundColor: "#ffffff", border: "1px solid #000000",
                        textAlign: "left", borderRadius: "0px", fieldPosition: "column", gap: "30px",
                        placeholder: "Type your text here."
                    },
                }
                return element;
            }
    
            // Return an empty array or null if 'fields' not found in any format
            return {};
        }
    
    return (
        <>
        <button ref={importref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="importModal" tabIndex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "550px" }}>
                    <div className="modal-content" style={{ marginTop: "30vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={importref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div>
                                <div style={{ padding: "1rem 0rem", fontSize: "1rem", fontWeight: "600" }}>Enter Typeform URL</div>
                                <input
                                    style={{ maxWidth: " 45rem", height: "7vh", fontSize: "16px" }}
                                    id="GPT-input_box"
                                    className='Ai-input_box'
                                    type="text"
                                    placeholder="https://myusername.typeform.com/to/ITPUqM"
                                    autoFocus
                                    onKeyUp={(e) => {
                                        settypeformUrl(e.target.value);
                                    }}
                                />
                            </div>

                            <br></br>
                            {errMess && <div style={{ color: "red", padding: "5px" }}>{errMess}</div>}

                            <div style={{ width: "100%", textAlign: "center" }}>
                                <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                    onClick={() => importref1.current.click()}>Cancel</button>
                                <button className='QRC1Continuebtn' onClick={() => convertbtn()}>Convert to Duonut</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <button ref={templateref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#templateModal${selectedId}`}>
            </button>
            <div className="modal fade" ref={templateopenref} style={{ backgroundColor: "rgba(0, 15, 51, 0.5)", zIndex: "9999" }} id={`templateModal${selectedId}`} tabIndex="-1" aria-labelledby="templateModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: width >= 768 ? "65vw" : "100%", margin: "0.8rem auto 0rem auto" }}>
                    <div className="modal-content CPST1modalMain">
                        <div className="modal-body" style={{ padding: "0.7rem" }}>
                            <button ref={templateref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="CPST1close-icon" style={{ zIndex: "2" }} onClick={() => handleCloseModal()}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h2 style={{ paddingTop: "1rem", paddingLeft: "1rem", textAlign: "left", position: "relative", zIndex: "1" }}> {selectedName}</h2>
                            {selectedItem === 1 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <iframe className='CPST1iframe' id="iframeid" src={`${frontendUrl}/duonut/${selectedDisplayId}`} title="Duonut Iframe"></iframe>
                            </div>}
                            {selectedItem === 2 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className='CPST1mobileScreen'></div>
                                <iframe className='CPST1iframeMob' id="iframeid" src={`${frontendUrl}/duonut/${selectedDisplayId}`} title="Duonut Iframe"></iframe>
                            </div>}

                            <div className='CPST1modalBottom'>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "8px 11px 13px 12px" }} onClick={() => setselectedItem(1)}>
                                        <i className="fa fa-desktop" aria-hidden="true"></i>
                                    </div>}
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => setselectedItem(2)}>
                                        <i className="fa fa-mobile" aria-hidden="true" style={{ fontSize: "2rem" }}></i>
                                    </div>}
                                </div>
                                <button className='CPST1TemplateButton' onClick={() => onClickTemplateButton(selectedId, selectedName)}>
                                    <i className="fa fa-plus-circle" style={{ paddingRight: "8px", fontSize: "1rem" }} aria-hidden="true"></i>
                                    USE THIS TEMPLATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <AnimatePresence>
                {modalActive && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                        <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="CFS1modal row" style={{ position: "relative" ,width:"70rem"}}>
                            <div className="CFS1MainContainer">
                                <h1 style={{ marginTop: "2rem", marginBottom:"4rem" }}>Create your workflow or form</h1>
                                {/* {!modalAgain && <> <div style={{ marginBottom: "2rem" }}>Create a blank form or choose from selected templates to start gathering data</div>
                                    <div className="CFS1templateContainer">
                                        <div className="CFS1template">
                                            <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" fill="none">
                                                    <path fill="currentColor" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                                </svg>
                                                <div>Start From Scratch</div>

                                            </div>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); navigate('/ai') }}>
                                                <img src="/assets/generate_ai.png" style={{ height: "4.5rem" }} loading="lazy" />
                                                <div>Generate with AI</div>

                                            </div>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(1)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 1 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1643, 8236057811, "Feedback Template 1")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/1.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Feedback Template 1</h3>
                                            <button onClick={() => onClickTemplateButton(1643, "Feedback Template 1")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(2)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 2 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1801, 5673805858, "Market Research Template")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/2.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Market Research Template</h3>
                                            <button onClick={() => onClickTemplateButton(1801, "Market Research Template")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(3)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 3 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1261, 4682438228, "Utility Checklist Template")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/3.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Utility Checklist Template</h3>
                                            <button onClick={() => onClickTemplateButton(1261, "Utility Checklist Template")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(4)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 4 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1721, 4799020191, "Learning Template 1")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/4.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Learning Template 1</h3>
                                            <button onClick={() => onClickTemplateButton(1721, "Learning Template 1")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                    </div>
                                    <button className="btn-primary" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); navigate('/templates/all'); }} style={{ width: "auto", padding: "0.8rem", margin: "2rem auto" }} >See More Templates</button>
                                </>} */}


                                {modalAgain && <div className="CFS1templateContainer" style={{marginTop: '1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'nowrap' }}>
                                    <div className="CFS1templateBlock" style={{width:"18%",margin:"0"}}>
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" fill="none">
                                                <path fill="#3184C9" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>Start From Scratch</div>

                                    </div>
                                    <div className="CFS1templateBlock" style={{width:"18%",margin:"0"}}>
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleTemplateModal(true)); }}>
                                            <img src="/assets/generate_template.png" alt="duonut" loading="lazy" style={{ width:"40%"}}/>
                                        </div>
                                        {/* <div style={{ marginTop: "1rem" }}>Generate with AI</div> */}
                                        <div style={{ marginTop: "1rem" }}>Create from Template</div>

                                    </div>
                                    <div className="CFS1templateBlock" style={{width:"18%",margin:"0"}}>
                                        <div className="CFS1startscratch" onClick={() => {navigate("/home");dispatch(toggleCreateFromScratchModalDuonut(false));}}>
                                            <img src="/assets/generate_ai1.png" alt="duonut" loading="lazy" />
                                        </div>
                                        {/* <div style={{ marginTop: "1rem" }}>Generate with AI</div> */}
                                        <div style={{ marginTop: "1rem" }}>Generate with AI</div>

                                    </div>
                                    <div className="CFS1templateBlock" style={{width:"18%",margin:"0"}}>
                                                    <div className="CFS1startscratch" onClick={() => importref.current.click()} style={{ height: "14rem", gap: "6px", fontSize: "20px", padding: "10px 5px" }}>
                                                        <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "2.5rem", paddingBottom: "5px", color: "#337ab7", }}></i>
                                                        {/* <span style={{ color: "#337ab7", fontWeight: "bold" }}>Import it now</span> */}
                                                        {/* <span style={{ fontSize: "14.5px", padding: "5px 20px", margin: "0px 10px" }}>Import Typeform surveys to your Duonut.</span> */}
                                                    </div>
                                                    <div style={{ marginTop: "1rem" }}>Import Typeform surveys to your Duonut.</div>
                                                </div>
                                    {/* <div className="CFS1templateBlock">
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); window.location.href = 'https://duonut.com/templates'; }}>

                                            <img src="/assets/template_icon.png" alt="duonut" loading="lazy" />
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>Use Template</div>
                                    </div> */}
                                </div>}

                            </div>

                            <div className="close-icon icon-button CFS1close" onClick={handleClose}>
                                <img src="/assets/icons/close.svg" />
                            </div>
                        </motion.div>
                        <div className="modal-overlay"></div>
                    </motion.div>
                )}
            </AnimatePresence >
        </>
    );
}
