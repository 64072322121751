import { AnimatePresence } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter,  Route, Routes } from "react-router-dom";

import Navbar from "../Components/Navbar";
import Design from "../Pages/Design/Design";
import Home from "../Pages/Home/Home";
import NotFound from "../Pages/404/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "../Components/Modals/Form/Form";
import LogoutModal from "../Components/Modals/Logout";
import { RequireAuth } from "./PrivateRoute";
import Viewer from "../Pages/Viewer/Viewer";
import MyDuonut from "../Pages/MyDuonut/MyDuonut";
import DuonutSettingsPage from "../Components/DuonutSettings/DuonutSettings";
import MainDesign from "../Pages/Design/MainDesign";
import Templates from "../Pages/Templates/Templates";
import TemplateDetailsPage from "../Pages/TemplateDetailsPage/TemplateDetailsPage";
import CreateFromScratch from "../Components/Modals/CreateFromScratch/CreateFromScratch";
import DuonutNameModal from "../Components/Modals/DuonutNameModal/DuonutNameModal";
import OnBoarding from "../Pages/OnBoarding/OnBoarding";

import Contact from "../Pages/ContactUs/Contact";
import Pricing from "../Pages/Pricing/Pricing";

// import Blogs from "../Pages/Blog/Blogs";
import FeedbackForms from "../Pages/Blog/FeedbackForms";
import StoryTelling from "../Pages/Blog/StoryTelling";
import ToDoIn2024 from "../Pages/Blog/ToDoIn2024";
import CutPatientOnboarding from "../Pages/Blog/CutPatientOnboarding";
import ImproveHealthcareWorkflows from "../Pages/Blog/ImproveHealthcareWorkflows";
import EffectiveOnboardingProgram from "../Pages/Blog/EffectiveOnboardingProgram";
import ManufacturingChecklists from "../Pages/Blog/ManufacturingChecklists";

import DemoForm from "../Components/Modals/DemoForm/DemoForm";
import Profile from "../Pages/Profile";
import Payment from "../Pages/Payment/Payment";
import DuonutReports from "../Pages/DuonutReport/DuonutReports";
import DuonutAnalytics from "../Pages/DuonutAnalytics/DuonutAnalytics";

import Channel from "../Pages/Channel/Channel";
import Nugget from "../Pages/Nugget/Nugget";
import ChannelViewer from "../Pages/ChannelViewer/ChannelViewer";
import NuggetViewer from "../Pages/ChannelViewer/NuggetViewer";

import Integrations from "../Components/Integrations/Integrations";
import SlackSuccess from "../Components/SlackIntegration/SlackSuccess";

import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermCondition from "../Pages/TermCondition/TermCondition";
import CookiePolicy from "../Pages/CookiePolicy/CookiePolicy";

import Typeform from "../Pages/Help/Typeform";
import Jotform from "../Pages/Help/Jotform";
import SlackPage from "../Pages/Integrations/SlackPage";
import SlackHelp from "../Pages/Help/SlackHelp";
import HubspotHelp from "../Pages/Help/HubspotHelp";
import ProMessage from "../Components/Modals/ProMessage/ProMessage";
import UserSetting from "../Components/Modals/UserSetting/UserSetting";
import DuonutPageMapping from "../Pages/DuonutPageMapping/DuonutPageMapping";
import Pdf from "../Pages/Pdf";
import FlowChart from "../Components/FlowChart/FlowChart";
import Workflow from "../Pages/Workflow/Workflow";
import Sites from "../Pages/Sites/Sites";
import LoginPage from "../Pages/LoginPage/LoginPage";

import Insurance from "../Pages/Usecase/Insurance";
import HealthCare from "../Pages/Usecase/HealthCare";
import Manufacturing from "../Pages/Usecase/Manufacturing";
import Education from "../Pages/Usecase/Education";
import Schedules from "../Components/Schedules/Schedules";
import HubSpotSuccess from "../Components/HubSpotIntegration/HubSpotSuccess";
import HubspotPage from "../Pages/Integrations/HubspotPage";

import AiPage from "../Pages/AiPage/AiPage";
import ForgotPassword from "../Components/Modals/Form/forgotPassword";
import ChangePasswordModal from "../Components/Modals/Form/changePasswordModal";
import UserMapping from "../Components/Modals/UserMapping/UserMapping";

// import AiStartPage from "../Pages/AiStartPage/AiStartPage";
// import AiData from "../Pages/AiData/AiData";
// import AiDuonutPage from "../Pages/AiDuonutPage/AiDuonutPage";
// import EmbedAiDuonutPage from "../Pages/AiDuonutPage/EmbedAiDuonutPage";

import AiStartPageNew from "../Pages/AiStartPageNew/AiStartPageNew";
import AiDataNew from "../Pages/AiDataNew/AiDataNew";
import AiDuonutPageNew from "../Pages/AiDuonutPageNew/AiDuonutPageNew";
import EmbedAiDuonutPageNew from "../Pages/AiDuonutPageNew/EmbedAiDuonutPageNew";
import Panel from "../Pages/PanelData/Panel";
import Credit from "../Pages/Credit/Credit";
import TemplatesModal from "../Components/Modals/TemplateModal/TemplatesModal";
import TemplateDetailsPageModal from "../Components/Modals/TemplateModal/TemplateDetailsPageModal";

// import ActivityCategoriesModal from "../components/Modals/ActivityCategories/activityCategories";
// import DiscardOrSaveChanges from "../components/Modals/DiscardOrSaveChanges";
// import DuonutSettings from "../components/Modals/duonutSettings";
// import Preview from "../components/Modals/Preview";
// import VideoUpload from "../components/Modals/VideoUpload";
// import About from "../pages/AboutUs/About";
// import AllDuonut from "../viewerPages/AllDuonut/AllDuonut";
// import Dashboard from "../viewerPages/Dashboard/Dashboard";
// import Reports from "../viewerPages/Reports/Reports";
// import Test from "../viewerPages/Test/Test";
// import ConfirmReplace from "../components/Modals/ConfirmReplace/confirmReplace";
// import ConfirmReplaceDuonut from "../components/Modals/ConfirmReplaceDuonut/confirmReplaceDuonut";
// import AcquirePage from "../viewerPages/AcquirePage/AcquirePage";
// import AcquireDetailsPage from "../viewerPages/AcquireDetailsPage/AcquireDetailsPage";
// import CreatePage from "../pages/Create/CreatePage";
// import CreateSubTypePage from "../pages/CreateSubType/CreateSubTypePage";
// import CaseStudies from "../pages/CaseStudies/CaseStudies";
// import CaseStudies2 from "../pages/CaseStudies2/CaseStudies2";
// import CaseStudies3 from "../pages/CaseStudies3/CaseStudies3";
// import Category from "../pages/Category/Category";
// import CategoryTemplates from "../pages/CategoryTemplates/CategoryTemplates";
// import Home5 from "../pages/Home5";
// import FromUrl from "../components/Modals/FromUrl/FromUrl";
// import { PreferenceModal } from "../components/Modals/PreferenceModal/Preference";
// import Feedback from "../components/Modals/Feedback/Feedback";
// import Unsubscribe from "../pages/Unsubscribe/Unsubscribe";

// import Example1 from "../pages/UserExamples/Example1";


// import ConfirmDeleteUser from "../components/Modals/ConfirmDeleteUser/ConfirmDeleteUser";
// import TemplateModal from "../components/Modals/TemplateModal/TemplateModal";


//will contain all the navigation logic
const AppRoutes = () => {
  const {  forgotPasswordActive, changePasswordModalActive } = useSelector((state) => state.formDetails);
  // const { videoModalActive } = useSelector((state) => state.activityCategories);
  // const { modalOpen: previewModalOpen } = useSelector((state) => state.preview);
  // const { modalOpen: settingsModalOpen } = useSelector((state) => state.duonutSettings);
  // const { preferenceActive } = useSelector((state) => state.userActivity);
  const { isLoggedIn } = useSelector((state) => state.user);
  // const { active: confirmReplaceModalActive } = useSelector((state) => state.sidebarSliders.confirmReplaceModal);
  const userSettingModalActive = useSelector((state) => state.userSetting.modalActive);
  const userMappingModalActive = useSelector((state) => state.userMapping.modalActive);

  return (
    <AnimatePresence>
      <BrowserRouter>
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} />
        <>
          {forgotPasswordActive && <ForgotPassword />}
          {changePasswordModalActive && <ChangePasswordModal />}
          {/* {previewModalOpen && <Preview />}
          {videoModalActive && <VideoUpload />}
          {settingsModalOpen && <DuonutSettings />}
          {preferenceActive && <PreferenceModal />}
          <ConfirmReplace />
          <ConfirmReplaceDuonut />*/}
          {userSettingModalActive && <UserSetting />}
          {userMappingModalActive && <UserMapping />}
          <DemoForm />
          <DuonutNameModal />
          <CreateFromScratch />
          <Form />
          <LogoutModal />
          <ProMessage />
          <TemplatesModal />
          {/* <ActivityCategoriesModal />
          <DiscardOrSaveChanges />
          <FromUrl />
          <Feedback />
          <ConfirmDeleteUser />
          <TemplateModal /> */}

          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/usergrowth" element={<Home />} /> */}
            {/* <Route path="/pdf" element={<Pdf />} /> */}

            <Route path="/pdf/:id" element={<Pdf />} />

            <Route path="/design" element={<Design editing={false} />} />
            {/* <Route path="/design/:id" element={<Design editing={true} />} /> */}
            <Route
              path="/design/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <MainDesign editing={true} />
                </RequireAuth>
              }
            >
              <Route
                index
                element={
                  <RequireAuth isLoggedIn={isLoggedIn}>
                    <Design editing={true}  />
                  </RequireAuth>
                }
              />
              <Route
                path="settings"
                element={<DuonutSettingsPage />}
              />
              <Route
                path="flow"
                element={<FlowChart />}
              />
              <Route
                path="integrations"
                element={<Integrations />}
              />
              <Route
                path="Schedules"
                element={<Schedules />}
              />
              <Route
                path="reports"
                element={<DuonutReports />}
              />
              <Route
                path="analytics"
                element={<DuonutAnalytics />}
              />

            </Route>

            <Route path="/duonut/:id" element={<Viewer />} />

            <Route path="/templates/:id" element={<Templates />} />
            <Route path="/templates/:id/:anotherId" element={<TemplateDetailsPage />} />

            <Route path="/termsandconditions" element={<TermCondition />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/cookiepolicy" element={<CookiePolicy />} />
            <Route path="/contactus" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />

            <Route
              path="/profile"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/payment/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Payment />
                </RequireAuth>
              }
            />

            <Route
              path="/duonutreports/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <DuonutReports />
                </RequireAuth>
              }
            />

            <Route
              path="/duonutanalytics/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <DuonutAnalytics />
                </RequireAuth>
              }
            />

            <Route
              path="/workflow/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Channel />
                </RequireAuth>
              }
            />
            <Route
              path="/workflows/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Nugget />
                </RequireAuth>
              }
            />
            <Route path="/viewworkflow/:id" element={<ChannelViewer />} />
            <Route path="/viewworkflows/:id" element={<NuggetViewer />} />

            <Route
              path="/createworkflow"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Workflow />
                </RequireAuth>
              }
            />
            <Route
              path="/workflow"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Sites />
                </RequireAuth>
              }
            />

            <Route path="/ai" element={<AiPage />} />

            {/* <Route path="/home" element={<AiStartPage />} />
            <Route
              path="/aidata"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <AiData />
                </RequireAuth>
              }
            />
            <Route path="/survey/:id" element={<AiDuonutPage />} />
            <Route path="/survey/embed/:id" element={<EmbedAiDuonutPage />} /> */}

            <Route path="/home" element={<AiStartPageNew />} />
            <Route
              path="/aidata"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <AiDataNew />
                </RequireAuth>
              }
            />
            <Route path="/survey/:id" element={<AiDuonutPageNew />} />
            <Route path="/survey/embed/:id" element={<EmbedAiDuonutPageNew />} />

            {/* <Route path="/aboutus" element={<About />} />
            
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/all_duonuts" element={<AllDuonut />} />
            <Route path="/all_duonuts/:id" element={<AllDuonut />} />
            

            <Route path="/duonut/test" element={<Test />} />
            {/* <Route path="/reports" element={<Reports />} /> 
            <Route
              path="/reports"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Reports />
                </RequireAuth>
              }
            />

            <Route path="/mentalhealth" element={<CaseStudies />} />
            <Route path="/edtech" element={<CaseStudies2 />} />
            <Route path="/d2c" element={<CaseStudies3 />} />

            <Route path="/create" element={<CreatePage />} />
            <Route path="/create/:id" element={<CreateSubTypePage />} />

            <Route path="/templates" element={<Category />} />
            <Route path="/templates/:id" element={<CategoryTemplates />} />
            <Route path="/templates/:id/:anotherId" element={<AcquireDetailsPage />} />*/}

            <Route path="/pagemapping/:id" element={<DuonutPageMapping />} />

            <Route path="/help/duonut-a-free-typeform-alternative" element={<Typeform />} />
            <Route path="/help/best-free-jotform-alternative" element={<Jotform />} />
            <Route path="/help/how-to-integrate-duonut-with-slack" element={<SlackHelp />} />
            <Route path="/help/how-to-integrate-duonut-with-hubspot" element={<HubspotHelp />} />

            {/* <Route path="/blog" element={<Blogs />} /> */}
            <Route path="/blog/feedback-forms" element={<FeedbackForms />} />
            <Route path="/blog/story-telling" element={<StoryTelling />} />
            <Route path="/blog/to-do-in-2024" element={<ToDoIn2024 />} />
            <Route path="/blog/cut-patient-onboarding" element={<CutPatientOnboarding />} />
            <Route path="/blog/improve-healthcare-workflows" element={<ImproveHealthcareWorkflows />} />
            <Route path="/blog/onboarding-documents" element={<EffectiveOnboardingProgram />} />
            <Route path="/blog/manufacturing-checklists" element={<ManufacturingChecklists />} />

            <Route path="/usecases/insurance" element={<Insurance />} />
            <Route path="/usecases/healthcare" element={<HealthCare />} />
            <Route path="/usecases/education" element={<Education />} />
            <Route path="/usecases/manufacturing" element={<Manufacturing />} />

            <Route path="/integrations/slack" element={<SlackPage />} />
            <Route path="/integrations/hubspot" element={<HubspotPage />} />

            <Route path="/slacksuccess" element={
              <RequireAuth isLoggedIn={isLoggedIn}>
                <SlackSuccess />
              </RequireAuth>} />
            <Route path="/hubspotsuccess" element={
              <RequireAuth isLoggedIn={isLoggedIn}>
                <HubSpotSuccess />
              </RequireAuth>} />

            {/*  <Route path="/allduonuts" element={<MyDuonut />} />
            <Route path="/acquire" element={<AcquirePage />} />
            <Route path="/acquire/:id" element={<AcquirePage />} /> */}
            {/* <Route path="/templatedetails/:id" element={<AcquireDetailsPage />} /> */}
            {/* <Route path="/myduonuts" element={<MyDuonut isMyDuonuts={true} />} /> */}
            <Route
              path="/myduonuts"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <MyDuonut isMyDuonuts={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/credit"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Credit />
                </RequireAuth>
              }
            />
            <Route
              path="/paneldata"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Panel />
                </RequireAuth>
              }
            />
            <Route
              path="/onboarding"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <OnBoarding />
                </RequireAuth>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      </BrowserRouter>
    </AnimatePresence >
  );
};

export default AppRoutes;
