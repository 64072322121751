import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("token", token);
  return header;
};


export const aiDuonutNewSlide = createSlice({
  name: "aiDuonutNew",
  initialState: {
    response: [],
    data: []
  },
  reducers: {
    saveAiDuonutNewAns: (state, action) => {
      state.response = [action.payload];
    },
    saveAiDuonutNewData: (state, action) => {
      state.data = [action.payload];
    }
  }
});

export const getAiDuonutNewAsync = (paramsid) => async (dispatch) => {
  try {
    const display_id = paramsid.id;
    // console.log(display_id)
    // const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/aiviewernew/` + display_id;
    const response = await axios.get(API_URL);
    // console.log(response.data);
    dispatch(saveAiDuonutNewData(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createAiDuonutNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_ai_duonut_new`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editAiDuonutNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_ai_duonut_new`;
    const response = await axios.patch(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};


export const deleteAiDuonutNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_ai_duonut`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveAiAnsNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/save_ai_answer_data_new`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAiAnsNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/get_ai_answer_data_new`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveAiDuonutNewAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAiConversationAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_ai_conversation`;
    const response = await axios.post(API_URL, data, header);
    console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getRatingFeedbackAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_rating_feedback`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getConversationSummaryAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_conversation_summary`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOpenEndedQuestionAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_open_ended`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOpenEndedAiQuestionsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_open_ended_ai_question`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOpenEndedAiImageAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_open_ended_image`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getQuestionAnswerSummaryAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_open_ended_question_answer_summary`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};
export const getUserQuestionAnswerSummaryAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_open_ended_user_question_answer_summary`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveAiDuonutNewAns, saveAiDuonutNewData } = aiDuonutNewSlide.actions;
export const showAiDuonutNewresponse = (state) => state.aiDuonutNew.response;
export const showAiDuonutNewData = (state) => state.aiDuonutNew.data;
export default aiDuonutNewSlide.reducer;
