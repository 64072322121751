import React from 'react';
import { useLocation, } from "react-router-dom";
import { useSelector, } from "react-redux";

const Image = ({
  id,
  elementAttr: {
    url, url_data, border, margin, padding, borderRadius, linkUrl
  },
}) => {
  const location = useLocation();
  // const { viewType } = useSelector((state) => state.duonutSettings);

  const handleClickImage = () => {
    if (location.pathname.includes("/duonut/") && linkUrl) {
      window.open(linkUrl)
    }
  }

  return (
    <div style={{
      display: "flex",
       justifyContent: "center", width: '100%', height: '100%'
    }}
      onClick={() => handleClickImage()}>
      <img src={(url_data !== undefined && Object.keys(url_data).length !== 0) ? url_data.uri : url} style={{
        maxWidth: '100%', maxHeight: '100%', objectFit: "cover",
        cursor: (location.pathname).includes("/duonut/") ? "default" : 'default',
        border, margin, padding, borderRadius
      }} alt="" />
    </div>
  );
}

export default Image;
