import React, { useRef, useEffect, useState } from 'react';
import './ShowTemplates.css';
// import { frontendUrl } from "../../../services/constants";
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter"
import InfiniteScroll from "react-infinite-scroll-component";

const ShowTemplates= ({ data,setcategoryNameCopy,setIdName }) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [mouseEnterId, setmouseEnterId] = useState(0);
    const [selectedMasterData, setselectedMasterData] = useState([]);
    const [selectedItem, setselectedItem] = useState(1);
    const [selectedData, setselectedData] = useState(data.slice(0, 12));
    // const templateref = useRef(null);
    const templateref1 = useRef(null);
    const templateopenref = useRef(null)
   
    console.log(data);
    useEffect(() => {
        setselectedData(data.slice(0, 12));
    }, [data]);

    useEffect(() => {
        if (width <= 768) {
            setselectedItem(2);
        }
    }, [width]);

    const onClickCard = (masterData) => {
       // console.log(masterData)
        setselectedMasterData(masterData);
        // Remove special characters and convert to lowercase
        let cleanedString = masterData.name.replace(/[^\w\s]/gi, '').toLowerCase();

        // Replace spaces with hyphens
        let resultString = cleanedString.replace(/\s+/g, '-');
        setIdName(resultString); 
        // console.log(idName);

        let categoryName = masterData?.duonut_category_id?.name?.toLowerCase().replace(/ /g, "_");
        setcategoryNameCopy(categoryName);
        // console.log(categoryNameCopy);

        // navigate(`/templates/${categoryName}/${resultString}`);
        // setTimeout(() => {
        //     templateref.current.click();
        // }, 1000); //miliseconds

        
        
    }

    const handleClose = () => {
        templateref1.current.click();
    }
    useOutsideAlerter(templateopenref, handleClose);

    const onClickTemplateBtn = () => {
        window.open(`/design?selectedDuonutId=${selectedMasterData.id}`);
    }

    const onClickLoadMore = () => {
        // console.log("qwerr")
        // Load 12 more elements from data array
        const nextData = data.slice(selectedData.length, selectedData.length + 12);
        setselectedData(prevData => [...prevData, ...nextData]);
    }
    // console.log("selectedData", selectedData, data)

    return (
        <div>
            {/* <button ref={templateref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#templateModal${selectedMasterData?.duonut_id?.id}`}>
            </button>
            <div className="modal fade" ref={templateopenref} style={{ backgroundColor: "rgba(0, 15, 51, 0.5)" }} id={`templateModal${selectedMasterData?.duonut_id?.id}`} tabIndex="-1" aria-labelledby="templateModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: width >= 768 ? "65vw" : "100%", margin: "0.8rem auto 0rem auto" }}>
                    <div className="modal-content CPST1modalMain">
                        <div className="modal-body" style={{ padding: "0.7rem" }}>
                            <button ref={templateref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="CPST1close-icon" style={{ zIndex: "2" }} onClick={() => handleClose()}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h2 style={{ paddingLeft: "1rem", textAlign: "left", position: "relative", zIndex: "1" }}> {selectedMasterData?.name}</h2>
                            {selectedItem === 1 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <iframe className='CPST1iframe' id="iframeid" src={`${frontendUrl}/duonut/${selectedMasterData?.display_id}`} title="Duonut Iframe"></iframe>
                            </div>}
                            {selectedItem === 2 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className='CPST1mobileScreen'></div>
                                <iframe className='CPST1iframeMob' id="iframeid" src={`${frontendUrl}/duonut/${selectedMasterData?.display_id}`} title="Duonut Iframe"></iframe>
                            </div>}

                            <div className='CPST1modalBottom'>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "8px 11px 13px 12px" }} onClick={() => setselectedItem(1)}>
                                        <i className="fa fa-desktop" aria-hidden="true"></i>
                                    </div>}
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => setselectedItem(2)}>
                                        <i className="fa fa-mobile" aria-hidden="true" style={{ fontSize: "2rem" }}></i>
                                    </div>}
                                </div>
                                <button className='CPST1TemplateButton' onClick={() => onClickTemplateBtn()}>
                                    <i className="fa fa-plus-circle" style={{ paddingRight: "8px", fontSize: "1rem" }} aria-hidden="true"></i>
                                    USE THIS TEMPLATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='CPST1activityDuonuts'>
                {/* <p className="CPST1subtype_name"> {header}</p> */}

                <InfiniteScroll
                    dataLength={selectedData.length}
                    next={onClickLoadMore}
                    hasMore={data.length > selectedData.length ? true : false}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    <div className='CPST1templatesContainer' style={{display: 'flex',
                        // justifyContent: 'center'
                        }}>
                        {selectedData.map((master, idx) => {
                            if (master.visibility === false) return null;
                            return (
                                <div key={idx} className="CPST1templateCard" onClick={() => onClickCard(master)}
                                    onMouseEnter={() => setmouseEnterId(master.id)}
                                    onMouseLeave={() => setmouseEnterId(0)}>

                                    <div className="CPST1templateCardImgContainer" id={`selected-slide-${master.id}`}>
                                        <div className='CPST1templateCardHover' style={{ opacity: mouseEnterId === master.id ? "1" : "0" }}>
                                            <span className='CPST1templateCardHoverCircle'>
                                                <i className="fa fa-plus" style={{ color: "black" }} aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        {master.setting?.coverPhoto ? <img className="skeleton CPST1templateCardImg" loading="lazy" alt='duonut'
                                            onLoad={(e) => e.target.classList.remove("skeleton")}
                                            src={master.setting?.coverPhoto} />
                                            : master.user_activity[0]?.thumbnail ? <img className="skeleton CPST1templateCardImg" loading="lazy" alt='duonut'
                                                onLoad={(e) => e.target.classList.remove("skeleton")}
                                                src={master.user_activity[0]?.thumbnail} />
                                                : <img className="skeleton CPST1templateCardImg" loading="lazy" alt='duonut'
                                                    onLoad={(e) => e.target.classList.remove("skeleton")}
                                                    src="/assets/thumbnail.jpg" />}
                                    </div>
                                    <div className='CPST1templateCardText'>
                                        <div className='CPST1templateCardTextInner'>{master.name}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );

}

export default ShowTemplates;