import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("token", token);
  return header;
};


export const aiNewSlide = createSlice({
  name: "aiNew",
  initialState: {
    response: [],
    data: []
  },
  reducers: {
    saveAiNew: (state, action) => {
      state.response = [action.payload];
    },
    saveAiNewData: (state, action) => {
      state.data = [action.payload];
    }
  }
});

export const saveAiNewAsync = () => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_ai_detail_new`;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveAiNew(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const saveAiNewAsync1 = () => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_ai_detail_new`;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createAiNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_ai_new_data`;
    const response = await axios.post(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editAiNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_ai_data`;
    const response = await axios.patch(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};


export const deleteAiNewAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_ai_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const getWebsiteDataAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/get_website_data`;
    const response = await axios.post(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};
// export const getWhatsappConversation = () => async (dispatch) => {
//   try {
//     const header = getAuthHeaders();
//     var API_URL;
//     API_URL = `${domain}/duonut/get_whatsapp_conversation`;
//     const response = await axios.post(API_URL, header);
//     console.log(response.data);
//     // dispatch(saveAiNew(response.data));
//   } catch (err) {
//     throw new Error(err);
//   }
// };


export const { saveAiNew, saveAiNewData } = aiNewSlide.actions;
export const showAiNewresponse = (state) => state.aiNew.response;
export const showAiNewData = (state) => state.aiNew.data;
export default aiNewSlide.reducer;
