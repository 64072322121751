import { createSlice } from "@reduxjs/toolkit";
// import { domain } from "../../services/constants";
// import axios from 'axios';

// const axios = require("axios");

export const createFromScratchSlide = createSlice({
    name: "createFromScratch",
    initialState: {
        response: [],
        modalActive: false,
        modalAgain: true,
        templateModal:false,
    },
    reducers: {
        saveCreateFromScratch: (state, action) => {
            state.response = [action.payload];
        },
        toggleCreateFromScratchModalDuonut: (state, { payload }) => {
            state.modalActive = payload;
        },
        toggleCreateFromScratchModalAgain: (state, { payload }) => {
            state.modalAgain = payload;
        },
        toggleTemplateModal:(state,{payload})=>{
            state.templateModal = payload;
        },
    }
});

// export const saveCreateFromScratchAsync = (data) => async (dispatch) => {
//     try {
//         console.log(data);
//         const API_URL = `${domain}/duonut/save_demo`;
//         const response = await axios.post(API_URL, data);
//         console.log(response);
//         dispatch(saveCreateFromScratch(response.data));
//     } catch (err) {
//         throw new Error(err);
//     }
// };

export const { saveCreateFromScratch, toggleCreateFromScratchModalDuonut, toggleCreateFromScratchModalAgain, toggleTemplateModal } = createFromScratchSlide.actions;
export const showcreateFromScratchresponse = (state) => state.createFromScratch.response;
export default createFromScratchSlide.reducer;
