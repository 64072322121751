import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./AiSettingNew.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { toggleAiNewModal, updateAiNewData } from "../../../redux/slices/AiSettingNewReducer";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { domain, frontendUrl } from "../../../services/constants";
import QRCode from "react-qr-code";
// import ProlificPanel from "../Panel/ProlificPanel";
import RespondentPanel from "../Panel/RespondentPanel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { editAiDuonutNewAsync } from "../../../redux/slices/AiDuonutNewReducer";
import { saveAiNewAsync } from "../../../redux/slices/AiNewReducer";
import { creditDeduction } from "../../../services/duonut";
import { AnimatePresence, motion } from 'framer-motion';
import { getUserDetails } from "../../../services/auth";
import { updateCredit, updateTotalCredit } from "../../../redux/slices/user";
import axios from "axios";

export default function AiSettingNew() {
    const { active: aiActive, aiData } = useSelector((state) => state.AiSettingNew.aiNewModalSetting);
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const { credit } = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    var userEmail = localStorage.getItem("userEmail");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    // console.log("aiData", aiData);
    const dispatch = useDispatch();
    // const { width } = useWindowDimensions();
    const creditref = useRef(null);
    const creditref1 = useRef(null);
    const modelRef = useRef(null);
    const qrref = useRef(null);
    const qrref1 = useRef(null);
    const panelref = useRef(null);
    const panelref1 = useRef(null);
    const whatsappRef = useRef(null);
    const whatsappRef1 = useRef(null);
    const [iscopylink, setiscopylink] = useState(false);
    const [iscopylink1, setiscopylink1] = useState(false);
    const [iscopyiframe, setiscopyiframe] = useState(false);
    const [iscopyhtml, setiscopyhtml] = useState(false);
    const [openPreview, setopenPreview] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [panelType, setpanelType] = useState(2);
    const [openSettings, setIsOpenSettings] = useState("");
    const [conversationLimit, setconversationLimit] = useState(2);
    const [totalUserResponse, settotalUserResponse] = useState(10);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error_message, setErrorMessage] = useState("");
    const [eventNo, seteventNo] = useState(0);
    const [whatsAppNumber, setWhatsAppNumber] = useState(""); // Store input as a string
    const [whatsAppNumberList, setWhatsAppNumberList] = useState([]); // Store array of numbers
    const [whatsappBusinessNumber, setWhatsAppBuisnessNumber] = useState("");
    const [step, setStep] = useState(0);
    const [error_msg, setError_msg] = useState('');
    const [inputBusinessNumber, setInputBusinessNumber] = useState("");


    const [formData, setFormData] = useState({
        emailId: "",
        contactNumber: "", // Allow multiple contact numbers
        socialLinks: [{ platform: "", link: "" }] // Structured social media links
    });
    const [errors, setErrors] = useState({ email: "", contact: "" });

    // const countryCodeRegex = /^\+(\d{1,3})\d{4,14}$/;
    // const validWhatsAppNumberRegex = /^\+([1-9]\d{0,2})(\d{8,15})$/;  // General pattern for international numbers
    // const validIndianNumberRegex = /^\+91\d{10}$/;  // Specific pattern for Indian numbers (Exactly 10 digits after +91)

    // const validateWhatsAppNumber = (number) => {
    //     return validIndianNumberRegex.test(number);
    // }

    const validWhatsAppNumberRegex = /^\+(91\d{10}|([1-9]\d{1,2})\d{8,15})$/;

    const validateWhatsAppNumber = (number) => validWhatsAppNumberRegex.test(number);

    const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateEmail = (email) => validEmailRegex.test(email);


    const check_number = aiData?.aiData?.whatsapp_no;
    // console.log(check_number);

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    useEffect(() => {
        // console.log("C-D", aiData);
        if (aiData) {
            setconversationLimit(aiData?.conversation_depth);
            settotalUserResponse(aiData?.total_user_response);
        }
    }, [aiData]);

    useEffect(() => {
        if (aiData) {
            setWhatsAppBuisnessNumber(aiData?.aiData?.whatsapp_no);
        } else {
            setWhatsAppBuisnessNumber("");
        }
    }, [aiData]);

    useEffect(() => {
        console.log("aiData", aiData);
        if (aiData?.aiData) {
            setFormData({
                emailId: aiData.aiData.email_id || "",
                contactNumber: aiData.aiData.contact_no || "",
                socialLinks: aiData.aiData.social_id && Object.keys(aiData.aiData.social_id).length
                    ? Object.entries(aiData.aiData.social_id).map(([platform, link]) => ({ platform, link }))
                    : [{ platform: "", link: "" }]
            });
        }
    }, [aiData]);

    useEffect(() => {
        if (whatsappBusinessNumber !== null) {
            setStep(2);
        }
        else {
            setStep(1);
        }
    })
    const handleClose = () => {
        dispatch(toggleAiNewModal(false));
        dispatch(updateAiNewData({}));
        setiscopylink(false);
        setiscopyiframe(false);
        setiscopyhtml(false);
        setopenPreview(false);
    }
    useOutsideAlerter(modelRef, handleClose);

    const copylink = () => {
        var copyText = `${frontend_url}/survey/${aiData?.display_id}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink(true);
    }
    const copylink1 = () => {
        var copyText = `${frontend_url}/survey/${aiData?.display_id}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink1(true);
    }

    const copyiframe = () => {
        var copyText = `<iframe
        src="${frontend_url}/survey/embed/${aiData?.display_id}"
        title="Survey"
        frameborder="0"
        scrolling="no"
        style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:400px;width:500px;position:absolute;left:0;top:0">
            <p>Your browser does not support iframes.</p>
        </iframe>`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopyiframe(true);
    }

    const copyHtml = () => {
        var copyText = `
        <html dir="ltr" lang="en">
        <head>
            <meta content="text/html; charset=UTF-8" http-equiv="Content-Type"/>
            <meta name="x-apple-disable-message-reformatting"/>
            <style>
                @font-face {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                mso-font-alt: 'Verdana';
                src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
                }
                * {
                font-family: 'Roboto', Verdana;
                }
            </style>
        </head>
        <table align="center" width="100%" class="p-0.5/2" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="font-family:Roobert, Inter, ui-sans-serif, system-ui, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;margin:auto;width:fit-content;border-radius:0.5rem;background-color:rgb(243,244,246)">
            <tbody>
                <tr>
                    <td>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin:0.25rem;width:fit-content;border-radius:0.5rem;background-color:rgb(255,255,255);padding-bottom:1.25rem;padding-left:0.125rem;padding-right:0.125rem;padding-top:1.25rem">
                        <tbody>
                            <tr>
                                <td>
                                <p style="font-size:1.25rem;line-height:1.75rem;margin:0px;margin-bottom:2rem;text-align:center;font-weight:600">${aiData?.question}</p>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="text-align:center;height:3rem;">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=1" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                                
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">1</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=2" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                              
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">2</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=3" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                               
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">3</span>
                                              
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=4" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                               
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">4</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=5" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                              
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">5</span>
                                              
                                            </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                 <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:240px;width:100%">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column" style="text-align:left;font-size:0.75rem;line-height:1rem;text-transform:uppercase">Negative</td>
                                            <td data-id="__react-email-column" style="text-align:right;font-size:0.75rem;line-height:1rem;text-transform:uppercase">Positive</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin-top:2rem">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column">
                                            <p style="font-size:0.75rem;line-height:1rem;margin:0px;text-align:center;color:rgb(156,163,175)">Powered by</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column" style="text-align:center"><img alt="Duonut Logo" src="https://app.duonut.com/assets/duonut_logo.png" style="margin-left:auto;margin-right:auto;height:auto;width:8rem"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column">
                                            <p style="font-size:0.75rem;line-height:1rem;margin:0px;text-align:center;color:rgb(156,163,175)">Sign up <a href="https://duonut.com" style="color:inherit;text-decoration:none;user-select:none;text-decoration-line:underline" target="_blank">here</a> to send your first survey.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </td>
                </tr>
            </tbody>
        </table>
        </html>
        `;
        navigator.clipboard.writeText(copyText);
        setiscopyhtml(true);
    }

    const continueBtn = () => {
        qrref1.current.click();
    }

    const downloadbtn = () => {
        // Get the SVG element
        const svg = document.getElementById("qr-gen");

        // Check if SVG element exists and aiData.display_id is defined
        if (!svg) {
            console.error("SVG element is missing");
            return;
        }

        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas size based on SVG's bounding box
        const svgSize = svg.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        // Serialize SVG to a string and create a Blob
        const svgData = new XMLSerializer().serializeToString(svg);
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        // Create a new image and draw the SVG onto the canvas
        const img = new Image();
        img.onload = () => {
            // Draw the SVG onto the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);  // Use canvas dimensions
            URL.revokeObjectURL(url);  // Clean up URL

            // Convert canvas to data URL and trigger download
            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `qrcode.png`;  // Ensure filename is set correctly
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        img.onerror = (err) => {
            console.error("Error generating PNG:", err);
        };

        img.src = url;
    }

    const updateConversationLimit = (value) => {
        if (parseInt(value) >= 2) {
            var aiDuonutData = {
                id: aiData.id,
                conversation_depth: parseInt(value),
            }
            dispatch(editAiDuonutNewAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    dispatch(saveAiNewAsync());
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }

    const updateNoOfUserViews = (value) => {
        if (parseInt(value) >= 10) {
            var aiDuonutData = {
                id: aiData.id,
                total_user_response: parseInt(value),
            }
            dispatch(editAiDuonutNewAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    dispatch(saveAiNewAsync());
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }
    const [isEditingConversation, setIsEditingConversation] = useState(false);
    const [isEditingUserViews, setIsEditingUserViews] = useState(false);

    var costConversationLimit = 0;
    var perConversationCost = 2;
    var costUserResponse = 0;
    var perUserCreditCost = 2;

    const newLimitChange = conversationLimit - aiData?.conversation_depth;
    // console.log("New Limit", newLimitChange, conversationLimit, aiData?.conversation_depth);
    if (newLimitChange < 0) { costConversationLimit = 0; }
    else {
        const applicableIncrements_conversation = Math.ceil((newLimitChange) / 2);
        costConversationLimit = perConversationCost * applicableIncrements_conversation;
    }

    const saveConversationLimit = () => {
        // console.log(costConversationLimit, credit, aiData, aiData?.websiteData, aiData?.websiteData?.company_name);
        if (credit >= costConversationLimit) {
            setIsEditingConversation(false);
            updateConversationLimit(conversationLimit);

            var name = aiData.aiData?.websiteData?.company_name ? aiData.aiData?.websiteData?.company_name : aiData.aiData?.title;

            var creditData = {
                name: `AI scan - Conversation Depth - ${name}`,
                message: `Conversation Depth : ${name} - ${aiData?.display_id}`,
                cost: costConversationLimit,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            if (costConversationLimit === 0) {
                setMessage('No Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);

            }
            getUserDetails((err, userDetails) => {
                if (err) return;
                dispatch(updateTotalCredit(userDetails.data.total_credit));
                dispatch(updateCredit(userDetails.data.credit));

            });
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const newUserResponses = totalUserResponse - aiData?.total_user_response;
    if (newUserResponses < 0) { costUserResponse = 0; }
    else {
        const applicableIncrements_userResponse = Math.ceil((newUserResponses) / 2);
        costUserResponse = perUserCreditCost * applicableIncrements_userResponse;
    }
    const saveUserViews = () => {
        if (credit >= costUserResponse) {
            setIsEditingUserViews(false);
            updateNoOfUserViews(totalUserResponse);
            var name = aiData.aiData?.websiteData?.company_name ? aiData.aiData?.websiteData?.company_name : aiData.aiData?.title;
            var creditData = {
                name: `AI scan - Number of User Views - ${name}`,
                message: `Number of User Views : ${name} - ${aiData?.display_id}`,
                cost: costUserResponse,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            if (costUserResponse === 0) {
                setMessage('No Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);

            }
            getUserDetails((err, userDetails) => {
                if (err) return;
                dispatch(updateTotalCredit(userDetails.data.total_credit));
                dispatch(updateCredit(userDetails.data.credit));

            });
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const creditDeduct = () => {
        if (eventNo === 8) {
            saveConversationLimit();
        } else if (eventNo === 9) {
            saveUserViews();
        }
        creditref1.current.click();
    };
    const handleBack = () => {
        setStep(1);
        setError_msg("");
    }
    const handleNext = async () => {
        const token = localStorage.getItem("access");
        if (credit < 100) {
            setErrorMessage("There is no sufficient credit to perform this operation");
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            return;
        }
        // Validate if inputBusinessNumber is provided and matches the expected format
        if (inputBusinessNumber && inputBusinessNumber.trim() !== "") {
            // Business Number Validation (using regex for country code and phone number)
            if (!validateWhatsAppNumber(inputBusinessNumber)) {
                setError_msg('Invalid WhatsApp Number. Use +CountryCodePhoneNumber (e.g., +14155552671).');
                setTimeout(() => setError_msg(''), 5000);
                return;
            }
            setWhatsAppBuisnessNumber(inputBusinessNumber); // Update the business number state
            setStep(2); // Move to step 2
            setError_msg(""); // Clear any previous error

            // Prepare the data to send to the backend
            const cleanNumber = inputBusinessNumber.replace('+', ''); // Clean number by removing '+'

            const data = {
                id: aiData?.aiData?.id,
                whatsapp_no: cleanNumber  // Only pass the cleaned number
            };

            // Safely compare whatsapp_no values
            const currentBusinessNumber = aiData?.aiData?.whatsapp_no ? aiData.aiData.whatsapp_no.replace('+', '') : null;

            // Only send the request if the business number has changed or is null
            if (cleanNumber !== currentBusinessNumber || currentBusinessNumber === null) {
                try {
                    const response = await fetch(`${domain}/duonut/edit_ai_new_data`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    });

                    if (response.ok) {
                        setStep(2);
                        // Handle success if needed
                        if (response.status === 200) {
                            // whatsappRef1.current.click();
                            const name = aiData.aiData?.websiteData?.company_name || aiData.aiData?.title;

                            const creditData = {
                                name: `Business Whatsapp Number Added - ${name}`,
                                message: `Business Whatsapp Number Added: ${name}`,
                                cost: 100,
                            };

                            creditDeduction(creditData, (err, res) => {
                                if (err) console.log(err.response);
                            });

                            setMessage("Credit has been deducted");
                            setShowSuccessMessage(true);
                            setTimeout(() => setShowSuccessMessage(false), 5000);
                            return { success: true };
                        } else {
                            console.error("Error submitting data");
                            return { success: false };
                        }
                    } else {
                        console.error('Error updating business number');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        } else {
            // Show error message if the input is empty
            setError_msg("Please enter a WhatsApp Business Number.");
            setTimeout(() => setError_msg(''), 5000); // Clear the error after 5 seconds
        }
    };
    const handleInputChange = (e) => {
        const input = e.target.value;
        setWhatsAppNumber(input); // Store raw input string

        // Convert to array, trim spaces, and filter out empty values
        const numberList = input
            .split(",")
            .map(num => num.trim())
            .filter(num => num !== "");

        setWhatsAppNumberList(numberList); // Store cleaned numbers
    };

    const handleOk = () => {
        if (whatsAppNumberList.length === 0) {
            setError_msg("Please enter at least one WhatsApp number.");
            setTimeout(() => setError_msg(""), 5000);
            return;
        }

        // Validate numbers
        const invalidNumbers = whatsAppNumberList.filter(num => !validateWhatsAppNumber(num));

        if (invalidNumbers.length > 0) {
            setError_msg(`Invalid numbers: ${invalidNumbers.join(", ")}`);
            setTimeout(() => setError_msg(""), 5000);
            return;
        }

        setError_msg("");

        const totalCost = whatsAppNumberList.length * 10; // Deduct 10 credits per number
        if (credit < totalCost) {
            setErrorMessage(`Insufficient credit. You need ${totalCost} credits.`);
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            return;
        }

        // Send each valid number separately
        whatsAppNumberList.forEach(number => handleWhatsApp(number, totalCost / whatsAppNumberList.length));
    };

    const handleWhatsApp = async (number, costPerMessage) => {
        const formattedNumber = number.replace("+", ""); // Ensure number formatting

        if (credit < costPerMessage) {
            setErrorMessage("There is no sufficient credit to perform this operation");
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            return;
        }

        const data = {
            user_phone: formattedNumber,
            aiData_id: aiData.aiData.id,
            question: aiData.question,
        };

        try {
            const response = await fetch(`${domain}/duonut/send_whatsapp_message_template`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            if (response.status === 200) {
                whatsappRef1.current.click();
                const name = aiData.aiData?.websiteData?.company_name || aiData.aiData?.title;

                const creditData = {
                    name: `Shared to WhatsApp - ${name}`,
                    message: `Shared to WhatsApp: ${name} - ${aiData?.question}`,
                    cost: costPerMessage,
                };

                creditDeduction(creditData, (err, res) => {
                    if (err) console.log(err.response);
                });

                setMessage("Credit has been deducted");
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);

                return { success: true, data: responseData };
            } else {
                console.error("Error submitting data:", responseData);
                return { success: false, data: responseData };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, data: error };
        }
    };

    const handleEmailChange = (e) => {
        // setFormData((prevData) => ({
        //     ...prevData,
        //     emailId: e.target.value
        // }));
        const value = e.target.value;

        setFormData((prevData) => ({
            ...prevData,
            emailId: value
        }));

        // if (!validateEmail(value)) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         email: "Please enter a valid email address."
        //     }));
        //     setTimeout(() => setErrors(""), 5000);
        //     return;
        // } else {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         email: "" // Clear error if valid
        //     }));
        // }
    };

    const handleContactChange = (e) => {
        // setFormData((prevData) => ({
        //     ...prevData,
        //     contactNumber: e.target.value
        // }));
        const value = e.target.value;

        setFormData((prevData) => ({
            ...prevData,
            contactNumber: value
        }));

        // // Validate against regex
        // if (!value.startsWith('+')) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         contact: "Please include the country code, e.g., +91 for India."
        //     }));
        // } else if (!validateWhatsAppNumber(value)) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         contact: "The phone number is invalid. Ensure it has a valid length after the country code."
        //     }));
        //     setTimeout(() => setErrors(""), 5000);
        //     return;
        // } else {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         contact: "" // Clear error if valid
        //     }));
        // }
    };

    // Handle social links change
    const handleSocialChange = (index, field, value) => {
        const updatedSocialLinks = [...formData.socialLinks]; // Create a shallow copy
        updatedSocialLinks[index] = {
            ...updatedSocialLinks[index], // Copy existing data
            [field]: value,  // Update the specific field (platform or link)
        };
        setFormData({ ...formData, socialLinks: updatedSocialLinks }); // Set the new state
    };

    // Add a new social link field
    // const addSocialField = () => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         socialLinks: [...prevData.socialLinks, { platform: "", link: "" }]
    //     }));
    // };
    const addSocialField = () => {
        setFormData({
            ...formData,
            socialLinks: [...formData.socialLinks, { platform: "", link: "" }]
        });
    };

    const deleteSocialField = async (index) => {
        const token = localStorage.getItem("access");
        const socialLinkToDelete = formData.socialLinks[index];
        console.log(socialLinkToDelete); //{platform: 'Facebook', link: 'http://localhost:3001/aidata?company=1&selected=2'}
        try {
            const response = await fetch(`${domain}/duonut/delete_social_link`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    id: aiData?.aiData?.id,  // ID of the AI Data
                    social_link: { platform: socialLinkToDelete.platform }  // Only send the platform name
                }),
            });

            if (response.ok) {
                console.log("Social link deleted successfully!");

                // Remove from frontend (the corresponding social link object)
                const updatedSocialLinks = formData.socialLinks.filter((_, i) => i !== index);
                setFormData({ ...formData, socialLinks: updatedSocialLinks });

            } else {
                console.error("Error deleting social link");
            }
        } catch (error) {
            console.error("Error deleting social link:", error);
        }
    };



    // Handle save button click
    const handleSave = async () => {
        
        const newErrors = {}; // Store validation errors

    // Validate Email
    if (!formData.emailId || !validateEmail(formData.emailId)) {
        newErrors.email = 'Please enter a valid email address.';
    }

    // Validate Contact Number
    if (!formData.contactNumber || !validateWhatsAppNumber(formData.contactNumber)) {
        newErrors.contact = 'Please enter a valid contact number.';
    }

    // If there are any errors, update the error state and return
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setTimeout(() => setErrors(""), 5000);
        return;
    }

        const token = localStorage.getItem("access");

        // // Ensure email and phone number are correct
        // if (!validateWhatsAppNumber(formData.contact_no)) {
        //     setError_msg('Invalid Number. Use +CountryCodePhoneNumber (e.g., +14155552671).');
        //     setTimeout(() => setError_msg(''), 5000);
        //     return;
        // }

        // Convert social links array into an object format
        const socialLinksObject = {};
        formData.socialLinks.forEach(social => {
            if (social.platform && social.link) {
                socialLinksObject[social.platform] = social.link;
            }
        });

        const data = {
            id: aiData?.aiData?.id,
            email_id: formData.emailId,  // ensure this value is correct
            contact_no: formData.contactNumber,
            social_id: socialLinksObject,
        };

        try {
            const response = await fetch(`${domain}/duonut/edit_ai_new_data_social`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log("Data updated successfully!");
                // You might want to do something after the successful update, like clearing the form or showing a success message.
            } else {
                const errorData = await response.json();
                console.error("Error updating business details:", errorData);
                setError_msg(errorData.error || "Error updating data");  // Show API error message if available
                setTimeout(() => setError_msg(''), 5000);  // Hide after a while
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError_msg("Error fetching data. Please try again later.");
            setTimeout(() => setError_msg(''), 5000);  // Hide after a while
        }
    };




    return (
        <>
            <div style={aiActive ? { backgroundColor: "rgba(30, 29, 29, 0.42)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%" } : {}}>
                <div ref={modelRef} className={aiActive ? "ASN1collaps-sidebar-open" : "ASN1collaps-sidebar-close"}>
                    <button ref={qrref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#qrModal">
                    </button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="qrModal" tabIndex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ marginTop: "15vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                                <div className="modal-body">
                                    <button ref={qrref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <h2 style={{ margin: "2vh 0vh" }}> QR Code</h2>
                                    <br></br>
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: "200px", width: "100%" }}>
                                        <QRCode
                                            id="qr-gen"
                                            // size={456}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={`${frontend_url}/survey/${aiData?.display_id}`}
                                        // viewBox={`0 0 456 456`}
                                        />
                                    </div>
                                    <br></br>

                                    <button className='QRC1Continuebtn' onClick={() => copylink1()}>{iscopylink1 ? "Copied" : "Copy Link"}</button>
                                    <button className='QRC1Continuebtn' onClick={() => downloadbtn()}>Download</button>
                                    <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                        onClick={() => continueBtn()}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button ref={panelref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#panel"></button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="panel" tabIndex="-1" aria-labelledby="panelLabel" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "100%", maxHeight: "100%" }}>
                            <div className="modal-content" style={{ margin: "auto", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", width: "65vw" }}>
                                <div className="modal-body row">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #ccc", paddingBottom: "10px"
                                    }}>
                                        <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px" }}>Panel</h2>
                                        <p style={{ fontSize: "16px", color: "grey" }}>(Your Credit : {credit})</p>
                                        <button ref={panelref1} style={{ width: "5rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        {/* <h2 style={{ margin: "2vh 0vh", borderBottom: "1px solid grey" }}> Panel</h2> */}

                                    </div>

                                    {/* {panelType === 1 && <ProlificPanel link={`${frontend_url}/survey/${aiData?.display_id}`} />} */}
                                    {panelType === 2 && <RespondentPanel link={`${frontend_url}/survey/${aiData?.display_id}`} />}
                                </div>

                            </div>
                        </div>
                    </div>
                    <button ref={creditref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#creditModal">
                    </button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="creditModal" tabIndex="-1" aria-labelledby="creditModalLabel" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "40vw" }}>
                            <div className="modal-content" style={{ marginTop: "30vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                                <div className="modal-body">
                                    <button ref={creditref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <h2 style={{ margin: "2vh 0vh" }}> Credit Deduct</h2>
                                    <br></br>
                                    <div style={{}}>
                                        {eventNo === 8 && (
                                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src="../../assets/icons/coin.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                                {costConversationLimit} coins will be used to perform this operation.
                                            </label>
                                        )}
                                        {eventNo === 9 && (
                                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src="../../assets/icons/coin.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                                {costUserResponse} coins will be used to perform this operation.
                                            </label>
                                        )}
                                    </div>
                                    <br></br>
                                    <button className='QRC1Continuebtn' onClick={() => creditDeduct()}>Ok</button>
                                    <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                        onClick={() => creditref1.current.click()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button ref={whatsappRef} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#whatsapp"></button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="whatsapp" tabIndex="-1" aria-labelledby="whatsappmodal" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "40vw" }}>
                            <div className="modal-content" style={{ marginTop: "30vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                                <div className="modal-body">
                                    <button ref={whatsappRef1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <h2 style={{ margin: "2vh 0vh 1vh 0vh" }}> Share across WhatsApp</h2>
                                    <p style={{ fontSize: "16px", color: "#a7a5a5", marginBottom: "2vh" }}>Please enter along with the country code.</p>

                                    <br />
                                    {error_msg && <p style={{ color: 'red', fontSize: '14px' }}>{error_msg}</p>}
                                    {/* Step 1: WhatsApp Business Number */}
                                    {step === 1 && (!whatsappBusinessNumber) && (
                                        <div>
                                            <label htmlFor="whatsappBusinessNumber" style={{ display: "block", marginBottom: "5px" }}>Sender - WhatsApp Business Number</label>
                                            <input
                                                id="whatsappBusinessNumber"
                                                type="text"
                                                placeholder="Enter Sender WhatsApp Business Number (e.g., +14155552671)"
                                                style={{ width: "80%", padding: "10px", margin: "10px 0", borderRadius: "5px", border: "1px solid #ccc" }}
                                                onChange={(e) => setInputBusinessNumber(e.target.value)} // Update the local state during typing
                                                value={inputBusinessNumber}
                                            />
                                            <p style={{ fontSize: "14px", color: "#a7a5a5", marginBottom: "2vh" }}>100 credit will be utilized for this operation.</p>
                                            <button
                                                className='QRC1Continuebtn'
                                                onClick={() => handleNext()} // Move to step 2 after entering business number
                                            >
                                                Next
                                            </button>
                                        </div>
                                    )}

                                    {/* Step 2: WhatsApp Number */}
                                    {step === 2 && (
                                        <div>
                                            <label htmlFor="whatsappNumber" style={{ display: "block", marginBottom: "5px" }}>
                                                Receiver - WhatsApp Number(s)
                                            </label>
                                            <input
                                                id="whatsappNumber"
                                                type="text"
                                                placeholder="Enter WhatsApp Numbers (e.g., +919423675689)"
                                                value={whatsAppNumber} // Controlled input
                                                onChange={handleInputChange}
                                                style={{
                                                    width: "80%",
                                                    padding: "10px",
                                                    margin: "10px 0",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ccc"
                                                }}
                                            />
                                            <p style={{ fontSize: "14px", color: "#a7a5a5", marginBottom: "2vh" }}>10 credit per number will be utilized for this operation.</p>

                                            {whatsappBusinessNumber === null && (
                                                <button className="QRC1Continuebtn" onClick={handleBack}>
                                                    Back
                                                </button>
                                            )}

                                            <button className="QRC1Continuebtn" onClick={handleOk}>
                                                Ok
                                            </button>

                                            <button
                                                className="QRC1Continuebtn"
                                                style={{ margin: "2vh", border: "1px solid black" }}
                                                onClick={() => whatsappRef1.current.click()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={aiActive ? "ASN1collaps-sidebar-container-open" : "ASN1collaps-sidebar-container-close"}>

                        <div style={{ zIndex: "2", position: "fixed", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={handleClose}>
                            <img src="/assets/icons/close.svg" />
                        </div>

                        <div style={{ padding: "0px 10px 10px 10px" }}>

                            <div style={{ zIndex: "1", position: "fixed", top: "0", width: "29rem", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
                                <h2 style={{ textAlign: "center", padding: "25px 10px 10px 10px" }}>Create Standalone Links</h2>
                                <div style={{ textAlign: "center" }}>Embed an iFrame or HTML code or share the link standalone.</div>
                                <br></br>
                            </div>
                            <div style={{ marginTop: "8rem" }}>
                                <br></br>

                                <h3 style={{ textAlign: "left", paddingLeft: "8px" }}>Instructions:</h3>
                                <br></br>
                                <div style={{ textAlign: "left", paddingLeft: "8px" }}>There are multiple ways to share your surveys with your customers. A variety of formats are provided by clicking the appropriate link below.</div>
                                <br></br>
                                <div style={{ textAlign: "left", paddingLeft: "8px" }}>You may insert the iFrame or HTML code onto your website or into your email platform of choice. Shareable Links are also provided for SMS or email hyperlinks. QR Codes may be used on signage or at events to collect feedback.</div>

                                <br></br>
                                <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
                                    <div
                                        onClick={() => window.open(`${frontendUrl}/survey/${aiData.display_id}`)}>
                                        <button className='AIDPN1button' style={{ fontSize: "16px", padding: "15px", margin: "1rem 0rem" }}>Preview &nbsp; <i class="fa fa-external-link" aria-hidden="true"></i></button></div>
                                    {/* <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
                                        <div className='ADP1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "10px 13px 15px 13px" }} onClick={() => { setselectedItem(1); setopenPreview(true); }}> <i className="fa fa-desktop" aria-hidden="true" ></i></div>
                                        <div className='ADP1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => { setselectedItem(2); setopenPreview(true) }}><i className="fa fa-mobile" style={{ fontSize: "2rem" }} aria-hidden="true" ></i></div>
                                    </div> */}
                                </div>
                                {/* <div style={{
                                    padding: "10px",
                                    backgroundColor: "transparent",
                                    // border: "1px solid #cfcfcf",
                                    borderRadius: "6px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "5px"
                                }}>
                                    <div style={{
                                        width: "100%",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer"
                                    }} onClick={() => setopenPreview(!openPreview)}>
                                        <div>See Preview</div>
                                        <svg aria-hidden="true" style={{ height: "1rem", transform: openPreview ? "rotate(180deg)" : "rotate(0deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                    </div>
                                    {openPreview && <div style={{ width: "100%" }}>
                                        <hr></hr>
                                        {selectedItem === 1 && <div>
                                            <iframe style={{ minHeight: "65vh", width: '100%' }} id="iframeid" src={`${frontendUrl}/survey/embed/${aiData.display_id}`} title="Duonut Iframe"></iframe>

                                        </div>}
                                        {selectedItem === 2 && <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div className='ADP1mobileScreen'></div>
                                            <div className='ADP1iframeMob' style={{ display: "flex", alignItems: "center", width: "24rem" }}>
                                                <iframe style={{ width: "24rem", height: "63vh" }} id="iframeid" src={`${frontendUrl}/survey/embed/${aiData.display_id}`} title="Duonut Iframe"></iframe>

                                            </div>
                                        </div>}
                                    </div>}
                                </div> */}

                                <br></br>
                                {/* <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>Panel</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Distribute to your customers based on demographic criteria</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => { setiscopylink1(false); .currentpanelref.click(); }}>Panel</button>
                                    </div>
                                </div> */}
                                <ReactTooltip id="settingGuide" place="top" effect="solid" delayHide={900} interactive={true} > </ReactTooltip>



                                <div className="ASN1row_container">
                                    <div className="ASN1row ">
                                        <h3>Share</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Share a link with your customer via text, email or based on demographic criteria</label>
                                        <div className="type-options" style={{ margin: "10px 0px" }}>
                                            {/* <div>
                                                <input
                                                    type="radio"
                                                    id="prolific"
                                                    name="panelType"
                                                    value={1}
                                                    checked={panelType === 1}
                                                    onChange={() => setpanelType(1)}
                                                    style={{ border: '2px solid #007BFF' }}
                                                />
                                                &nbsp;
                                                <label htmlFor="prolific">
                                                    Prolific &nbsp;
                                                    <span style={{ cursor: "pointer", }}
                                                        data-tooltip-html={`Hire real participants from Prolfiic</br> to conduct your research with real participants.<br/> Visit <a href='https://www.prolific.com/'  target='_blank' style={{cursor:"pointer"}}>Prolific</a> for more details.`}
                                                        data-tooltip-content={`Hire real participants from Prolfiic</br> to conduct your research with real participants.<br/> Visit <a href='https://www.prolific.com/'  target='_blank' >Prolific</a> for more details.`}
                                                        data-tooltip-id="settingGuide">
                                                        <i class="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i>
                                                    </span>
                                                </label><br />
                                            </div> */}
                                            <div>
                                                {/* <input
                                                    type="radio"
                                                    id="respondent"
                                                    name="panelType"
                                                    value={2}
                                                    checked={panelType === 2}
                                                    onChange={() => setpanelType(2)}
                                                /> 
                                                &nbsp; */}
                                                <label htmlFor="respondent">
                                                    Respondent &nbsp;
                                                    <span style={{ cursor: "pointer", }}
                                                        data-tooltip-html={`Hire real participants from Respondent.io</br> to conduct your research with real participants.<br/> Visit <a href='https://www.respondent.io/'  target='_blank'  style={{cursor:"pointer"}}>Respondent.io</a> for more details.`}
                                                        data-tooltip-content={`Hire real participants from Respondent.io</br> to conduct your research with real participants.<br/> Visit <a href='https://www.respondent.io/'  target='_blank' >Respondent.io</a> for more details.`}
                                                        data-tooltip-id="settingGuide">
                                                        <i class="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="type-options" style={{ margin: "10px 0px" }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <label htmlFor="whatsapp">
                                                    Whatsapp &nbsp;
                                                </label>
                                                <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="whatsapp" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => copylink()}>{iscopylink ? "Copied" : "Copy Link"}</button>
                                        </div>&nbsp;
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => { setiscopylink1(false); panelref.current.click(); }} style={{ width: "100%" }}>Panel</button>
                                        </div>
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => { whatsappRef.current.click(); }} style={{ width: "100%", marginTop: "25px" }}>WhatsApp</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>iFrame Embed code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Put a clickable survey onto your website</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => copyiframe()}>{iscopyiframe ? "Copied" : "Copy iFrame"}</button>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>HTML Embed code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Put a clickable survey into Klaviyo, Mailchimp, Hubspot, Zendesk and more</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => copyHtml()}>{iscopyhtml ? "Copied" : "Copy HTML"}</button>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>QR Code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Share a QR code with your customers on signs or at events</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => { setiscopylink1(false); qrref.current.click(); }}>Generate QR Code</button>
                                    </div>
                                </div>
                                <div className="ASN1row_container" style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <div className="ASN1row">
                                            <h3>Settings</h3>
                                            <label style={{ textAlign: 'left', fontSize: "14px" }}></label>
                                        </div>
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => setIsOpenSettings(!openSettings)}>{openSettings ? "Close Settings" : "Open Settings"}</button>
                                        </div>
                                    </div>
                                    {openSettings &&
                                        <div style={{
                                            background: '#CFD8DC',
                                            margin: '12px 5px',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            width: '95%'
                                        }}>
                                            <div className="form_field">
                                                <div className="setting-checkbox" style={{ textAlign: "left" }}>
                                                    {/* Conversation Depth Setting */}
                                                    <label>
                                                        Conversation Depth
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Conversation Limit"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill="#fec208"
                                                                    d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"
                                                                ></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "6rem", textAlign: "center", padding: "6px" }}
                                                            disabled={!isEditingConversation || !userProAccess}
                                                            value={conversationLimit}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, Number(e.target.value)); // Ensure the value is not below 0
                                                                setconversationLimit(value);
                                                            }}
                                                        />
                                                        {!isEditingConversation ? (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setIsEditingConversation(true)}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    if (costConversationLimit === 0) {
                                                                        setIsEditingConversation(false);
                                                                        seteventNo(8);
                                                                    } else {
                                                                        setIsEditingConversation(false);
                                                                        seteventNo(8);
                                                                        creditref.current?.click();
                                                                    }
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Number of User Views Setting */}
                                                <div className="setting-checkbox" style={{ textAlign: "left", marginTop: "1rem" }}>
                                                    <label>
                                                        Number of user views
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="User views per duonut"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill="#fec208"
                                                                    d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"
                                                                ></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "6rem", textAlign: "center", padding: "6px" }}
                                                            disabled={!isEditingUserViews || !userProAccess}
                                                            value={totalUserResponse}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, Number(e.target.value));
                                                                settotalUserResponse(value);
                                                            }}
                                                        />
                                                        {!isEditingUserViews ? (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setIsEditingUserViews(true)}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    if (costUserResponse === 0) {
                                                                        setIsEditingUserViews(false);
                                                                        seteventNo(8);
                                                                    } else {
                                                                        setIsEditingUserViews(false);
                                                                        seteventNo(9);
                                                                        creditref.current?.click();
                                                                    }
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className="ASN1row_container" style={{ display: "flex", flexDirection: "column", }}>
                                    <div className="ASN1row" style={{ width: '100%', maxWidth: "100%" }}>
                                        <h3 style={{ marginBottom: "10px" }}>Media Links</h3>
                                        {/* Email Input */}
                                        <label style={{ fontSize: "16px", fontWeight: '600' }}>Email Id:</label>
                                        <input
                                            type="email"
                                            className="contact_input"
                                            placeholder="Enter email id"
                                            value={formData.emailId} // Controlled input
                                            onChange={handleEmailChange}
                                            style={{
                                                width: "80%",
                                                padding: "10px",
                                                margin: "10px 0",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc"
                                            }}
                                        />
                                        {errors.email && (
                                            <p style={{ color: 'red', fontSize: '12px', marginBottom: '10px', textAlign: "left" }}>{errors.email}</p>
                                        )}

                                        {/* Contact Number Input */}
                                        <label style={{ fontSize: "16px", fontWeight: '600' }}>Contact Number:</label>
                                        <input
                                            type="tel"
                                            className="contact_input"
                                            placeholder="Enter contact number"
                                            value={formData.contactNumber} // Controlled input
                                            onChange={handleContactChange}
                                            style={{
                                                width: "80%",
                                                padding: "10px",
                                                margin: "10px 0",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc"
                                            }}
                                        />
                                        {errors.contact && (
                                            <p style={{ color: 'red', fontSize: '12px', marginBottom: '10px', textAlign: "left" }}>{errors.contact}</p>
                                        )}


                                        {/* Dynamic Social Links */}
                                        <label style={{ fontSize: "16px", marginBottom: "5px", fontWeight: '600' }}>Social ID Links:</label>

                                        {formData.socialLinks.map((social, index) => (
                                            <div key={index} className="social-input-group" style={{ display: 'flex', gap: '10px', margin: '2px 0', alignItems: 'center' }}>
                                                <div style={{ textAlign: "left" }}>
                                                    <label style={{ fontSize: "14px" }}>Platform:</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Platform (e.g., Google Review, Facebook)"
                                                        value={social.platform || ""}
                                                        onChange={(e) => handleSocialChange(index, "platform", e.target.value)}
                                                        style={{
                                                            width: "80%",
                                                            padding: "10px",
                                                            margin: "10px 0",
                                                            borderRadius: "5px",
                                                            border: "1px solid #ccc"
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ textAlign: "left" }}>
                                                    <label style={{ fontSize: "14px" }}>Link:</label>
                                                    <input
                                                        type="url"
                                                        placeholder="Enter link"
                                                        value={social.link || ""}
                                                        onChange={(e) => handleSocialChange(index, "link", e.target.value)}
                                                        style={{
                                                            width: "80%",
                                                            padding: "10px",
                                                            margin: "10px 0",
                                                            borderRadius: "5px",
                                                            border: "1px solid #ccc"
                                                        }}
                                                    />
                                                </div>
                                                <span className="AIDPN1icon">
                                                    <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteSocialField(index)}></i>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <button type="button" className="AIDPN1button" onClick={addSocialField}>
                                            + Add Social Link
                                        </button>
                                        <button className="AIDPN1button" onClick={handleSave}>Save</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div >
            </div >
            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#4CAF50",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {message}
                    </motion.div>
                )}
                {showErrorMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#DC143C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {error_message}
                    </motion.div>
                )}
            </AnimatePresence>
            <ReactTooltip
                id="analyticspage2"
                place="bottom"
                effect="solid"
                className="custom-tooltip"
            >
                <p style={{ display: "flex", alignItems: "center" }}>
                    <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem" }} alt="coin icon" />
                    &nbsp; 2 credit
                </p>
            </ReactTooltip>
            <ReactTooltip
                id="whatsapp"
                place="bottom"
                effect="solid"
                className="custom-tooltip"
            >
                <p style={{ display: "flex", alignItems: "center" }}>
                    <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem" }} alt="coin icon" />
                    &nbsp; 100 credit + 10 credit per number
                </p>
            </ReactTooltip>
        </>
    );
}
