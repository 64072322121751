import React, { useState, useEffect, useRef } from 'react';
import './Element.css';
import { EditText, } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements,    updateElementInputFocus, setTextFormData } from '../../redux/slices/userActivity';
import { useLocation} from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, saveFormData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const Phone = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius,
    minLength, maxLength, required, fieldPosition, placeholder, borderBottom, gap, height,
  },
  conditions,
  language,
  slideId,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity,  selectedComponent, selectedSlide, } = useSelector((state) => state.userActivity);
  const location = useLocation();
  const [phoneField, setPhoneField] = useState('');
  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name,  viewer_url_params } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const [inputText, setInputText] = useState("");
  const [placeholderText, setplaceholderText] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
      fetchText(text);
      fetchPlaceholderText(placeholder);
    } else {
      setInputText(text);
      setplaceholderText(placeholder);
    }

    var formData = {};
    formData[text] = id;
    // console.log(formData);
    dispatch(setTextFormData(formData));
  }, [text,placeholder]);

  const fetchText = async (texts) => {
    const t_text = await translateText(texts, language);
    setInputText(t_text);
  };
  const fetchPlaceholderText = async (texts) => {
    const t_text = await translateText(texts, language);
    setplaceholderText(t_text);
  };
  const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

  const handlePhoneFieldTextChange = (e) => {
    // console.log(e.target.value);
    setInputText(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const phoneTimeout = useRef(null);

  const handlePhoneFieldInputChange = (e) => {
    if (location.pathname.includes("/duonut/")) {
      setPhoneField(e.target.value);

      clearTimeout(phoneTimeout.current);
      phoneTimeout.current = setTimeout(() => {

        var formData1 = {};
        formData1[text] = e.target.value;
        // console.log(formData);
        dispatch(saveFormData(formData1));

        var save_ans = {
          display_id: viewer_display_id,
          watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
          result: e.target.value ? 1 : 0,
          question: text,
          answered_text: e.target.value,
          // end: lastQue ? "1" : "0",
          nickname: viewer_nick_name,
          step: selectedSlide + 1,
          type: "Phone",
          // country: location[0] ? location[0].address?.country : "",
          // city: location[0] ? location[0].address?.state_district : "",
          // location: location[0] ? location[0].display_name : "",
          url_params: viewer_url_params,
        }
        // console.log(save_ans);
        const formData = new FormData();
        Object.keys(save_ans).forEach(key => {
          const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
          formData.append(key, value);
        });

        dispatch(saveQuizAnsAsync(formData));

        var save_response = {
          id: id,
          type: "Phone",
          step: selectedSlide + 1,
          question: text,
          answered_text: e.target.value,
        }
        dispatch(saveQuizResponse(save_response));

      }, 1000);

    }
  }

  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  return (
    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: fieldPosition ? fieldPosition : "column", gap: gap ? gap : fieldPosition === 'row' ? '20px' : '0' }}>
      <EditText
        id={`phone-input-${id}`}
        value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          // display: "flex",
          // alignItems: "center",
          width: fieldPosition === 'row' ? "max-content" : "100%",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => {
          handleInputFocus();
          // setTimeout(() => document.querySelector(`#phone-input-${id}`).select(), 100); 
        }}
        onChange={(e) => handlePhoneFieldTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
      <input type='tel' key={borderBottom} style={{
        width: "100%", minHeight: "40px", height: height,
        color, fontFamily, fontSize: "16px", fontStyle, textDecoration, textTransform,
        textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
        backgroundColor, border: borderBottom ? "0" : border, margin, borderRadius, borderBottom: border
      }}
        onChange={(e) => handlePhoneFieldInputChange(e)}
        readOnly={location.pathname.includes("/duonut/") ? false : true}
        required={required}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholderText}
      />
    </div>
  );
}

export default Phone;
