import React, { useEffect, useState, useMemo } from "react";
import './DuonutReports.css';
import { showduonutreport, getduonutreportAsync } from '../../redux/slices/DuonutReportReducer';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx/xlsx.mjs';
import { backendUrl } from "../../services/constants";
import { editReportApiAsync, deleteReportApiAsync, } from "../../redux/slices/ReportApiReducer";
import Chart from 'react-apexcharts';
import LineChart0 from "./LineChart0";
import LineChart1 from "./LineChart1";
import LineChart2 from "./LineChart2";
import LineChart3 from "./LineChart3";
import LineChart4 from "./LineChart4";
import { toast } from "react-toastify";
import axios from "axios";
import { getQuestionAnswerSummaryAsync, getUserQuestionAnswerSummaryAsync } from "../../redux/slices/AiDuonutNewReducer";
import DuonutAnalytics from "../DuonutAnalytics/DuonutAnalytics";
import { PieChart } from 'react-minimal-pie-chart';
import { domain } from "../../services/constants";
import { creditDeduction } from '../../services/duonut';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getUserDetails } from "../../services/auth";
import { updateCredit, updateTotalCredit } from "../../redux/slices/user";
import { AnimatePresence, motion } from 'framer-motion';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, CartesianGrid, ReferenceLine, LabelList, Label, Line, LineChart } from 'recharts';
import AttributeChart from "./BWChart";

const DuonutReports = () => {
    const { userActivities } = useSelector((state) => state.userActivity);
    const { credit } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const paramsid = useParams();
    const duonut_id = parseInt(paramsid.id);
    const navigate = useNavigate();
    const { google_sheet_integration, title } = useSelector(state => state.duonutSettings);

    useEffect(() => {
        dispatch(getduonutreportAsync(duonut_id));
        // console.log("inside", duonut_id);
    }, [duonut_id]);

    const duonutreportData = useSelector(showduonutreport);
    const reportData = duonutreportData[0];
    // console.log(reportData);
    const [elementType, setElementType] = useState('');
    const [tableData, settableData] = useState([]);
    const [duonutName, setduonutName] = useState("");
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [stepNum, setstepNum] = useState(0);
    const [filterNum, setfilterNum] = useState(-1);
    const [filteredItems, setfilteredItems] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [newSelectedAnswer, setNewSelectedAnswer] = useState('');
    const [transformedData, setTransformedData] = useState([]);
    const [columns1, setColumns1] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [selectedDate, setselectedDate] = useState("CreatedAt");
    const [selectedColumn, setselectedColumn] = useState("");
    const [selectedColumn1, setselectedColumn1] = useState("Actual Production");
    const [selectedFilterColumn, setselectedFilterColumn] = useState("");
    const [selectedStep, setselectedStep] = useState("");
    const [dashboardSummary, setdashboardSummary] = useState("");
    const [dashboardAllSummary, setdashboardAllSummary] = useState("");
    const [selectedTab, setselectedTab] = useState(1);
    const [signal, setsignal] = useState("");
    const [allsignal, setallsignal] = useState("");
    const [userGroupData, setuserGroupData] = useState([]);
    const [showConversation, setshowConversation] = useState(-1);
    const [stepData, setstepData] = useState([]);
    const [analysis, setanalysis] = useState([]);
    const [isEmpty, setisEmpty] = useState("");
    const [errMess, seterrMess] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [userSummary, setUserSummary] = useState("");
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [participantNames, setParticipantNames] = useState({});
    const [signalWord, setsignalWord] = useState([]);
    const [duonutStepAnalytics, setduonutStepAnalytics] = useState([]);
    const [duonutUserAnalytics, setduonutUserAnalytics] = useState([]);
    const [generateStepButton, setGenerateStepButton] = useState(-1);
    const [getStepSummary, setStepSummary] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error_message, setErrorMessage] = useState("");
    const [rankedData, setRankedData] = useState([]);
    const [conjointColumns, setConjointColumns] = useState([]);
    const [conjointChartData, setConjointChartDatas] = useState([]);
    const [conjointOptionsData, setconjointOptionsData] = useState([]);
    const [BWchartData, setBWChartData] = useState({});
    const [genericRI, setGenericRI] = useState([]);
    const [partWorths, setPartWorths] = useState([]);
    const [priceAnalysis, setpriceAnalysis] = useState([]);
    const [countBW, setCountBW] = useState([]);
    const [preferenceScoresBW, setPreferenceScoresBW] = useState({});
    const [bestPercentages, setBestPercentages] = useState({});
    const [worstPercentages, setWorstPercentages] = useState({});
    const [netChoice, setNetChoice] = useState({});
    const [graphData, setGraphData] = useState([]);
    // const [domainMin, setdomainMin] = useState(-30);
    // const [domainMax, setdomainMax] = useState(30);
    const [countAnalysis, setcountAnalysis] = useState([]);
    // console.log("User Activity - ", userActivities);
    // const filteredItems = tableData.filter(
    //     item => item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase()) &&
    //         item.step && item.step.includes(filterNum),
    // );

    // const filteredItems = tableData.filter(item => {
    //     const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
    //     const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
    //     return nicknameMatch && stepMatch;
    // });

    useEffect(() => {
        if (tableData.length > 0) {
            // console.log("tableData", tableData)
            var main_filter = tableData.filter(item => {
                const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
                const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
                return nicknameMatch && stepMatch;
            });
            setfilteredItems(main_filter);
        }
    }, [tableData, filterNum, filterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText || filterNum) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setfilterNum(-1);
            }
        };

        return (
            <div key={filteredItems}>
                <select value={filterNum} className='text_editor_dropdown'
                    style={{ marginRight: "1rem" }}
                    onChange={e => setfilterNum(e.target.value)} >
                    <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled>
                        Filter By Step</option>
                    {Array.from({ length: stepNum }, (_, index) => index).map((item, idx) => {
                        return <option key={idx + 1} value={idx + 1} > {`Step ${idx + 1}`} </option>
                    }
                    )}
                </select>

                <input
                    type="text"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    placeholder="Filter By User"
                    style={{ border: "1px solid black", padding: "6px 10px" }}
                    list="browsers"
                />
                <datalist id="browsers">
                    {filteredItems?.filter((item, index, array) =>
                        array.findIndex((i) => i.nickname === item.nickname) === index)
                        .map((item, i) => {
                            return <option value={item.nickname}></option>
                        })}
                </datalist>
                <button type="button" onClick={handleClear} style={{ padding: "9px 20px", cursor: "pointer" }}>
                    X
                </button>
            </div>
        );
    }, [filterText, resetPaginationToggle, filteredItems]);

    // Function to split and create new entries
    function splitSelectedAnswer(dict) {
        const newDict = {};
        Object.keys(dict).forEach(key => {
            const entry = dict[key];
            const answers = entry.selected_option.split(';').map(option => option.trim());
            answers.forEach((answer, index) => {
                const newKey = `${key}-${index}`;
                newDict[newKey] = {
                    ...entry,
                    selected_option: answer
                };
            });
        });
        return newDict;
    }

    useEffect(() => {
        if (duonutreportData[0]) {
            if (parseInt(localStorage.getItem('userId')) !== parseInt(reportData.created_by)) {
                navigate('/')
                return
            }
            setduonutName(reportData.duonut_name);

            // console.log(reportData.duonut_report_dict);
            // Call the function and get the updated dictionary
            const updatedDict = splitSelectedAnswer(reportData.duonut_report_dict);

            // Output the updated dictionary
            // console.log(updatedDict);

            var tablerows = [];
            // Object.entries(reportData.duonut_report_dict).reverse().map(([key, value]) => {
            //     tablerows.push({ ...value });
            // })
            Object.entries(reportData.duonut_report_dict).map(([key, value]) => {
                tablerows.push({ ...value });
            })

            if (tablerows.length > 0) {
                var largestId = tablerows?.reduce((max, item) => parseInt(item.step) > max ? parseInt(item.step) : max, tablerows[0].step);
                setstepNum(largestId);
                // console.log("The largest id is: " + largestId);
            }

            // console.log("tablerows", tablerows);
            settableData(tablerows);
        }
    }, [duonutreportData])

    useEffect(() => {
        if (tableData.length > 0) {
            // Function to safely parse `url_params` and handle empty strings
            function safeParseUrlParams(urlParams) {
                try {
                    return urlParams ? JSON.parse(urlParams) : {}; // Return empty object if urlParams is empty
                } catch (e) {
                    // console.warn("Invalid url_params:", urlParams);
                    return {}; // Return empty object if parsing fails
                }
            }

            const groupedData = tableData?.reduce((acc, item) => {
                // Find if there's already an entry for this watch_id and nickname
                let existingGroup = acc.find(
                    (group) => group.watch_id === item.watch_id && group.nickname === item.nickname
                );

                if (!existingGroup) {
                    // If no group exists, create a new one
                    existingGroup = {
                        watch_id: item.watch_id,
                        nickname: item.nickname,
                        created_at: item.created_at,
                        url_params: safeParseUrlParams(item.url_params),
                        records: [],
                    };
                    acc.push(existingGroup);
                }

                // Add the current item to the group's records
                existingGroup.records.push(item);
                return acc;
            }, []);

            // console.log("groupedData", groupedData);
            setuserGroupData(groupedData);
        }
    }, [tableData])

    useEffect(() => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/get_duonut_step_analytics`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                duonut_id: duonut_id,
            },
        }).then(response => {
            if (response.status === 200) {
                // console.log("get_duonut_step_analytics", response)
                setduonutStepAnalytics(response?.data);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

        axios({
            method: "POST",
            url: `${domain}/duonut/get_duonut_user_analytics`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                duonut_id: duonut_id,
            },
        }).then(response => {
            if (response.status === 200) {
                // console.log("get_duonut_user_analytics", response)
                setduonutUserAnalytics(response?.data);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

    }, [getStepSummary]);

    const columns = [
        {
            name: "Date",
            selector: (row) => row.created_at,
            center: true,
            sortable: true,
        },
        // {
        //     name: "id",
        //     selector: (row) => row.id,
        //     center: true,
        // },
        {
            name: "User",
            selector: (row) => row.nickname,
            center: true,
        },
        // {
        //     name: "Query Params",
        //     selector: (row) => row.url_params,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Question",
            selector: (row) => row.question,
            center: true,
            wrap: true,
        },
        {
            name: "Selected Answer",
            selector: (row) => row.id === editingRowId ? (
                <input
                    type="text"
                    style={{ border: "1px solid black", padding: "8px 5px", borderRadius: "5px", width: "7rem" }}
                    value={newSelectedAnswer}
                    onChange={handleChange}
                />
            )
                : row.type === "FileUpload" ?
                    (
                        <a href={`${backendUrl}${row.file}`} target="_blank" rel="noopener noreferrer">
                            {`${backendUrl}${row.file}`}
                        </a>
                    ) : row.selected_option,
            center: true,
            wrap: true,
        },
        {
            name: "Result",
            selector: (row) => (row.type === "Mcq") ? row.result ? "Correct" : "Wrong" : "-",
            center: true,
        },
        {
            name: "Step",
            selector: (row) => row.step,
            center: true,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            center: true,
        },
        // {
        //     name: "Location",
        //     selector: (row) => row.location,
        //     center: true,
        //     wrap: true,
        // },
        // {
        //     name: "Status",
        //     selector: (row) => row.status,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    {(row.type === "TextField" || row.type === "TextArea" || row.type === "Email" || row.type === "Password" || row.type === "Phone" || row.type === "NumberField" || row.type === "DateField") ?
                        row.id === editingRowId ? (
                            <button className="report_btn-edit" onClick={() => handleSave(row.id)}>Save</button>
                        ) : (
                            <button className="report_btn-edit" onClick={() => handleEdit(row)}>Edit</button>
                        )
                        : <div style={{ width: "4rem", display: "inline-block" }}></div>
                    }
                    <button className="report_btn-delete" onClick={() => handleDelete(row.id)}>Delete</button>
                </div>
            ),
            center: true,
        },
    ]

    const handleSave = (id) => {
        // Handle the save action here (e.g., updating the state or making an API call)
        // console.log(`Saving row with id: ${id}, new value: ${newSelectedAnswer}`);

        var reportData = {
            id: id,
            selected_option: newSelectedAnswer,
        }
        dispatch(editReportApiAsync(reportData));

        var main_filter = filteredItems.map(item => {
            if (item.id === id) {
                return { ...item, selected_option: newSelectedAnswer };
            }
            return item;
        });
        setfilteredItems(main_filter);

        setEditingRowId(null);
    };

    const handleChange = (e) => {
        setNewSelectedAnswer(e.target.value);
    };

    const handleEdit = (row) => {
        setEditingRowId(row.id);
        setNewSelectedAnswer(row.selected_option || '');
    };

    const handleDelete = (id) => {
        // console.log(`Deleting row with id: ${id}`);
        var reportData1 = {
            id: id
        }
        // console.log(reportData1)
        dispatch(deleteReportApiAsync(reportData1));
        // console.log(filteredItems)

        var main_filter = filteredItems.filter(item => item.id !== id);
        setfilteredItems(main_filter);
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                // console.log("qqq", item[key]);
                var itemData = item[key] ? item[key].toString() : "";
                var itemData = itemData.replace(/,|\n/g, '');;
                if (ctr > 0) result += columnDelimiter;

                result += itemData;
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    function downloadCSV(Data) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(Data);
        if (csv == null) return;

        // Add heading to the CSV before the data
        const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        csv = `${heading}\n${csv}`;

        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.csv` : `${duonutName} reports.csv`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
        if (credit >= 2) {
            var creditData = {
                name: `${title} - CSV`,
                message: `${title} - CSV`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
                getUserDetails((err, userDetails) => {
                    if (err) return;
                    dispatch(updateTotalCredit(userDetails.data.total_credit));
                    dispatch(updateCredit(userDetails.data.credit));

                });

            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);

        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    }

    function downloadXLSX(Data) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(Data);

        // Add heading to the worksheet
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        // XLSX.utils.sheet_add_aoa(ws, [[heading]], { origin: 'A1' });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob and create a download link
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (credit >= 2) {
            var creditData = {
                name: `${title} - XLSX`,
                message: `${title} - XLSX`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
                getUserDetails((err, userDetails) => {
                    if (err) return;
                    dispatch(updateTotalCredit(userDetails.data.total_credit));
                    dispatch(updateCredit(userDetails.data.credit));

                });
            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);

        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    }
    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    function downloadWordDoc(Data) {
        const heading = "";
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;

        // Create a table in HTML format
        const table = `<h2 style="text-align: center;">Activity Name - ${duonutName}</h2>
                <h3 style="text-align: center;">User - ${Data[0].nickname}</h3>
                    <table style="border-collapse: collapse; width: 100%;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #000; padding: 8px;">User</th>
                                <th style="border: 1px solid #000; padding: 8px;">Question</th>
                                <th style="border: 1px solid #000; padding: 8px;">Result</th>
                                <th style="border: 1px solid #000; padding: 8px;">Selected Answer</th>
                                <th style="border: 1px solid #000; padding: 8px;">Step</th>
                                <th style="border: 1px solid #000; padding: 8px;">Type</th>
                                <th style="border: 1px solid #000; padding: 8px;">Query Params</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${Data.map(item => `<tr>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.nickname}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.question}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.result}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.selected_option}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.step}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.type}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.url_params}</td>
                                            </tr>`).join('')}
                        </tbody>
                      </table>`;

        // Create a Blob containing the table HTML
        const blob = new Blob([heading, table], { type: 'application/msword' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterText ? `${duonutName} - ${Data[0].nickname} reports.doc` : `${duonutName} reports.doc`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        if (credit >= 2) {
            var creditData = {
                name: `${title} - Word Document`,
                message: `${title} - Word Document`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
                getUserDetails((err, userDetails) => {
                    if (err) return;
                    dispatch(updateTotalCredit(userDetails.data.total_credit));
                    dispatch(updateCredit(userDetails.data.credit));

                });
            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);

        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    }



    useEffect(() => {
        if (tableData.length > 0) {
            var transformedData = tableData.reduce((acc, obj) => {
                // Find if an object with the same watch_id and nickname exists in acc array
                let existingObj = acc.find(item => item.WatchId === obj.watch_id && item.NickName === obj.nickname);

                if (!existingObj) {
                    // If no existing object, create a new one with watch_id and nickname
                    existingObj = {
                        WatchId: obj.watch_id,
                        CreatedAt: obj.created_at,
                        NickName: obj.nickname,
                    };
                    acc.push(existingObj);
                }

                // Assign each question and selected_option as properties to existingObj
                existingObj[obj.question] = obj.selected_option;

                return acc;
            }, []);

            // console.log(transformedData);
            setTransformedData(transformedData);
        }
    }, [tableData]);

    useEffect(() => {
        if (transformedData.length > 0) {
            // Collect all unique keys across all objects in transformedData
            const allKeys = Array.from(
                new Set(transformedData.flatMap(Object.keys))
            );

            // Generate columns based on these unique keys
            const transformedData1 = allKeys.map(key => ({
                name: key,
                selector: (row) => row[key],
                sortable: true,
                wrap: true,
                center: true,
                omit: false,
            }));

            // console.log(transformedData1);

            setColumns1(transformedData1);
        }
    }, [transformedData]);


    const handleToggleColumn = (columnName) => {
        setColumns1(columns1.map(col => col.name === columnName ? { ...col, omit: !col.omit } : col));
    };


    useEffect(() => {
        if (transformedData.length > 0 && selectedColumn) {
            if (selectedFilterColumn) {
                // Group data by the selected filter column (e.g., Shift)
                const groupedData = transformedData.reduce((acc, item) => {
                    const key = item[selectedFilterColumn];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    });
                    return acc;
                }, {});

                // Create chart series for each group
                const seriesData = Object.keys(groupedData).map(key => ({
                    name: `${selectedFilterColumn}: ${key}`,
                    data: groupedData[key].sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }));

                setLineChartData(seriesData);
            } else {
                // Single series if no filter column is selected
                const singleSeriesData = [{
                    name: selectedColumn,
                    data: transformedData.map(item => ({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    })).sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }];

                // console.log(singleSeriesData)
                setLineChartData(singleSeriesData);
            }
        }
    }, [transformedData, selectedColumn, selectedFilterColumn, selectedDate]);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const createSheet = async (AccessToken, Data) => {
        // console.log("Data", Data)
        try {
            const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${AccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    properties: {
                        title: filename,
                    },
                }),
            });

            if (response.ok) {
                const sheetData = await response.json();
                const spreadsheetId = sheetData.spreadsheetId;
                // console.log('Spreadsheet created:', sheetData);
                // Step 2: Generate a comprehensive set of headers
                const headers = Data.reduce((acc, item) => {
                    Object.keys(item).forEach(key => {
                        if (!acc.includes(key)) {
                            acc.push(key);
                        }
                    });
                    return acc;
                }, []);

                // Step 3: Create rows of data that match the headers
                const values = [
                    headers, // Header row
                    ...Data.map(item => headers.map(header => item[header] || '')) // Data rows
                ];

                const range = `Sheet1!A1`; // Start from the first cell
                const updateResponse = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${AccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        range,
                        majorDimension: 'ROWS',
                        values: values,
                    }),
                });

                if (updateResponse.ok) {
                    // console.log('Spreadsheet updated with data');
                    toast.success("Export Successfully in Google Sheet.");

                } else {
                    console.error('Error updating spreadsheet with data:', updateResponse.statusText);
                }
            } else {
                console.error('Error creating spreadsheet:', response.statusText);
                if (response && response.status === 401) { // Access token expired
                    try {
                        const newAccessToken = await refreshAccessToken(google_sheet_integration?.refresh_key);
                        await createSheet(newAccessToken, Data);
                    } catch (refreshError) {
                        console.error('Error refreshing access token', refreshError);
                    }
                } else {
                    console.error('Error sending email', response);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    const handelGenerateStepSummary = (step) => {
        if (credit >= 2) {
            //  Extract and concatenate AI conversation messages
            const questionAnswers = tableData
                .filter(item => String(item.step) === String(step) || item.step.startsWith(`${step}-ai`))  // Filter only step 1 and step 1-ai1 items
                .map(item => ({
                    question: item.question,
                    answer: item.selected_option
                }));
            if (questionAnswers.length === 0) {
                setisEmpty("No summary generated as this step doesn’t have any open ended question.");
                return null;
            } else {
                setisEmpty("");
            }

            const stepQuestionAnswers = tableData
                .filter(item => String(item.step) === String(step))  // Filter only step 2 items
                .map(item => ({
                    question: item.question,
                    answer: item.selected_option
                }));
            // console.log("step -Q&A -", stepQuestionAnswers);
            const stepData = tableData
                .filter(item => String(item.step) === String(step) || item.step.startsWith(`${step}-ai`))
                .map(item => ({
                    question: item.question,
                    step: item.step,
                    selected_option: item.selected_option
                }));
            setstepData(stepData);

            // console.log(questionAnswers, stepData);

            const filteredType = tableData
                .filter(item => String(item.step) === String(step))  // Filter items where step matches
                .map(item => item.type)[0];  // Extract only the 'type' property
            setElementType(filteredType);
            // console.log("filteredType", filteredType);

            if (filteredType === "GenericConjoint") {
                const options = userActivities[step - 1]?.elements?.find(element => element?.elementType === "GenericConjoint")?.elementAttributes.options;
                setconjointOptionsData(options);
                // Step 1: Extract and count combinations
                const combinationCounts = {};
                stepData.forEach((item) => {
                    const combination = item.selected_option;
                    if (combination === "None of the above") return null;
                    if (combination) {
                        combinationCounts[combination] = (combinationCounts[combination] || 0) + 1;
                    }
                });
                // console.log(stepData);
                // Step 2: Sort by count and assign rank
                const sortedCombinations = Object.entries(combinationCounts)
                    .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
                    .map(([combination, count], index) => {
                        const attributeValues = combination.split(", ").map((value, i) => ({
                            [options[i]?.attribute || `Attribute ${i + 1}`]: value,
                        }));
                        return {
                            rank: index + 1,
                            count,
                            ...Object.assign({}, ...attributeValues),
                        };
                    });

                setRankedData(sortedCombinations);
                // console.log(options);
                // Step 3: Dynamically create columns
                const dynamicColumns = options.map((option) => ({
                    name: option.attribute,
                    selector: (row) => row[option.attribute],
                    sortable: true,
                }));

                // Add static columns for Count and Rank
                dynamicColumns.push(
                    {
                        name: "Count",
                        selector: (row) => row.count,
                        sortable: true,
                    },
                    {
                        name: "Rank",
                        selector: (row) => row.rank,
                        sortable: true,
                    }
                );

                setConjointColumns(dynamicColumns);

                const chartData = processData(stepData, options);

                setConjointChartDatas(chartData);

                // console.log("options", options, combinationCounts, sortedCombinations, chartData);

                //Attribute Importance
                const parseData = (data) => {
                    // console.log(data, options);
                    const attributes = {};  // Dynamic attributes container

                    // Initialize attributes based on options (dynamic)
                    options.forEach(option => {
                        attributes[option.attribute] = [];
                    });

                    // Parse the data
                    data.forEach((item, index) => {
                        // console.log(`Processing item at index ${index}:`, item);

                        // Ensure `selected_option` exists and is a string
                        if (item.selected_option && typeof item.selected_option === "string") {
                            // console.log(`Valid selected_option at index ${index}:`, item.selected_option);

                            // Split selected_option into parts
                            const parts = item.selected_option.split(", ");
                            if (parts.length === options.length) {
                                options.forEach((option, idx) => {
                                    const value = parts[idx];

                                    // Handle each attribute
                                    if (option.attribute === "Amount" || option.attribute === "Price") {
                                        // For numeric attributes, parse as number
                                        const numericValue = option.attribute === "Amount"
                                            ? parseFloat(value.replace("g", "")) || 0
                                            : parseInt(value.replace("$", ""), 10) || 0;
                                        attributes[option.attribute].push(numericValue);
                                    } else {
                                        // For categorical attributes (anything else), just store the value
                                        attributes[option.attribute].push(value || "Unknown");
                                    }
                                });
                            } else {
                                console.warn(`Unexpected format for selected_option at index ${index}:`, item.selected_option);
                            }
                        } else {
                            console.warn(`Missing or invalid selected_option at index ${index}:`, item);
                        }
                    });

                    // Calculate ranges for each attribute
                    const ranges = {};
                    let totalRange = 0;

                    options.forEach(option => {
                        const attributeData = attributes[option.attribute];

                        if (attributeData.length > 0) {
                            if (typeof attributeData[0] === "number") {
                                // For numeric attributes like Amount and Price
                                const range = Math.max(...attributeData) - Math.min(...attributeData);
                                ranges[option.attribute] = range;
                                totalRange += range;
                            } else {
                                // For categorical attributes (like Product or Bulk Order)
                                const uniqueValues = new Set(attributeData);
                                const range = uniqueValues.size - 1;  // Categories - 1
                                ranges[option.attribute] = range;
                                totalRange += range;
                            }
                        } else {
                            ranges[option.attribute] = 0;
                        }
                    });

                    // console.log("Calculated ranges:", ranges);

                    // If total range is 0 or too small, handle edge case
                    if (totalRange === 0) {
                        console.error("Total range is zero. Cannot calculate relative importance.");
                        return [];
                    }

                    // Calculate relative importance for each attribute
                    const importanceData = options.map(option => {
                        const importance = ((ranges[option.attribute] / totalRange) * 100).toFixed(1);
                        return {
                            attribute: option.attribute,
                            importance: importance
                        };
                    });

                    // console.log("Calculated importance data:", importanceData); // Log the final result
                    return importanceData;
                };

                const importanceData = parseData(stepData);
                setGenericRI(importanceData);
                //Average preference for levels(Regression)
                const parseDataRegression = (data) => {
                    const attributeCounts = {};
                    const totalCount = data.length;

                    data.forEach((item) => {
                        const levels = item.selected_option.split(", ");
                        levels.forEach((level, index) => {
                            const attribute = options[index].attribute;
                            if (!attributeCounts[attribute]) attributeCounts[attribute] = {};

                            if (!attributeCounts[attribute][level]) attributeCounts[attribute][level] = 0;
                            attributeCounts[attribute][level] += 1;
                        });
                    });

                    // Calculate preferences and baseline
                    const chartData = [];
                    Object.keys(attributeCounts).forEach((attribute) => {
                        const levels = Object.keys(attributeCounts[attribute]);
                        const totalLevels = levels.map((level) => attributeCounts[attribute][level]);
                        const baseline = totalLevels.reduce((sum, count) => sum + count, 0) / levels.length; // Average as baseline

                        levels.forEach((level) => {
                            if (level !== "None of the above") { // Exclude "None of the above"
                                const preference = attributeCounts[attribute][level];
                                chartData.push({
                                    attribute,
                                    level,
                                    percentage: (((preference - baseline) / totalCount) * 100).toFixed(1), // Adjusted percentage
                                });
                            }
                        });
                    });

                    // console.log(chartData);
                    return chartData;
                };
                const partWorthsData = parseDataRegression(stepData);
                setPartWorths(partWorthsData);
                // const percentages = partWorths.map((item) => parseFloat(item.percentage));
                // const minValue = Math.min(...percentages);
                // const maxValue = Math.max(...percentages);

                // // Add some padding to the range for better visualization
                // const padding = 10; // Adjust this value if needed
                // setdomainMin(Math.min(Math.floor(minValue / 10) * 10 - padding, 0)); // Ensure 0 is included
                // setdomainMax(Math.max(Math.ceil(maxValue / 10) * 10 + padding, 0)); // Ensure 0 is included

                // // Group data by attribute for stacked bars
                // const groupedData = options.map((option) => ({
                //     attribute: option.attribute,
                //     levels: partWorths.filter((item) => item.attribute === option.attribute),
                // }));
                // console.log(groupedData);

                const countLevels = (data) => {
                    let counts = {};
                    data.forEach(item => {
                        const selectedOption = item.selected_option;
                        const parts = selectedOption.split(', ');

                        // Loop through each option to check if the selected_option matches any level
                        options.forEach(option => {
                            // Go through the levels of the current attribute and match them with parts of selected_option
                            option.levels.forEach(level => {
                                parts.forEach((part, index) => {
                                    if (part === level) {
                                        // Check if the counts object for this attribute exists, if not, initialize it
                                        if (!counts[option.attribute]) {
                                            counts[option.attribute] = {};
                                        }

                                        // If the level exists for this attribute, initialize an object to store the count and value
                                        if (!counts[option.attribute][level]) {
                                            counts[option.attribute][level] = {
                                                count: 0,
                                                value: level
                                            };
                                        }

                                        // Increment the level count for this attribute
                                        counts[option.attribute][level].count++;
                                    }
                                });
                            });
                        });
                    });

                    // Function to calculate the percentages based on total number of entries in sample data
                    const calculatePercentages = (counts, totalEntries) => {
                        Object.keys(counts).forEach(attribute => {
                            Object.keys(counts[attribute]).forEach(level => {
                                const count = counts[attribute][level].count;
                                counts[attribute][level].percentage = ((count / totalEntries) * 100).toFixed(1) + '%';
                            });
                        });
                    };

                    // Calculate the total number of entries (sample data length)
                    const totalEntries = data.length;

                    // Calculate percentages
                    calculatePercentages(counts, totalEntries);

                    // Format output in the required format
                    let formattedOutput = {};

                    Object.keys(counts).forEach(attribute => {
                        formattedOutput[attribute] = {};

                        Object.keys(counts[attribute]).forEach(level => {
                            formattedOutput[attribute][level] = counts[attribute][level].percentage;
                        });
                    });

                    // console.log(formattedOutput);
                    return formattedOutput;
                };
                const countAnalysis_copy = countLevels(stepData);
                // console.log(countAnalysis_copy);
                setcountAnalysis(countAnalysis_copy);

            } else {
                setRankedData([]);
                setConjointColumns([]);
                setConjointChartDatas([]);
                setGenericRI([]);
                setPartWorths([]);
                setcountAnalysis([]);

            }

            if (filteredType === "BestWorstConjoint") {
                const options = userActivities[step - 1]?.elements?.find(element => element?.elementType === "BestWorstConjoint")?.elementAttributes.options;
                setconjointOptionsData(options);

                const data = parseStepData(stepData, options);

                const dynamicColumns = [
                    {
                        name: "Combination",
                        selector: row => row.combination,
                        sortable: true,
                        wrap: true,
                    },
                    {
                        name: "Best Count",
                        selector: row => row.bestCount,
                        sortable: true,
                    },
                    {
                        name: "Worst Count",
                        selector: row => row.worstCount,
                        sortable: true,
                    },
                    {
                        name: "Value to Customer",
                        selector: row => row.valueToCustomer,
                        sortable: true,
                    },
                    {
                        name: "Rank",
                        selector: row => row.rank,
                        sortable: true,
                    },
                ];

                setRankedData(data);
                setConjointColumns(dynamicColumns);

                const percentageData = calculatePreferences(stepData, options);
                setBWChartData(percentageData);
                const transformData = () => {
                    const levelCounts = {};
                    const totalCounts = {};

                    // Initialize counts for each attribute level
                    options.forEach((option) => {
                        totalCounts[option.attribute] = 0;
                        option.levels.forEach((level) => {
                            levelCounts[level] = 0;
                        });
                    });

                    // Parse `sampleData` to count levels
                    stepData.forEach((entry) => {
                        if (entry.selected_option) {  // Check if selected_option exists
                            const selectedOption = JSON.parse(entry.selected_option);

                            // Check if both 'best' and 'worst' properties exist
                            if (selectedOption.best && selectedOption.worst) {
                                const levels = [...selectedOption.best.split(", "), ...selectedOption.worst.split(", ")];

                                levels.forEach((level) => {
                                    if (levelCounts[level] !== undefined) {
                                        levelCounts[level] += 1;
                                    }
                                });
                            } else {
                                console.warn('Missing best or worst property in selectedOption:', selectedOption);
                            }
                        } else {
                            console.warn('selected_option is undefined or null for entry:', entry);
                        }
                    });

                    // Calculate total counts for each attribute
                    options.forEach((option) => {
                        option.levels.forEach((level) => {
                            totalCounts[option.attribute] += levelCounts[level];
                        });
                    });

                    // Convert counts to percentages and group by attributes
                    const groupedData = options.map((option) => {
                        const data = { attribute: option.attribute };
                        option.levels.forEach((level) => {
                            const total = totalCounts[option.attribute];
                            data[level] = total > 0 ? ((levelCounts[level] / total) * 100).toFixed(2) : '0.00'; // Save as percentage
                        });
                        return data;
                    });

                    return groupedData;
                };
                const count = transformData();
                // console.log(count);
                setCountBW(count);
                const calculatePreferencesBW = () => {
                    const scores = {};
                  
                    // Initialize scores
                    options.forEach((option) => {
                      scores[option.attribute] = {};
                      option.levels.forEach((level) => {
                        scores[option.attribute][level] = 0;
                      });
                    });
                  
                    // Process stepdata
                    stepData.forEach((entry) => {
                      try {
                        const selectedOption = JSON.parse(entry.selected_option);
                        const best = selectedOption.best.split(", ");
                        const worst = selectedOption.worst.split(", ");
                  
                        // Increment scores for best levels
                        best.forEach((level) => {
                          options.forEach((option) => {
                            if (option.levels.includes(level)) {
                              scores[option.attribute][level] += 1;
                            }
                          });
                        });
                  
                        // Decrement scores for worst levels
                        worst.forEach((level) => {
                          options.forEach((option) => {
                            if (option.levels.includes(level)) {
                              scores[option.attribute][level] -= 1;
                            }
                          });
                        });
                      } catch (error) {
                        console.error("Error processing entry:", entry, error);
                      }
                    });
                  
                    // Normalize scores to percentages with one decimal point
                    Object.keys(scores).forEach((attribute) => {
                      const levels = scores[attribute];
                      const maxScore = Math.max(...Object.values(levels).map(Math.abs));
                  
                      if (maxScore > 0) {
                        Object.keys(levels).forEach((level) => {
                          levels[level] = parseFloat(((levels[level] / maxScore) * 100).toFixed(1));
                        });
                      }
                    });
                  
                    // console.log(scores);
                    const scores_chart = Object.keys(scores).flatMap((attribute) => {
                        return Object.keys(scores[attribute]).map((level) => ({
                          attribute,
                          level,
                          percentage: scores[attribute][level],
                        }));
                      });
                    // console.log(scores_chart);
                    return scores_chart;
                  };
                  
                const averagePreferences=calculatePreferencesBW();
                setPreferenceScoresBW(averagePreferences);

            } else {
                setRankedData([]);
                setConjointColumns([]);
                setBWChartData({});
                setCountBW([]);
                setPreferenceScoresBW([]);
            }

            var get_ans = {
                questionAnswers: questionAnswers,
                stepQuestionAnswers: stepQuestionAnswers,
                questionOptionType: filteredType === "RadioImage" || filteredType === "RadioMultiImage" || filteredType === "GenericConjoint" ? true : false,
            }

            dispatch(getQuestionAnswerSummaryAsync(get_ans)).then((res) => {
                // console.log(res);
                if (res?.answer) {
                    // console.log(res?.answer);
                    setdashboardSummary(res?.answer)
                }
                if (res?.signal) {
                    setsignal(res?.signal);
                }
                if (res?.analysis) {
                    setanalysis(res?.analysis);
                }
                setGenerateStepButton(-1);
                var creditData = {
                    name: "Step Summary",
                    message: `Step Summary -${duonut_id}- ${title} - step ${step}`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);

                    getUserDetails((err, userDetails) => {
                        if (err) return;
                        dispatch(updateTotalCredit(userDetails.data.total_credit));
                        dispatch(updateCredit(userDetails.data.credit));

                    });
                });
                const token = localStorage.getItem("access");
                axios({
                    method: "POST",
                    url: `${domain}/duonut/create_edit_duonut_step_analytics`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        // Add your payload data here
                        duonut_id: duonut_id,
                        step: step,
                        analysis: res?.analysis,
                        summary: res?.answer,
                        signal: res?.signal,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        // console.log("success");
                        setStepSummary(!getStepSummary);
                    }
                }).catch(error => {
                    console.error('Error fetching data:', error);
                });
            }).catch((e) => {
                console.log(e.message, e);
            });
        } else {
            setisEmpty("Credit Coins is less.");
        }
    }

    const handelGenerateSummary = () => {
        if (credit >= 2) {
            //  Extract and concatenate AI conversation messages
            const questionAnswers = tableData.map(item => ({
                question: item.question,
                selected_option: item.selected_option
            }));

            // console.log(questionAnswers);

            var get_ans = {
                questionAnswers: questionAnswers,
            }

            dispatch(getQuestionAnswerSummaryAsync(get_ans)).then((res) => {
                // console.log(res);
                if (res?.answer) {
                    // console.log(res?.answer);
                    setdashboardAllSummary(res?.answer)
                }
                if (res?.signal) {
                    setallsignal(res?.signal);
                }
                var creditData = {
                    name: "All Step Summary",
                    message: `All Step Summary -${duonut_id}- ${title} `,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);

                    getUserDetails((err, userDetails) => {
                        if (err) return;
                        dispatch(updateTotalCredit(userDetails.data.total_credit));
                        dispatch(updateCredit(userDetails.data.credit));

                    });
                });


            }).catch((e) => {
                console.log(e.message, e);
            });
        } else {
            seterrMess("Credit Coins is less.");
        }

    }
    const handelGenerateUserSummary = (step, name, watch_id) => {
        if (credit >= 2) {
            //  Extract and concatenate AI conversation messages
            // console.log("user summary");
            const questionAnswers = userGroupData[step].records
                .map(item => ({
                    question: item.question,
                    answer: item.selected_option
                }));
            // console.log("User Q&A", questionAnswers)
            // if (questionAnswers.length === 0) {
            //     setisEmpty("No summary generated as this step doesn’t have any open ended question.");
            //     return null;
            // } else {
            //     setisEmpty("");
            // }
            var get_ans = {
                questionAnswers: questionAnswers,
            }

            dispatch(getUserQuestionAnswerSummaryAsync(get_ans)).then((res) => {
                // console.log(res);
                if (res?.answer) {
                    // console.log(res?.answer);
                    setUserSummary(res?.answer)
                }
                if (res?.signal) {
                    setsignal(res?.signal);
                }
                if (res?.signal_word) {
                    setsignalWord(res?.signal_word);
                }
                var creditData = {
                    name: "User Summary",
                    message: `User Summary -${duonut_id}- ${title} - user: ${name}`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                    getUserDetails((err, userDetails) => {
                        if (err) return;
                        dispatch(updateTotalCredit(userDetails.data.total_credit));
                        dispatch(updateCredit(userDetails.data.credit));

                    });
                });

                const token = localStorage.getItem("access");
                axios({
                    method: "POST",
                    url: `${domain}/duonut/create_edit_duonut_user_analytics`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        // Add your payload data here
                        duonut_id: duonut_id,
                        watch_id: watch_id,
                        signal_word: res?.signal_word,
                        summary: res?.answer,
                        signal: res?.signal,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        // console.log("success")
                    }
                }).catch(error => {
                    console.error('Error fetching data:', error);
                });
            }).catch((e) => {
                console.log(e.message, e);
            });
        } else {
            seterrMess("Credit Coins is less.");
        }
    }

    // Toggle the visibility of the second div
    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    // Prepare the chart data
    const getChartData = (usersCount) => {
        const totalUsers = analysis.reduce(
            (total, current) => total + current.number_of_unique_users,
            0
        );
        const percentage = (usersCount / totalUsers) * 100;

        return {
            labels: ["Users"],
            datasets: [
                {
                    data: [percentage, 100 - percentage],
                    backgroundColor: ["#36A2EB", "#D3D3D3"],
                },
            ],
        };
    };

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };

    // Function to fetch participant name
    const getParticipantName = async (id) => {
        const token = localStorage.getItem("access");
        try {
            const response = await axios.post(`${domain}/duonut/get_participant`, {
                profile_id: id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const p_name =
                    response?.data?.respondent_participant?.firstName +
                    " " +
                    response?.data?.respondent_participant?.lastInitial;
                return p_name;
            } else {
                return "Anonymous";
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return "Anonymous";
        }
    };

    // Fetch participant names when analytics data is loaded
    useEffect(() => {
        const fetchNames = async () => {
            const namesMap = {};
            for (const item of userGroupData || []) {
                if (item?.url_params?.respondent_profile_id) {
                    const name = await getParticipantName(item.url_params.respondent_profile_id);
                    namesMap[item.url_params.respondent_profile_id] = name;
                }
            }
            setParticipantNames(namesMap);
        };

        fetchNames();
    }, [userGroupData]);

    const highlightText = (text, signalWords) => {
        const regex = new RegExp(`\\b(${signalWords.join("|")})\\b`, "gi");

        return text.split(regex).map((part, index) => {
            if (signalWords.some((word) => word.toLowerCase() === part.toLowerCase())) {
                return (
                    <span key={index} style={{ backgroundColor: "yellow" }}>
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    // Function to process data
    const processData = (stepData, options) => {
        const data = [];

        // Iterate through each attribute in options
        options.forEach(({ attribute, levels }) => {
            const levelCounts = levels.map(level => ({
                level,
                count: stepData.filter(item => item.selected_option.includes(level)).length
            }));

            // Calculate percentages
            const total = levelCounts.reduce((sum, level) => sum + level.count, 0);
            const percentages = levelCounts.map(level => ({
                name: attribute,
                level: level.level,
                percentage: ((level.count / total) * 100).toFixed(1) // Percentage with 1 decimal
            }));

            // Merge data
            percentages.forEach(({ level, percentage }) => {
                const existing = data.find(d => d.name === attribute);
                if (existing) {
                    existing[level] = parseFloat(percentage);
                } else {
                    data.push({
                        name: attribute,
                        [level]: parseFloat(percentage)
                    });
                }
            });
        });

        return data;
    };

    const parseStepData = (stepData, options) => {
        // Create a map to track counts
        const counts = {};

        stepData.forEach(({ selected_option }) => {
            const { best, worst } = JSON.parse(selected_option);

            // Increment Best Count
            counts[best] = counts[best] || { bestCount: 0, worstCount: 0 };
            counts[best].bestCount += 1;

            // Increment Worst Count
            counts[worst] = counts[worst] || { bestCount: 0, worstCount: 0 };
            counts[worst].worstCount += 1;
        });

        // Create a data array for the table
        const data = Object.entries(counts).map(([combination, { bestCount, worstCount }]) => ({
            combination,
            bestCount,
            worstCount,
            valueToCustomer: (bestCount - worstCount), // You can adjust this calculation
        }));

        // Rank the data based on valueToCustomer
        data.sort((a, b) => b.valueToCustomer - a.valueToCustomer);
        data.forEach((item, index) => {
            item.rank = index + 1;
        });

        return data;
    };

    function calculatePreferences(stepData, options) {
        const levelStats = {};

        // Initialize stats for each level
        options.forEach(({ levels, attribute }) => {
            levels.forEach(level => {
                levelStats[level] = { shown: 0, best: 0, worst: 0 };
            });
        });

        // Count appearances, best, and worst selections
        stepData.forEach(({ selected_option }) => {
            const { best, worst } = JSON.parse(selected_option);
            const bestParts = best.split(", ");
            const worstParts = worst.split(", ");

            options.forEach(({ levels }, index) => {
                if (levels.includes(bestParts[index])) {
                    levelStats[bestParts[index]].best += 1;
                }
                if (levels.includes(worstParts[index])) {
                    levelStats[worstParts[index]].worst += 1;
                }
                levels.forEach(level => {
                    if (bestParts.includes(level) || worstParts.includes(level)) {
                        levelStats[level].shown += 1;
                    }
                });
            });
        });

        // Calculate percentages
        const percentages = {};
        Object.keys(levelStats).forEach(level => {
            const { shown, best, worst } = levelStats[level];
            percentages[level] = {
                best: shown ? (best / shown) * 100 : 0,
                worst: shown ? (worst / shown) * 100 : 0,
            };
        });

        return percentages;
    }

    const updateDuonutStepAnalytics = (step) => {
        const stepData = tableData
            .filter(item => String(item.step) === String(step) || item.step.startsWith(`${step}-ai`))
            .map(item => ({
                question: item.question,
                step: item.step,
                selected_option: item.selected_option
            }));
        setstepData(stepData);

        const filteredType = tableData
            .filter(item => String(item.step) === String(step))  // Filter items where step matches
            .map(item => item.type)[0];  // Extract only the 'type' property
        setElementType(filteredType);
        // console.log("filteredType", filteredType, stepData, userActivities);
        if (filteredType === "GenericConjoint") {
            const options = userActivities[step - 1]?.elements?.find(element => element?.elementType === "GenericConjoint")?.elementAttributes.options;
            setconjointOptionsData(options);
            // Step 1: Extract and count combinations
            const combinationCounts = {};
            stepData.forEach((item) => {
                const combination = item.selected_option;
                if (combination === "None of the above") return null;
                if (combination) {
                    combinationCounts[combination] = (combinationCounts[combination] || 0) + 1;
                }
            });

            // Step 2: Sort by count and assign rank
            const sortedCombinations = Object.entries(combinationCounts)
                .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
                .map(([combination, count], index) => {
                    const attributeValues = combination.split(", ").map((value, i) => ({
                        [options[i]?.attribute || `Attribute ${i + 1}`]: value,
                    }));
                    return {
                        rank: index + 1,
                        count,
                        ...Object.assign({}, ...attributeValues),
                    };
                });

            setRankedData(sortedCombinations);

            // Step 3: Dynamically create columns
            const dynamicColumns = options.map((option) => ({
                name: option.attribute,
                selector: (row) => row[option.attribute],
                sortable: true,
            }));

            // Add static columns for Count and Rank
            dynamicColumns.push(
                {
                    name: "Count",
                    selector: (row) => row.count,
                    sortable: true,
                },
                {
                    name: "Rank",
                    selector: (row) => row.rank,
                    sortable: true,
                }
            );

            setConjointColumns(dynamicColumns);

            const chartData = processData(stepData, options);

            setConjointChartDatas(chartData);

            // console.log("options", options, combinationCounts, sortedCombinations, chartData);

            //Attribute Importance
            const parseData = (data) => {
                // console.log(data, options);
                const attributes = {};  // Dynamic attributes container

                // Initialize attributes based on options (dynamic)
                options.forEach(option => {
                    attributes[option.attribute] = [];
                });

                // Parse the data
                data.forEach((item, index) => {
                    // console.log(`Processing item at index ${index}:`, item);

                    // Ensure `selected_option` exists and is a string
                    if (item.selected_option && typeof item.selected_option === "string") {
                        // console.log(`Valid selected_option at index ${index}:`, item.selected_option);

                        // Split selected_option into parts
                        const parts = item.selected_option.split(", ");
                        if (parts.length === options.length) {
                            options.forEach((option, idx) => {
                                const value = parts[idx];

                                // Handle each attribute
                                if (option.attribute === "Amount" || option.attribute === "Price") {
                                    // For numeric attributes, parse as number
                                    const numericValue = option.attribute === "Amount"
                                        ? parseFloat(value.replace("g", "")) || 0
                                        : parseInt(value.replace("$", ""), 10) || 0;
                                    attributes[option.attribute].push(numericValue);
                                } else {
                                    // For categorical attributes (anything else), just store the value
                                    attributes[option.attribute].push(value || "Unknown");
                                }
                            });
                        } else {
                            console.warn(`Unexpected format for selected_option at index ${index}:`, item.selected_option);
                        }
                    } else {
                        console.warn(`Missing or invalid selected_option at index ${index}:`, item);
                    }
                });

                // Calculate ranges for each attribute
                const ranges = {};
                let totalRange = 0;

                options.forEach(option => {
                    const attributeData = attributes[option.attribute];

                    if (attributeData.length > 0) {
                        if (typeof attributeData[0] === "number") {
                            // For numeric attributes like Amount and Price
                            const range = Math.max(...attributeData) - Math.min(...attributeData);
                            ranges[option.attribute] = range;
                            totalRange += range;
                        } else {
                            // For categorical attributes (like Product or Bulk Order)
                            const uniqueValues = new Set(attributeData);
                            const range = uniqueValues.size - 1;  // Categories - 1
                            ranges[option.attribute] = range;
                            totalRange += range;
                        }
                    } else {
                        ranges[option.attribute] = 0;
                    }
                });

                // console.log("Calculated ranges:", ranges);

                // If total range is 0 or too small, handle edge case
                if (totalRange === 0) {
                    console.error("Total range is zero. Cannot calculate relative importance.");
                    return [];
                }

                // Calculate relative importance for each attribute
                const importanceData = options.map(option => {
                    const importance = ((ranges[option.attribute] / totalRange) * 100).toFixed(1);
                    return {
                        attribute: option.attribute,
                        importance: importance
                    };
                });

                // console.log("Calculated importance data:", importanceData); // Log the final result
                return importanceData;
            };


            const importanceData = parseData(stepData);
            setGenericRI(importanceData);

            //Average preference for levels(Regression)
            const parseDataRegression = (data) => {
                const attributeCounts = {};
                const totalCount = data.length;

                data.forEach((item) => {
                    const levels = item.selected_option.split(", ");
                    levels.forEach((level, index) => {
                        const attribute = options[index].attribute;
                        if (!attributeCounts[attribute]) attributeCounts[attribute] = {};

                        if (!attributeCounts[attribute][level]) attributeCounts[attribute][level] = 0;
                        attributeCounts[attribute][level] += 1;
                    });
                });

                // Calculate preferences and baseline
                const chartData = [];
                Object.keys(attributeCounts).forEach((attribute) => {
                    const levels = Object.keys(attributeCounts[attribute]);
                    const totalLevels = levels.map((level) => attributeCounts[attribute][level]);
                    const baseline = totalLevels.reduce((sum, count) => sum + count, 0) / levels.length; // Average as baseline

                    levels.forEach((level) => {
                        if (level !== "None of the above") { // Exclude "None of the above"
                            const preference = attributeCounts[attribute][level];
                            chartData.push({
                                attribute,
                                level,
                                percentage: (((preference - baseline) / totalCount) * 100).toFixed(1), // Adjusted percentage
                            });
                        }
                    });
                });

                // console.log(chartData);
                return chartData;
            };
            const partWorthsData = parseDataRegression(stepData);
            setPartWorths(partWorthsData);
            // const percentages = partWorths.map((item) => parseFloat(item.percentage));
            // const minValue = Math.min(...percentages);
            // const maxValue = Math.max(...percentages);

            // Add some padding to the range for better visualization
            // const padding = 10; // Adjust this value if needed
            // setdomainMin(Math.min(Math.floor(minValue / 10) * 10 - padding, 0)); // Ensure 0 is included
            // setdomainMax(Math.max(Math.ceil(maxValue / 10) * 10 + padding, 0)); // Ensure 0 is included

            // // Group data by attribute for stacked bars
            // const groupedData = options.map((option) => ({
            //     attribute: option.attribute,
            //     levels: partWorths.filter((item) => item.attribute === option.attribute),
            // }));
            // console.log(groupedData);

            const countLevels = (data) => {
                let counts = {};
                data.forEach(item => {
                    const selectedOption = item.selected_option;
                    const parts = selectedOption.split(', ');

                    // Loop through each option to check if the selected_option matches any level
                    options.forEach(option => {
                        // Go through the levels of the current attribute and match them with parts of selected_option
                        option.levels.forEach(level => {
                            parts.forEach((part, index) => {
                                if (part === level) {
                                    // Check if the counts object for this attribute exists, if not, initialize it
                                    if (!counts[option.attribute]) {
                                        counts[option.attribute] = {};
                                    }

                                    // If the level exists for this attribute, initialize an object to store the count and value
                                    if (!counts[option.attribute][level]) {
                                        counts[option.attribute][level] = {
                                            count: 0,
                                            value: level
                                        };
                                    }

                                    // Increment the level count for this attribute
                                    counts[option.attribute][level].count++;
                                }
                            });
                        });
                    });
                });

                // Function to calculate the percentages based on total number of entries in sample data
                const calculatePercentages = (counts, totalEntries) => {
                    Object.keys(counts).forEach(attribute => {
                        Object.keys(counts[attribute]).forEach(level => {
                            const count = counts[attribute][level].count;
                            counts[attribute][level].percentage = ((count / totalEntries) * 100).toFixed(1) + '%';
                        });
                    });
                };

                // Calculate the total number of entries (sample data length)
                const totalEntries = data.length;

                // Calculate percentages
                calculatePercentages(counts, totalEntries);

                // Format output in the required format
                let formattedOutput = {};

                Object.keys(counts).forEach(attribute => {
                    formattedOutput[attribute] = {};

                    Object.keys(counts[attribute]).forEach(level => {
                        formattedOutput[attribute][level] = counts[attribute][level].percentage;
                    });
                });

                // console.log(formattedOutput);
                return formattedOutput;
            };
            const countAnalysis_copy = countLevels(stepData);
            // console.log(countAnalysis_copy);
            setcountAnalysis(countAnalysis_copy);

            //Price level count analysis
            const priceLevel = (data) => {
                let priceCounts = {};

                // Regular expression to extract the price (assuming it starts with "$" and followed by digits)
                const priceRegex = /\$(\d+(\.\d{1,2})?)/;

                // Function to dynamically extract and count prices
                data.forEach((item) => {
                    const selectedOption = item.selected_option;
                    const match = selectedOption.match(priceRegex);
                    if (match) {
                        const price = match[0].replace('$', ''); // Extract the price (e.g., '200')

                        // Ensure price is a valid number before proceeding
                        if (!isNaN(price) && price !== '') {
                            // Initialize the price count if not already present
                            if (!priceCounts[price]) {
                                priceCounts[price] = { total: 0 };
                            }

                            // Increment the total count for this price (every time the price is shown)
                            priceCounts[price].total += 1;
                            // console.log(`Price: ${price}`);
                        }
                    }
                });

                // Calculate percentage for each price level
                let pricePercentages = {};
                const totalResponses = stepData.length; // Total number of responses

                for (let price in priceCounts) {
                    const total = priceCounts[price].total;

                    // Calculate percentage: (total / totalResponses) * 100
                    const percentage = ((total / totalResponses) * 100).toFixed(1);

                    // Store the percentage in a new object
                    pricePercentages[price] = percentage;
                }

                // Output the price percentages to verify results
                // console.log('Price Percentages: ', pricePercentages);
                return pricePercentages;
            }
            const price_Counts = priceLevel(stepData);
            setpriceAnalysis(price_Counts);
        } else {
            setRankedData([]);
            setConjointColumns([]);
            setConjointChartDatas([]);
            setGenericRI([]);
            setPartWorths([]);
            setcountAnalysis([]);
            setpriceAnalysis([]);
        }

        if (filteredType === "BestWorstConjoint") {
            const options = userActivities[step - 1]?.elements?.find(element => element?.elementType === "BestWorstConjoint")?.elementAttributes.options;
            setconjointOptionsData(options);

            const data = parseStepData(stepData, options);

            const dynamicColumns = [
                {
                    name: "Combination",
                    selector: row => row.combination,
                    sortable: true,
                    wrap: true,
                },
                {
                    name: "Best Count",
                    selector: row => row.bestCount,
                    sortable: true,
                },
                {
                    name: "Worst Count",
                    selector: row => row.worstCount,
                    sortable: true,
                },
                {
                    name: "Value to Customer",
                    selector: row => row.valueToCustomer,
                    sortable: true,
                },
                {
                    name: "Rank",
                    selector: row => row.rank,
                    sortable: true,
                },
            ];

            setRankedData(data);
            setConjointColumns(dynamicColumns);

            const percentageData = calculatePreferences(stepData, options);
            setBWChartData(percentageData);

            const transformData = () => {
                const levelCounts = {};
                const totalCounts = {};

                // Initialize counts for each attribute level
                options.forEach((option) => {
                    totalCounts[option.attribute] = 0;
                    option.levels.forEach((level) => {
                        levelCounts[level] = 0;
                    });
                });

                // Parse `sampleData` to count levels
                stepData.forEach((entry) => {
                    if (entry.selected_option) {  // Check if selected_option exists
                        const selectedOption = JSON.parse(entry.selected_option);

                        // Check if both 'best' and 'worst' properties exist
                        if (selectedOption.best && selectedOption.worst) {
                            const levels = [...selectedOption.best.split(", "), ...selectedOption.worst.split(", ")];

                            levels.forEach((level) => {
                                if (levelCounts[level] !== undefined) {
                                    levelCounts[level] += 1;
                                }
                            });
                        } else {
                            console.warn('Missing best or worst property in selectedOption:', selectedOption);
                        }
                    } else {
                        console.warn('selected_option is undefined or null for entry:', entry);
                    }
                });

                // Calculate total counts for each attribute
                options.forEach((option) => {
                    option.levels.forEach((level) => {
                        totalCounts[option.attribute] += levelCounts[level];
                    });
                });

                // Convert counts to percentages and group by attributes
                const groupedData = options.map((option) => {
                    const data = { attribute: option.attribute };
                    option.levels.forEach((level) => {
                        const total = totalCounts[option.attribute];
                        data[level] = total > 0 ? ((levelCounts[level] / total) * 100).toFixed(2) : '0.00'; // Save as percentage
                    });
                    return data;
                });

                return groupedData;
            };
            const count = transformData();
            // console.log(count);
            setCountBW(count);

            const calculatePreferencesBW = () => {
                const scores = {};
              
                // Initialize scores
                options.forEach((option) => {
                  scores[option.attribute] = {};
                  option.levels.forEach((level) => {
                    scores[option.attribute][level] = 0;
                  });
                });
              
                // Process stepdata
                stepData.forEach((entry) => {
                  try {
                    const selectedOption = JSON.parse(entry.selected_option);
                    const best = selectedOption.best.split(", ");
                    const worst = selectedOption.worst.split(", ");
              
                    // Increment scores for best levels
                    best.forEach((level) => {
                      options.forEach((option) => {
                        if (option.levels.includes(level)) {
                          scores[option.attribute][level] += 1;
                        }
                      });
                    });
              
                    // Decrement scores for worst levels
                    worst.forEach((level) => {
                      options.forEach((option) => {
                        if (option.levels.includes(level)) {
                          scores[option.attribute][level] -= 1;
                        }
                      });
                    });
                  } catch (error) {
                    console.error("Error processing entry:", entry, error);
                  }
                });
              
                // Normalize scores to percentages with one decimal point
                Object.keys(scores).forEach((attribute) => {
                  const levels = scores[attribute];
                  const maxScore = Math.max(...Object.values(levels).map(Math.abs));
              
                  if (maxScore > 0) {
                    Object.keys(levels).forEach((level) => {
                      levels[level] = parseFloat(((levels[level] / maxScore) * 100).toFixed(1));
                    });
                  }
                });
              
                // console.log(scores);
                const scores_chart = Object.keys(scores).flatMap((attribute) => {
                    return Object.keys(scores[attribute]).map((level) => ({
                      attribute,
                      level,
                      percentage: scores[attribute][level],
                    }));
                  });
                // console.log(scores_chart);
                return scores_chart;
              };
              
            const averagePreferences=calculatePreferencesBW();
            setPreferenceScoresBW(averagePreferences);

            //Net Best/Worst
            // const calculateResults = () => {
            //     const bestCounts = {};
            //     const worstCounts = {};
            //     const totalResponses = stepData.length;
            
            //     // Initialize counts for all levels
            //     options.forEach((option) => {
            //       option.levels.forEach((level) => {
            //         bestCounts[level] = 0;
            //         worstCounts[level] = 0;
            //       });
            //     });
            
            //     // Count best and worst selections
            //     stepData.forEach((entry) => {
            //       const selectedOption = JSON.parse(entry.selected_option);
            //       const best = selectedOption.best.split(", ");
            //       const worst = selectedOption.worst.split(", ");
            
            //       best.forEach((level) => bestCounts[level]++);
            //       worst.forEach((level) => worstCounts[level]++);
            //     });
            
            //     // Calculate percentages
            //     const bestPercentagesResult = {};
            //     const worstPercentagesResult = {};
            //     const netChoiceResult = {};
            
            //     Object.keys(bestCounts).forEach((level) => {
            //       bestPercentagesResult[level] = (bestCounts[level] / totalResponses) * 100;
            //       worstPercentagesResult[level] = (worstCounts[level] / totalResponses) * 100;
            //       netChoiceResult[level] = bestPercentagesResult[level] - worstPercentagesResult[level];
            //     });
            
            //     setBestPercentages(bestPercentagesResult);
            //     setWorstPercentages(worstPercentagesResult);
            //     setNetChoice(netChoiceResult);
            //   };
            //   console.log(bestPercentages,worstPercentages,netChoice);
            //   calculateResults();

            const calculateResults = () => {
                const bestCounts = {};
                const worstCounts = {};
                const totalResponses = stepData.length;
            
                // Initialize counts for all levels
                options.forEach((option) => {
                  option.levels.forEach((level) => {
                    bestCounts[level] = 0;
                    worstCounts[level] = 0;
                  });
                });
            
                // Count best and worst selections
                stepData.forEach((entry) => {
                  const selectedOption = JSON.parse(entry.selected_option);
                  const best = selectedOption.best.split(", ");
                  const worst = selectedOption.worst.split(", ");
            
                  best.forEach((level) => bestCounts[level]++);
                  worst.forEach((level) => worstCounts[level]++);
                });
            
                // Calculate percentages and prepare data for the graph
                const graphDataResult = [];
                Object.keys(bestCounts).forEach((level) => {
                  const bestPercentage = (bestCounts[level] / totalResponses) * 100;
                  const worstPercentage = (worstCounts[level] / totalResponses) * 100;
                  const netPercentage = bestPercentage - worstPercentage;
            
                  graphDataResult.push({
                    level,
                    best: bestPercentage,
                    worst: -worstPercentage, // Display worst as negative
                    net: netPercentage,
                  });
                });
            
                setGraphData(graphDataResult);
              };
              console.log(graphData);
              calculateResults();

        } else {
            setRankedData([]);
            setConjointColumns([]);
            setBWChartData({});
            setCountBW([]);
            setPreferenceScoresBW([]);

        }


        const matchedItem = duonutStepAnalytics.find((item) => parseInt(item.step) === parseInt(step));
        if (matchedItem) {
            // console.log("Data matched");
            setdashboardSummary(matchedItem.summary);
            setsignal(matchedItem.signal);
            setanalysis(matchedItem.analysis);
        } else {
            // console.log("No match found");
            setdashboardSummary("");
            setsignal("null");
            setanalysis([null]);
        }
    };
    const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    const updateDuonutUserAnalytics = (watch_id, idx) => {
        duonutUserAnalytics.map((item, i) => {
            if (item.watch_id === watch_id) {
                setSelectedUser(idx);
                setUserSummary(item?.summary);
                setsignal(item?.signal);
                setsignalWord(item?.signal_word);
            }
        });
    };
    const getColorForAttribute = (attribute) => {
        // Generate a hash for the attribute
        const hash = attribute.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

        // Generate a random color by manipulating the hash and using a random factor
        const r = (hash * 37 + 101) % 256;  // Red channel
        const g = (hash * 53 + 47) % 256;  // Green channel
        const b = (hash * 97 + 13) % 256;  // Blue channel

        // Random adjustment to add some variety to colors
        const randomFactor = Math.floor(Math.random() * 50); // Introduce randomness

        const finalR = Math.min(255, r + randomFactor);
        const finalG = Math.min(255, g + randomFactor);
        const finalB = Math.min(255, b + randomFactor);

        // Return the color in hex format
        return `#${(finalR).toString(16).padStart(2, '0')}${(finalG).toString(16).padStart(2, '0')}${(finalB).toString(16).padStart(2, '0')}`;
    };
    const prepareChartData = (countAnalysis) => {
        const chartData = [];

        Object.keys(countAnalysis).forEach((category) => {
            Object.keys(countAnalysis[category]).forEach((level) => {
                chartData.push({
                    category,
                    level,
                    percentage: parseFloat(countAnalysis[category][level]),
                });
            });
        });
        // console.log(chartData);
        return chartData;
    };

    // Prepare the data for charts
    const chartData = prepareChartData(countAnalysis);
    const count_transformedData = chartData.map((data) => ({
        label: `${data.category}: ${data.level}`,
        percentage: data.percentage,
    }));
    // console.log(count_transformedData);

    const getRandomColorCount = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Get dynamic category colors for each attribute
    const generateCategoryColors = (data) => {
        const categoryColors = {};
        data.forEach(item => {
            const category = item.label.split(':')[0]; // Extract category from the label
            if (!categoryColors[category]) {
                categoryColors[category] = getRandomColorCount();
            }
        });
        return categoryColors;
    };

    // Get dynamic category colors each level
    const categoryColors = generateCategoryColors(count_transformedData);
    const priceAnalysis_data = Object.keys(priceAnalysis).map((price) => ({
        price: `$${price}`,
        percentage: parseFloat(priceAnalysis[price]),
    }));
    // console.log(priceAnalysis_data);

    return (
        <div className="chartContainer">

            <div className="LeftSidebarContainer" style={{ borderTop: "2px solid #dbdfe8" }}>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(1)}>Duonut AI Summary
                    {selectedTab === 1 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(2)}>User Responses
                    {selectedTab === 2 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(5)}>Reports
                    {selectedTab === 5 && <div className="selected-line"></div>}
                </div>
                {/* <div className="LeftSidebarTab" onClick={() => setselectedTab(3)}>Form Data
                    {selectedTab === 3 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(4)}>Graph
                    {selectedTab === 4 && <div className="selected-line"></div>}
                </div> */}
            </div>
            <ReactTooltip id="analyticspage2" place="top" effect="solid"><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" /> 2</ReactTooltip>

            {selectedTab === 4 && <>
                {duonut_id === 149 && <div className="CATTP1templateblur">
                    <div className='CATTP1leftContainer'>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Production")}>
                            <div style={selectedColumn1 === "Actual Production" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Production</div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Prod/hr as per cycle time")}>
                            <div style={selectedColumn1 === "Prod/hr as per cycle time" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Prod/hr as per cycle time </div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Machine Utilization in %")}>
                            <div style={selectedColumn1 === "Machine Utilization in %" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Machine Utilization in %</div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Output Hrs")}>
                            <div style={selectedColumn1 === "Actual Output Hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Output Hrs </div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Available M/C hrs")}>
                            <div style={selectedColumn1 === "Available M/C hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Available M/C hrs</div>
                        </div>
                    </div>

                    <div className='CATTP1rightContainer' id="scrollableDiv">
                        <h2 style={{ padding: "2rem" }}>{"Activity Name - " + duonutName}</h2>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart0 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>



                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart3 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart4 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart1 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart2 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                    </div>
                </div>}

                <br></br><br></br><br></br><br></br>

                <div className="DR1linecharfilter">
                    <div>Select Date Field to use in Chart</div>
                    <select value={selectedDate} className='text_editor_dropdown'
                        onChange={e => setselectedDate(e.target.value)} >
                        <option key={"CreatedAt"} value="CreatedAt" selected>CreatedAt</option>
                        {columns1.map((column, idx) => {
                            if (column.name.toLowerCase().includes('date')) {
                                return (
                                    <option key={idx + 1} value={column.name}>
                                        {column.name}
                                    </option>
                                );
                            }
                            // If the column does not contain a date, do not render anything
                            return null;
                        }
                        )}
                    </select>
                </div>
                <br></br>

                <div className="DR1linecharfilter">
                    <div>Select Number Field to show in Chart</div>
                    <select value={selectedColumn} className='text_editor_dropdown'
                        onChange={e => setselectedColumn(e.target.value)} >
                        <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                            Select Field</option>
                        {columns1.map((column, idx) => {
                            return <option key={idx + 1} value={column.name} > {column.name} </option>
                        }
                        )}
                    </select>
                </div>
                <br></br>

                <div className="DR1linecharfilter">
                    <div>Select Field to Filter wise show in Chart</div>
                    <select value={selectedFilterColumn} className='text_editor_dropdown'
                        onChange={e => setselectedFilterColumn(e.target.value)} >
                        <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                            Select Field</option>
                        {columns1.map((column, idx) => {
                            return <option key={idx + 1} value={column.name} > {column.name} </option>
                        }
                        )}
                    </select>
                </div>

                <div className="DR1linechart">
                    <Chart
                        type="line"
                        width={800}
                        height={400}

                        series={lineChartData}

                        options={{
                            title: {
                                text: "Graphs",
                                style: { fontSize: 30 }
                            },

                            // colors: ['#f90000'],
                            // theme: { mode: "light" },

                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    format: 'dd MMM', // Customize the date format here
                                    // show: false,
                                },
                                title: {
                                    text: "Date",
                                    // style: { color: "#f90000" }
                                },
                            },

                            yaxis: {
                                title: {
                                    text: selectedColumn,
                                    // style: { color: "#f90000" }
                                },
                            }

                        }}

                    >

                    </Chart>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </>}

            {selectedTab === 2 && <>
                <br></br>
                <br></br>
                <h2>User Responses</h2>
                <div>You can view user-wise feedback for each step of your questionnaire</div>
                <br></br>
                <br></br>
                <div style={{ width: "90vw", margin: "auto" }}>
                    {userGroupData.map((item, i) => (<div style={{
                        // border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px", margin: "10px",
                        background: "#cccccc21"
                    }}>
                        <div key={i} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>{i + 1}</div>
                            {/* <div>{item.created_at}</div> */}
                            <div style={{ color: "#37474F", fontSize: "15px" }}>
                                {new Date(item.created_at).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                })}
                            </div>
                            <div>{item.nickname}
                                {item?.url_params?.respondent_profile_id && <label>
                                    -{" "}Respondent Participant -{" "}
                                    {participantNames[item.url_params.respondent_profile_id] || "Loading..."}
                                </label>}
                            </div>
                            {userSummary && selectedUser === i ? (
                                // Display the signal after summary generation
                                <>{(elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint") && (
                                    <>
                                        {signal === "positive" ? (
                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                <label style={{ marginRight: "10px", color: "green" }}>
                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                                </label>
                                            </label>
                                        ) : signal === "negative" ? (
                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                <label style={{ marginRight: "10px", color: "red" }}>
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                </label>
                                            </label>
                                        ) : (
                                            <label></label>
                                        )}
                                    </>
                                )}
                                </>
                            ) : (
                                // Display the button while generating or before generation
                                (elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint") && (<button
                                    className="AIDPN1button"
                                    style={{ width: "10rem" }}
                                    disabled={selectedUser === i} // Disable button while generating
                                    onClick={() => {
                                        setSelectedUser(i);
                                        setUserSummary("");
                                        handelGenerateUserSummary(i, item.nickname, item.watch_id);
                                        (showConversation === -1 || showConversation !== i) ? setshowConversation(i) : setshowConversation(-1);
                                    }}
                                    data-tooltip-id="analyticspage2"
                                >
                                    {duonutUserAnalytics.find((item1) => item1.watch_id === item.watch_id) ? "Refresh"
                                        : selectedUser === i ? "Generating..." : "Generate"}
                                </button>)
                            )}
                            <label style={{ cursor: "pointer" }} onClick={() => { updateDuonutUserAnalytics(item.watch_id, i); (showConversation === -1 || showConversation !== i) ? setshowConversation(i) : setshowConversation(-1) }}>
                                <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (showConversation === i) ? "rotate(180deg)" : "rotate(0deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                            </label>
                        </div>
                        {userSummary && selectedUser === i && showConversation === i && elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint" && <div style={{ margin: "1rem 10px", textAlign: "left" }}>
                            <div
                                style={{ borderBottom: " 0.5px solid #ccc", padding: "20px 0px", borderTop: " 0.5px solid #ccc" }}>
                                <b>Summary -</b >
                                {/* {userSummary} */}
                                {highlightText(userSummary, signalWord)}
                            </div>

                        </div>}

                        {showConversation === i && (
                            <div style={{ marginTop: "2rem" }}>
                                {item?.records?.map((record, index) => (
                                    <div key={index} style={{ padding: "5px", margin: "5px", textAlign: "left" }} >
                                        <div style={{ color: 'gray' }}>
                                            {/* Step-{record.step}.  {record.question} */}
                                            {`Step-${record.step.includes("ai") ? record.step.replace(/ai\d*/, "Follow up") : record.step}. - ${record.question}`}
                                        </div>
                                        <div style={{ padding: "8px 0px" }}>
                                            Answer - {record.selected_option}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    ))}
                </div>

                {/* <ReactTooltip id="analyticspage2" place="bottom" effect="solid"><i className="fa fa-database" aria-hidden="true" style={{ color: '#C0CA33' }}></i> &nbsp; 2 credit </ReactTooltip> */}

                <br></br>
                <br></br>
                <div style={{ margin: "0px 10px", borderTop: "1px solid #ccc" }}>
                    <div
                        style={{ cursor: "pointer", fontWeight: "bold", margin: "20px 0px", fontSize: "20px" }}
                        onClick={toggleTableVisibility} >
                        Raw-Form Data &nbsp; <span style={{ fontSize: "24px" }}>{isTableVisible ? <i class="fa fa-angle-up" aria-hidden="true"></i> : <i class="fa fa-angle-down" aria-hidden="true"></i>}</span>
                    </div>

                    {/* Conditionally Render Table */}
                    {isTableVisible && (

                        <DataTable
                            // title={"Submitted Data"}
                            // title={"Raw Data"}
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            paginationPerPage="50"
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                            fixedHeader
                            subHeader
                            subHeaderComponent={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    {/* Filter on the left */}
                                    <div>{subHeaderComponentMemo}</div>

                                    {/* Export buttons on the right */}
                                    <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                        {google_sheet_integration?.access_key && (
                                            <button
                                                data-tooltip-id="analyticspage2"
                                                className="btn-primary"
                                                onClick={() =>
                                                    createSheet(google_sheet_integration?.access_key, filteredItems)
                                                }
                                            >
                                                Export in Google Sheet
                                            </button>
                                        )}
                                        <button
                                            data-tooltip-id="analyticspage2"
                                            className="btn-primary"
                                            onClick={() => downloadCSV(filteredItems)}
                                        >
                                            Export CSV
                                        </button>
                                        <button
                                            data-tooltip-id="analyticspage2"
                                            className="btn-primary"
                                            onClick={() => downloadXLSX(filteredItems)}
                                        >
                                            Export Xlsx
                                        </button>
                                        <button
                                            data-tooltip-id="analyticspage2"
                                            className="btn-primary"
                                            onClick={() => downloadWordDoc(filteredItems)}
                                        >
                                            Export Word
                                        </button>
                                    </div>
                                </div>
                            }
                            persistTableHead
                            highlightOnHover
                        // subHeaderComponent={subHeaderComponentMemo}
                        // persistTableHead
                        // highlightOnHover
                        // actions={<>
                        //     {/* <button className="btn-primary" onClick={() => window.location.open(`duonut/`)} > Add</button> */}
                        //     {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, filteredItems)}>Export in Google Sheet</button>}
                        //     <button className="btn-primary" onClick={() => downloadCSV(filteredItems)}>Export CSV</button>
                        //     <button className="btn-primary" onClick={() => downloadXLSX(filteredItems)}>Export Xlsx</button>
                        //     <button className="btn-primary" onClick={() => downloadWordDoc(filteredItems)}>Export Word</button></>}
                        />
                    )}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </>}

            {
                selectedTab === 1 && <>
                    <br></br>
                    <br></br>
                    <div>
                        <h2>AI generated summary from your customers feedback.</h2>
                        <br></br>
                        <br></br>

                        <div className='AIDPN1DashboardContainerItem' style={{ width: "780px", margin: "1em auto", display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div className="AS1row">
                                    <h3>Here’s what your users have to say.</h3>
                                    <label style={{ textAlign: 'left', fontSize: "14px" }}>View AI generated summary from your customers feedback.</label>
                                </div>
                                {!dashboardAllSummary && <button className='AIDPN1button' style={{ fontSize: "1rem" }}
                                    onClick={() => handelGenerateSummary()}
                                    data-tooltip-id="analyticspage1">Generate All Steps Summary</button>}
                                {dashboardAllSummary && (
                                    <>
                                        {(elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint") && (
                                            <>
                                                {allsignal === "positive" ? (
                                                    <label style={{ display: "flex", alignItems: "center" }}>
                                                        <label style={{ marginRight: "10px", color: "green" }}>
                                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                                        </label>
                                                    </label>
                                                ) : allsignal === "negative" ? (
                                                    <label style={{ display: "flex", alignItems: "center" }}>
                                                        <label style={{ marginRight: "10px", color: "red" }}>
                                                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                        </label>
                                                    </label>
                                                ) : (
                                                    <label></label>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </div>
                            {dashboardAllSummary && <div style={{ textAlign: "left" }}>{dashboardAllSummary}</div>}
                            {errMess && <div style={{ textAlign: "left" }}>{errMess}</div>}
                        </div>

                        <br></br>
                        <h3>OR</h3>
                        <br></br>
                        <div>
                            {Array.from({ length: stepNum }, (_, index) => index).map((item, idx) => {
                                return <div style={{
                                    display: "flex", flexDirection: "column", justifyContent: "space-between", width: "780px",
                                    border: "1px solid gray", borderRadius: "5px", padding: "10px", margin: "10px auto"
                                }}>
                                    <div style={{ width: "100%", margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ fontWeight: "600" }}>Generate {userActivities[idx]?.name} Summary</div>
                                        {(!dashboardSummary || selectedStep !== idx + 1) &&
                                            <button className="AIDPN1button"
                                                onClick={() => { setGenerateStepButton(idx + 1); setselectedStep(idx + 1); setdashboardSummary(""); handelGenerateStepSummary(idx + 1) }}
                                                data-tooltip-id="analyticspage1">
                                                {duonutStepAnalytics.find((item1) => parseInt(item1.step) === (idx + 1)) ? "Refresh"
                                                    : generateStepButton === idx + 1 ? "Generating..." : "Generate"}
                                                {/* {selectedStep === idx + 1 && !isEmpty ? "Generating..." : "Generate"} */}
                                            </button>}
                                        {dashboardSummary && selectedStep === idx + 1 && (
                                            <>
                                                {(elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint") && (
                                                    <>
                                                        {signal === "positive" ? (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "green" }}>
                                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                                                </label>
                                                            </label>
                                                        ) : signal === "negative" ? (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "red" }}>
                                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                                </label>
                                                            </label>
                                                        ) : (
                                                            <label></label>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                        <label style={{ cursor: "pointer" }} onClick={() => { updateDuonutStepAnalytics(idx + 1); (selectedStep === -1 || selectedStep !== idx + 1) ? setselectedStep(idx + 1) : setselectedStep(-1); }}>
                                            <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (selectedStep === idx + 1) ? "rotate(180deg)" : "rotate(0deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                        </label>
                                    </div>
                                    {rankedData.length > 0 && selectedStep === idx + 1 && <div style={{ margin: "1rem 10px", textAlign: "left" }}>
                                        <DataTable
                                            title={" Ranked list of some product concepts as preferred by customers"}
                                            columns={conjointColumns}
                                            data={rankedData}
                                            pagination
                                            paginationPerPage="50"
                                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                            fixedHeader
                                            persistTableHead
                                            highlightOnHover
                                        />
                                    </div>}
                                    {conjointChartData.length > 0 && selectedStep === idx + 1 && <div>
                                        <h2 style={{ margin: '25px 0px' }}>Relative importance of attributes</h2>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart
                                                layout="vertical"
                                                data={genericRI}
                                                margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    type="number"
                                                    domain={[0, 100]} // Ensure values are shown as percentages from 0 to 100
                                                    tickFormatter={(value) => `${value}%`} // Format ticks as percentages
                                                    label={{
                                                        value: "Percentage of Levels",
                                                        position: "insideBottom",
                                                        offset: -10,
                                                        fontSize: 14,
                                                    }}
                                                />
                                                <YAxis
                                                    dataKey="attribute"
                                                    type="category"
                                                    label={{
                                                        value: "Attributes",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14, offset: -30,
                                                    }}
                                                />
                                                <Tooltip formatter={(value) => `${value}%`} />
                                                <Bar dataKey="importance" barSize={30} fill={getRandomColor()}>
                                                    {genericRI.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>

                                        <h2 style={{ margin: '25px 0px' }}>Distribution of preferences for levels</h2>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart
                                                data={conjointChartData}
                                                layout="vertical"
                                                margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
                                            >
                                                <XAxis type="number"
                                                    label={{
                                                        value: "Percentage of Levels",
                                                        position: "insideBottom",
                                                        offset: -10,
                                                        fontSize: 14,
                                                    }} />
                                                <YAxis type="category" dataKey="name"
                                                    label={{
                                                        value: "Attributes",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14, offset: -30,
                                                    }} />
                                                <Tooltip formatter={(value) => `${value}%`} />
                                                {/* <Legend /> */}
                                                {conjointOptionsData.map(({ levels }) =>
                                                    levels.map(level => (
                                                        <Bar key={level} dataKey={level} stackId="a" fill={getRandomColor()} />
                                                    ))
                                                )}
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <h2 style={{ margin: '25px 0px' }}>Average preferences for levels</h2>
                                        <ResponsiveContainer width="100%" height={500}>
                                            <BarChart
                                                data={partWorths}
                                                layout="vertical"
                                                margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    type="number"
                                                    domain={[-30, 60]}
                                                    label={{ fontSize: 14, value: "Percentage of Levels", position: "insideBottom", offset: -10, }}
                                                    axisLine={true}
                                                    tickLine={true}
                                                    tickFormatter={(value) => `${value}%`}
                                                    ticks={[-30, 0, 30, 60]}
                                                />
                                                <YAxis
                                                    type="category"
                                                    dataKey="level"
                                                    label={{
                                                        value: "Attributes",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14,
                                                        offset: -30,
                                                    }}
                                                />
                                                <ReferenceLine x={0} stroke="#000" strokeDasharray="none" /> {/* Solid line for 0 */}
                                                <Tooltip formatter={(value) => `${value}%`} />

                                                <Bar dataKey="percentage">
                                                    {partWorths.map((item, index) => {
                                                        const fillColor = getColorForAttribute(item.attribute); // Get color dynamically

                                                        return (
                                                            <Cell key={index} fill={fillColor} />
                                                        );
                                                    })}
                                                    {/* <LabelList
                                                        dataKey="percentage"
                                                        position="insideRight"
                                                        fill="#ffffff"
                                                        content={({ x, y, width, value, height }) => (
                                                            <text x={x + width / 2} y={y + height / 2} textAnchor="middle" fill="#ffffff">
                                                                {value}%
                                                            </text>
                                                        )}
                                                    /> */}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>

                                        <h2 style={{ margin: '25px 0px' }}>Count analysis of preferences for levels</h2>
                                        <ResponsiveContainer width="100%" height={500}>
                                            <BarChart
                                                data={count_transformedData}
                                                layout="vertical"
                                                margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis type="number" label={{ fontSize: 14, value: "Percentage of times chosen", position: "insideBottom", offset: -10, }} />
                                                <YAxis
                                                    dataKey="label"
                                                    type="category"
                                                    width={200}
                                                    label={{
                                                        value: "Levels by Attributes",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14,
                                                        offset: -30,
                                                    }}
                                                />
                                                <Tooltip formatter={(value) => `${value}%`} />
                                                <Bar
                                                    dataKey="percentage"
                                                    isAnimationActive={false}
                                                    shape={(props) => {
                                                        const { x, y, width, height, payload } = props;
                                                        const category = payload.label.split(':')[0]; // Extract category from label
                                                        const fillColor = categoryColors[category]; // Get the color for the category
                                                        return (
                                                            <rect x={x} y={y} width={width} height={height} fill={fillColor} />
                                                        );
                                                    }}
                                                >
                                                    <LabelList
                                                        dataKey="percentage"
                                                        position="insideRight"
                                                        fill="#ffffff"
                                                        formatter={(value) => `${value}%`}
                                                    />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <h2 style={{ margin: '25px 0px' }}>Price Percentage Distribution</h2>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart data={priceAnalysis_data} margin={{ top: 20, right: 30, left: 100, bottom: 20 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="price">
                                                    <Label value="Price" offset={-10} position="insideBottom" />
                                                </XAxis>
                                                <YAxis
                                                    label={{
                                                        value: "Average Preference (%)",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14,
                                                        offset: -30,
                                                    }} />
                                                <Tooltip formatter={(value) => `${value}%`} />
                                                <Line
                                                    type="monotone"
                                                    dataKey="percentage"
                                                    stroke="#8884d8"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>}


                                    {Object.keys(BWchartData).length > 0 && selectedStep === idx + 1 && <div>
                                        <h2>Distribution of preferences for levels</h2>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart
                                                data={countBW}
                                                layout="vertical"
                                                margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            >
                                                {/* XAxis Label Adjustment */}
                                                <XAxis
                                                    type="number"
                                                    domain={[0, 100]}
                                                    unit="%"
                                                    label={{
                                                        fontSize: 14,
                                                        value: "Average Preferences",  // X-axis label
                                                        position: "bottom",  // Put label at the bottom of the X-axis
                                                        offset: 10,  // Adjust as needed to position it well
                                                        angle: 0,  // Keep label horizontal (0 degrees)
                                                    }}
                                                />

                                                {/* YAxis Label Adjustment */}
                                                <YAxis
                                                    dataKey="attribute"
                                                    type="category"
                                                    label={{
                                                        value: "Levels",  // Y-axis label
                                                        angle: -90,  // Rotate the label 90 degrees
                                                        position: "insideLeft",  // Place label inside left of Y-axis
                                                        fontSize: 14,
                                                        offset: 0,  // Offset can be adjusted for fine-tuning
                                                    }}
                                                />

                                                <Tooltip formatter={(value) => `${value}%`} />

                                                {/* Loop through countBW and generate bars */}
                                                {countBW.length > 0 &&
                                                    countBW.map((data, index) => (
                                                        Object.keys(data)
                                                            .filter((key) => key !== "attribute")  // Skip 'attribute' field
                                                            .map((key) => (
                                                                <Bar
                                                                    key={`${key}-${index}`}  // Unique key for each bar
                                                                    dataKey={key}
                                                                    stackId="a"
                                                                    fill={getRandomColor()}
                                                                    name={key}
                                                                />
                                                            ))
                                                    ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <h2>Average preferences for levels</h2>
                                        <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                                data={preferenceScoresBW}
                                                layout="vertical"
                                                margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    type="number"
                                                    label={{ fontSize: 14, value: "Average Preferences", position: "insideBottom", offset: -10, }}
                                                    axisLine={true}
                                                    tickLine={true}
                                                    tickFormatter={(value) => `${value}%`}
                                                />
                                                <YAxis
                                                    type="category"
                                                    dataKey="level"
                                                    label={{
                                                        value: "Levels by attribute",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14,
                                                        offset: -30,
                                                    }}
                                                />
                                                <ReferenceLine x={0} stroke="#000" strokeDasharray="none" /> {/* Solid line for 0 */}
                                                <Tooltip formatter={(value) => `${value}%`} />

                                                <Bar dataKey="percentage">
                                                    {preferenceScoresBW.map((item, index) => {
                                                        const fillColor = getColorForAttribute(item.attribute); // Get color dynamically

                                                        return (
                                                            <Cell key={index} fill={fillColor} />
                                                        );
                                                    })}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                        
                                        <h2>Best/worst choice</h2>
                                        <div>
                                            {conjointOptionsData?.map(({ attribute, levels }) => (
                                                <AttributeChart
                                                    key={attribute}
                                                    attribute={attribute}
                                                    levels={levels}
                                                    percentages={BWchartData}
                                                />
                                            ))}
                                        </div>
                                        <h2>Net best/worst choice</h2>
                                        <div style={{ width: "100%", height: "600px" }}>
                                            <ResponsiveContainer>
                                            <BarChart
                                                data={graphData} layout="vertical"
                                                margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <YAxis
                                                    type="category"
                                                    dataKey="level"
                                                    label={{
                                                        value: "Levels by attribute",
                                                        angle: -90,
                                                        position: "insideLeft",
                                                        fontSize: 14,
                                                        offset: -20,
                                                    }}
                                                />
                                                <XAxis
                                                    type="number"
                                                    label={{
                                                        fontSize: 14,
                                                        value: "Percentage of times chosen (out of times shown)",
                                                        position: "bottom",
                                                        offset: 10,
                                                        angle: 0,
                                                    }}
                                                />
                                                <Tooltip  formatter={(value) => `${value}%`}/>
                                                <Bar dataKey="best" fill="#82ca9d" name="% Best" />
                                                <Bar dataKey="worst" fill="#ff7f7f" name="% Worst" />
                                                <Bar dataKey="net" fill="#8884d8" name="Net % Best/Worst" />
                                            </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>}

                                    {dashboardSummary && selectedStep === idx + 1 && elementType !== "GenericConjoint" && elementType !== "BestWorstConjoint" && <div style={{ margin: "3rem 10px", textAlign: "left" }}>
                                        <div style={{ fontWeight: "600", padding: "5px 0px" }}>Question - {stepData.find(item => parseInt(item.step) === parseInt(selectedStep)).question}</div>
                                        <br></br>
                                        <div><b>Summary - </b >{dashboardSummary}</div>
                                        <br></br>
                                        <div>
                                            <p style={{ marginBottom: "10px", fontWeight: "bold" }}>Answer Analysis - </p>
                                            {analysis.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    {/* Clickable div */}
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginBottom: "10px",
                                                            fontSize: "16px", // Set a base font size for the chart to be based on
                                                        }}

                                                    >
                                                        {/* User Information Section */}
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                                marginLeft: "10px",
                                                                background: "#f0f0f0",
                                                                borderRadius: "5px",
                                                                padding: "10px",
                                                                flex: "1", display: "flex", justifyContent: "space-between", alignItems: "center"
                                                            }}
                                                            onClick={() => toggleExpand(i)}
                                                        >
                                                            <p style={{ display: "flex", alignItems: "center" }}>
                                                                <PieChart
                                                                    data={[
                                                                        {
                                                                            title: "Users",
                                                                            value: item.number_of_unique_users,
                                                                            color: "#337ab7", // Custom color for the pie slice
                                                                        },
                                                                        {
                                                                            title: "Remaining",
                                                                            value:
                                                                                analysis.reduce(
                                                                                    (total, current) => total + current.number_of_unique_users,
                                                                                    0
                                                                                ) - item.number_of_unique_users,
                                                                            color: "#ccc", // Color for the remaining part
                                                                        },
                                                                    ]}
                                                                    lineWidth={55} // Adjust slice width for a thicker visual
                                                                    radius={20} // Adjust the radius for a larger pie chart
                                                                    style={{ width: "50px", height: "50px" }} // Adjust width and height for the container
                                                                    startAngle={90}
                                                                />
                                                                {item.number_of_unique_users} {" "}
                                                                <span style={{ color: "#37474fad" }}>
                                                                    (
                                                                    {Math.round(
                                                                        (item.number_of_unique_users /
                                                                            analysis.reduce(
                                                                                (total, current) => total + current.number_of_unique_users,
                                                                                0
                                                                            )) *
                                                                        100
                                                                    )}
                                                                    %)
                                                                </span>{" "}
                                                                - {item.theme_name}
                                                            </p>
                                                            <i class={`fa ${expandedIndex === i ? "fa-angle-up" : "fa-angle-down"}`}
                                                                aria-hidden="true" style={{ fontSize: "25px", padding: "20px" }}></i>
                                                        </div>
                                                    </div>

                                                    {/* Expanded section outside the clickable div */}
                                                    {expandedIndex === i && (
                                                        <div
                                                            style={{
                                                                margin: "10px 0px",
                                                                padding: "10px",
                                                                background: "#ffffff",
                                                                border: "1px solid #ddd",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <p style={{ color: "#337ab7" }}>
                                                                Summary - "{item.overall_summary_of_feedback || item.overall_summary_of_feedback_summary}"
                                                            </p>
                                                            <div style={{ marginTop: "10px" }}>
                                                                User Response - <br />
                                                                {/* {item.respective_feedback_summary.map((feedback, index) => (
                                                                    <p key={index} style={{ margin: "6px 0px", fontStyle: "italic" }}>
                                                                        "{feedback}"
                                                                    </p>
                                                                ))} */}
                                                                {Array.isArray(item?.respective_feedback_summary) ? (
                                                                    item.respective_feedback_summary?.map((feedback, index) => (
                                                                        <p key={index} style={{ margin: "6px 0px", fontStyle: "italic" }}>
                                                                            "{JSON.stringify(feedback)}"
                                                                        </p>
                                                                    ))
                                                                ) : (
                                                                    <p style={{ fontStyle: "italic", color: "#999" }}>No feedback available</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <br />
                                        <br></br>
                                        {/* <div style={{ fontWeight: "600", padding: "10px 0px" }}>User Answer: </div>
                                    {
                                        stepData
                                            .filter(item => item.step === String(selectedStep))
                                            .map((item, i) => (
                                                <div key={i} style={{}}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div>"{item.selected_option}"</div>
                                                        <br></br>
                                                    </div>
                                                </div>
                                            ))
                                    } */}
                                    </div>}
                                    {isEmpty && selectedStep === idx + 1 && <div style={{ textAlign: "left" }}>{isEmpty}</div>}

                                </div>
                            }
                            )}
                        </div>
                        <ReactTooltip id="analyticspage1" place="top" effect="solid"><img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" /> 2 </ReactTooltip>

                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            }


            {
                selectedTab === 3 && <>

                    <br></br>

                    <h2 style={{ padding: "1rem" }}>Form Data</h2>
                    <br></br>

                    <div>
                        <label style={{ fontSize: "1.2rem", fontWeight: "700", display: "inline-block", margin: "1rem" }}>Filters: </label>
                        {columns1.map(column => (
                            <label key={column.name} style={{ display: "inline-block", margin: "1rem" }}>
                                <input
                                    type="checkbox"
                                    checked={!column.omit}
                                    onChange={() => handleToggleColumn(column.name)}
                                />
                                {column.name}
                            </label>
                        ))}
                    </div>
                    <br></br>
                    <DataTable
                        title={"Form Data"}
                        columns={columns1.filter(col => !col.omit)}
                        data={transformedData}
                        pagination
                        paginationPerPage="50"
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        fixedHeader
                        persistTableHead
                        highlightOnHover
                        actions={<>
                            {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, transformedData)}>Export in Google Sheet</button>}
                            <button className="btn-primary" onClick={() => downloadCSV(transformedData)}>Export Csv</button>
                            <button className="btn-primary" onClick={() => downloadXLSX(transformedData)}>Export Xlsx</button>
                        </>}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            }

            {
                selectedTab === 5 &&
                <>
                    <DuonutAnalytics />
                </>
            }
            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#4CAF50",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {message}
                    </motion.div>
                )}
                {showErrorMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#DC143C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {error_message}
                    </motion.div>
                )}
            </AnimatePresence>
        </div >
    );
}

export default DuonutReports;