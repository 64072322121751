import React, { useState,  useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { updateLogoutModalActive } from '../../redux/slices/formDetails';
import {  useLocation, useNavigate } from "react-router-dom";

const MenuLeftBar = () => {
    const { isLoggedIn,  } = useSelector((state) => state.user);
    const { credit, total_credit } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openLogout, setopenLogout] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const location = useLocation();

    const handleClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handleClose);

    const handleToggleMenu = () => {
        if (isSmallScreen) {
            setIsCollapsed(!isCollapsed); // Toggle collapse state on small screens
        }
    };

    return (
        <>
            <div
                className='AIDPN1leftContainer'
                style={{
                    fontSize: "17px",
                    width: "100%",
                    minWidth: isHovered ? "14%" : "3%",
                    overflow: "hidden",
                    transition: "all 0.3s ease",
                    display: "flex", flexDirection: "column", alignItems: "center",
                }}
                onClick={handleToggleMenu}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {(location.pathname.includes("/home") || location.pathname.includes("/aidata")) && (
                    <div style={{ marginBottom: '2.5rem', display: "flex", alignItems: "center", gap: "10px" }}>
                        <img style={{ width: "auto", height: "2.5rem", borderRadius: "10px", cursor: "pointer" }}
                            onClick={() => window.location = "/home"}
                            src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
                        {(isCollapsed || isHovered) &&
                            <img style={{ width: "auto", height: "2.5rem", borderRadius: "10px", cursor: "pointer" }}
                                onClick={() => window.location = "/home"}
                                src="/assets/logo-name-white.png" alt="duonut" loading="lazy" />}
                    </div>
                )}
                {isLoggedIn ? (
                    <div style={{
                        ...((location.pathname.includes("/myduonuts") || (location.pathname.includes("/credit")) || (location.pathname.includes("/paneldata"))) &&
                            { marginTop: "3.5rem" })
                    }}>
                        {(location.pathname.includes("/myduonuts") || location.pathname.includes("/credit") || location.pathname.includes("/paneldata")) && (
                            <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                {(isCollapsed || isHovered) && <>
                                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                </>}
                            </div>)}
                        {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                                    (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                        <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                    </div>} */}
                        {(location.pathname.includes("/home") || location.pathname.includes("/aidata")) && (
                            <div className='AIDPN1tabName' onClick={() => navigate("/credit")}
                                style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px", width: "100%" }}>
                                <span style={{ color: "white", fontSize: "14px" }}>
                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                    {total_credit - credit}&nbsp;
                                    {(isCollapsed || isHovered) && (total_credit - 100 !== 0) && <>/ 100 + {total_credit - 100}</>}
                                    {(isCollapsed || isHovered) && (total_credit - 100 === 0) && <>/ 100 </>}
                                </span>
                            </div>
                        )}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: (isCollapsed || isHovered) ? 'flex-start' : 'center',
                        }}>
                            <div className='AIDPN1tabName' onClick={() => navigate("/profile")} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                                <i className="fa fa-cog" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Account Settings</p>}
                            </div>
                            {/* <div className='AIDPN1tabName' onClick={() => { dispatch(toggleUserSettingModalDuonut(true)); }}
                        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                        >
                          Duonut Mapping
                        </div> */}
                            <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")} style={{
                                display: "flex", alignItems: "center", flexDirection: "row", gap: "20px",
                                ...(location.pathname.includes("/myduonuts") && { fontWeight: "600", color: "#337ACD" })
                            }}>
                                <i className="fa fa-list-ul" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>My Duonuts</p>}
                            </div>
                            <div className='AIDPN1tabName' onClick={() => navigate("/credit")} style={{
                                display: "flex", alignItems: "center", flexDirection: "row", gap: "20px",
                                ...(location.pathname.includes("/credit") && { fontWeight: "600", color: "#337ACD" })
                            }}>
                                <i className="fa fa-database" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Credits</p>}
                            </div>
                            <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")} style={{
                                display: "flex", alignItems: "center", flexDirection: "row", gap: "20px",
                                ...(location.pathname.includes("/paneldata") && { fontWeight: "600", color: "#337ACD" })
                            }}>
                                <i className="fa fa-table" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Panels</p>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ position: "absolute", bottom: "0", fontSize: "20px", }}>
                            <div className='AIDPN1tabName' onClick={() => { window.open("https://duonut.com/pricing#pricing_container_list", "_blank"); }} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                                <i className="fa fa-credit-card" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Pricing</p>}
                            </div>
                            <div className='AIDPN1tabName' onClick={() => { window.open("https://duonut.com/contact", "_blank"); }} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                                <i className="fa fa-envelope-o" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Support</p>}
                            </div>
                            <div className='AIDPN1tabName' onClick={() => navigate("/login")} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                                <i className="fa fa-sign-in" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                {(isCollapsed || isHovered) && <p>Log In</p>}
                            </div>
                        </div>
                    </>
                )}


                {isLoggedIn && (
                    <div ref={logoutRef} style={{
                        display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
                    }}>
                        {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
                            Support
                          </div> */}
                        <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", gap: "25px", alignItems: "center" }} onClick={() => setopenLogout(!openLogout)}>
                            <ReactTooltip id="email-hover" place="top" effect="solid" delayHide={900} interactive={true}
                                style={{ fontSize: "12px" }}> </ReactTooltip>
                            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                <i className="fa fa-user-circle-o" aria-hidden="true" style={{ fontSize: "26px" }}></i>
                                {(isCollapsed || isHovered) &&
                                    <div style={{ display: "flex", flexDirection: "column" }} >
                                        <span style={{ cursor: "pointer", }}
                                            data-tooltip-content={
                                                localStorage.getItem("userEmail") || "Email not available"
                                            }
                                            data-tooltip-id="email-hover">
                                            {/* <i className="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i> */}
                                            <p>{localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""}</p>
                                            <p>  {localStorage.getItem("userEmail")
                                                ? localStorage.getItem("userEmail").length > 12
                                                    ? localStorage.getItem("userEmail").substring(0, 12) + '...'
                                                    : localStorage.getItem("userEmail")
                                                : localStorage.getItem("user.email")}
                                            </p>
                                        </span>
                                    </div>
                                }
                            </div>
                            {(isCollapsed || isHovered) &&
                                <div style={{ fontWeight: "600", paddingBottom: "4px" }}>

                                    {/* &nbsp; &nbsp; &nbsp; */}
                                    {openLogout ? (
                                        <i
                                            className="fa fa-angle-down"
                                            aria-hidden="true" style={{ fontSize: "24px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setopenLogout(false);
                                            }}
                                        ></i>
                                    ) : (
                                        <i className="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "24px" }}></i>
                                    )}
                                </div>
                            }
                        </div>
                        {openLogout && (
                            <div className='open-logout'>
                                <ul style={{ textDecoration: "none", width: "100%" }}>
                                    <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                            <i className="fa fa-credit-card" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Pricing
                                        </a>
                                    </li>
                                    <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                            <i className="fa fa-envelope-o" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Support
                                        </a>
                                    </li>
                                    {/* Logout */}
                                    <li onClick={() => dispatch(updateLogoutModalActive(true))} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <i className="fa fa-sign-out" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Log Out
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default MenuLeftBar;