import React, { useState, useEffect, useRef } from 'react';
import './AiDataNew.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, } from "react-router-dom";
import { saveAiNewAsync, showAiNewresponse } from "../../redux/slices/AiNewReducer";
import { createAiDuonutNewAsync, editAiDuonutNewAsync, getConversationSummaryAsync } from "../../redux/slices/AiDuonutNewReducer";
import { toggleAiNewModal, updateAiNewData } from "../../redux/slices/AiSettingNewReducer";
import AiSettingNew from "./AiSettingNew/AiSettingNew";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
// import { toast } from "react-toastify";
import { updateLogoutModalActive } from "../../redux/slices/formDetails";
import { addUserActivity, selectUserActivity, removeUserActivity } from '../../redux/slices/userActivity';
import { createDuonut } from "../../services/activity";
import { frontendUrl } from "../../services/constants";
import { getMyDuonuts } from "../../services/duonut";
// import Payment from '../Credit/CreditPayment/Payment';
import '../Credit/CreditPayment/CreditPayment.css';
// import { Title } from '@mui/icons-material';
import Credit from '../Credit/Credit';
import Panel from '../PanelData/Panel';
import axios from "axios";
import { domain } from "../../services/constants";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';

var duonutId;

const AiDataNew = () => {
    const { isLoggedIn, userProAccess, credit, total_credit } = useSelector((state) => state.user);
    const { settings } = useSelector(state => state.duonutSettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    // const [selectedPage, setselectedPage] = useState(1);
    // const [selectedPage, setselectedPage] = useState(() => {
    //     return parseInt(localStorage.getItem('selectedPage')) || 1; // Default to 1 if no value is found
    // });
    const [websiteData, setwebsiteData] = useState('');
    const [websiteDataText, setwebsiteDataText] = useState('');
    const [websiteDataQuestions, setwebsiteDataQuestions] = useState([]);
    const [selectedAnalyticsQue, setselectedAnalyticsQue] = useState(0);
    const [showConversation, setshowConversation] = useState(0);
    const [toatlAverage, settoatlAverage] = useState(0);
    const [dashboardSummary, setdashboardSummary] = useState('');
    const [ratingData, setratingData] = useState("");
    const [editQuestionId, seteditQuestionId] = useState(0);
    const [editQuestionText, seteditQuestionText] = useState('');
    const [userQuestionText, setuserQuestionText] = useState('');
    const [openLogout, setopenLogout] = useState(false);
    const [aiDataNub, setaiDataNub] = useState(0);
    const [hoverQuestion, sethoverQuestion] = useState(0);
    const [topbar, settopbar] = useState(true);
    const [selectedQuestion, setselectedQuestion] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isDuonut, setisDuonut] = useState(false);

    const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
    const [positive_signal, setPositiveSignal] = useState(0);
    const [negative_signal, setNegativeSignal] = useState(0);

    const [isHovered, setIsHovered] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // const handleMouseEnter = () => setIsHovered(true);
    // const handleMouseLeave = () => setIsHovered(false);
    // const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [showPreview, setShowPreview] = useState(false); // State to control preview visibility

    // const [openPreview, setOpenPreview] = useState(false);
    const [selectedItem, setSelectedItem] = useState(1);
    // const [selectedQuestion, setSelectedQuestion] = useState([]); // manage selected question

    // const handlePreviewClick = () => {
    //     setOpenPreview(!openPreview);  // Toggle preview visibility
    // };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialSelectedPage = queryParams.get('selected') ? parseInt(queryParams.get('selected')) : 1;
    const initialSelectedCompany = queryParams.get('company') ? parseInt(queryParams.get('company')) : 1;

    // Set initial state based on the URL or default to 1
    const [selectedPage, setselectedPage] = useState(initialSelectedPage);
    const [selectedPanelTab, setselectedPanelTab] = useState(1);


    const [aiDuonutData, setaiDuonutData] = useState([])
    const [participantNames, setParticipantNames] = useState({});

    const [reports_tab, setReports_tab] = useState(1);
    const [selectedWhatsappAnalyticsQue, setselectedWhatsappAnalyticsQue] = useState(0);
    const [websiteDataWhatsappQuestions, setwebsiteDataWhatsappQuestions] = useState([]);
    const [showWhatsappConversation, setshowWhatsappConversation] = useState(0);

    const buttonStyles = {
        fontSize: "1rem",
        backgroundColor: selectedQuestion.length === 0 ? "grey" : isHovered ? "#0E2C45" : "#3184c9",
        color: "white",
        border: selectedQuestion.length === 0 ? "1px solid grey" : isHovered ? "1px solid #0E2C45" : "1px solid #3184c9",
        cursor: selectedQuestion.length === 0 ? "not-allowed" : "pointer",
        opacity: selectedQuestion.length === 0 ? 0.6 : 1, // Optional for a subtle disabled effect
    };


    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handelClose);


    // getUserDetails((err, userDetails) => {
    //     if (err) return; 
    //     localStorage.setItem("credit", userDetails.data.credit);
    // });


    const inputRef = useRef(null);
    const handleSave = () => {
        // console.log(editQuestionId);
        if (editQuestionId !== 0) {
            var aiDuonutData = {
                id: editQuestionId,
                question: editQuestionText,
            }
            dispatch(editAiDuonutNewAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    // toast.success(`updating this Duonut`);
                    dispatch(saveAiNewAsync());
                    seteditQuestionId(0);
                    seteditQuestionText("");
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }

    useEffect(() => {
        dispatch(saveAiNewAsync());
    }, []);

    const aiData = useSelector(showAiNewresponse);
    // console.log(aiData);
    const aiData1 = aiData[0];
    // console.log(aiData1);

    useEffect(() => {
        setaiDataNub(parseInt(initialSelectedCompany) - 1);
    }, [initialSelectedCompany]);

    useEffect(() => {
        // console.log("hello");
        // console.log(aiData1);
        if (aiData1) {
            if (aiData1.empty_message) {
                navigate('/home');
            } else {
                // console.log(aiData1);
                setgptQuestionInput(aiData1[aiDataNub].ai_data.websiteUrl);
                setwebsiteData(aiData1[aiDataNub].ai_data.websiteData);
                setwebsiteDataText(aiData1[aiDataNub].ai_data.websiteDataText);
                const aiQueData = aiData1[aiDataNub].ai_duonut_data;
                const sortedData = [...aiQueData].sort((a, b) => a.id - b.id);
                setwebsiteDataQuestions(sortedData);
                // setselectedPage(1);

                // average for dashboard
                var totalAnswers = 0;
                var totalEntries = 0;
                let average;

                aiData1[aiDataNub].ai_duonut_data?.forEach(question => {
                    question.analytics.forEach(analytic => {
                        totalAnswers += parseInt(analytic.answer);
                        totalEntries++;
                    });
                });

                if (totalEntries === 0) {
                    average = 0;
                } else {
                    average = (totalAnswers / totalEntries).toFixed(1);
                }
                // console.log(average, totalAnswers, totalEntries);
                settoatlAverage(average);

                const allratingData = calculateRatingPercentages(aiData1[aiDataNub].ai_duonut_data);
                // console.log(allratingData);
                setratingData(allratingData);
            }
        }
    }, [aiData1, aiDataNub])

    const aiDuonutAnalytics = websiteDataQuestions?.find((data) => data.id === selectedAnalyticsQue);

    const handelSelectTab = (tab) => {
        setselectedPage(tab);
        // setIsHovered(false);
        setselectedAnalyticsQue(0);
        setshowConversation(0);
        setshowWhatsappConversation(0);
        if (selectedPage === 4 || selectedPage === 3 || selectedPage === 2) {
            dispatch(saveAiNewAsync());
        }

        const currentUrl = new URLSearchParams(location.search); // Get current search parameters
        currentUrl.set('selected', tab); // Set the 'selected' query parameter

        // Navigate to the same path with updated query parameters
        navigate({
            pathname: location.pathname, // Keep the current path
            search: currentUrl.toString(), // Add the updated search parameters
        });
    }

    const handelGenerateSummary = () => {
        //  Extract and concatenate AI conversation messages
        const allConversations = websiteDataQuestions.flatMap(item =>
            item.analytics.flatMap(analytic =>
                analytic.ai_conversation ? analytic.ai_conversation.map(conv => conv.message).join(' ') : ''
            )
        ).join(' ');

        // console.log(allConversations);

        var get_ans = {
            allConversations: allConversations,
        }

        dispatch(getConversationSummaryAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.answer) {
                // console.log(res?.answer);
                setdashboardSummary(res?.answer)
            }
        }).catch((e) => {
            console.log(e.message, e);
        });


    }

    // Function to calculate rating percentages
    const calculateRatingPercentages = (data) => {
        const ratings = [1, 2, 3, 4, 5]; // Include all ratings from 1 to 5
        const totalAnswers = data.reduce((acc, item) => acc + item.analytics.length, 0);

        // Count each rating
        const ratingCounts = data.flatMap(item => item.analytics)
            .reduce((acc, { answer }) => {
                acc[answer] = (acc[answer] || 0) + 1;
                return acc;
            }, {});

        // Calculate percentages ensuring the total is 100%
        return ratings.map((rating) => {
            const count = ratingCounts[rating] || 0;
            const percentage = totalAnswers ? ((count / totalAnswers) * 100).toFixed(2) : 0; // Calculate percentage
            return { rating, count, percentage };
        }).sort((a, b) => b.rating - a.rating); // Sort by rating in descending order
    };

    const handleAddUserQuestion = () => {
        let jsonData = [
            [userQuestionText],
        ];

        var ai_Duonut_Data = {
            question_data: jsonData,
            aiData_id: aiData1[aiDataNub]?.ai_data?.id,
        }
        dispatch(createAiDuonutNewAsync(ai_Duonut_Data)).then((res) => {
            // console.log(res);
            if (res.status === 201) {
                dispatch(saveAiNewAsync());
                setuserQuestionText("");
            }

        }).catch((e) => {
            console.log(e.message, e);
        });
    }

    let interval = null;
    const handleCreateDuonut = () => {
        const controller = new AbortController();
        interval = setInterval(() => {
            if (isLoggedIn) {
                setisLoading(true);
                clearInterval(interval);
                createDuonut({ name: "Untitled", duonut_category: 1, duonut_setting: { ...settings, viewer_sliding: true } }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval);
                    if (err) {
                        setisLoading(false);
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;
                    handleCreateMultiSlideDuonut();

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval)
        };
    }

    const handleCreateMultiSlideDuonut = () => {
        selectedQuestion.map((item, i) => {

            const link = `${frontendUrl}/survey/${item}`;

            var masterData = {
                elements: [
                    {
                        id: 1,
                        elementType: 'Iframe',
                        transparency: 1,
                        zIndex: 1,
                        order: 1,
                        posX: 0,
                        posY: 0,
                        height: 575,
                        width: 1024,
                        elementAttributes: {
                            url: link, border: "0px solid #000000"
                        },
                    }
                ],
                backgroundColor: "#ffffff",
                backgroundImage: "",
                name: "Step 1",
            };
            dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
        })
        dispatch(removeUserActivity({ idx: 0 }));
        dispatch(selectUserActivity(0));

        setTimeout(() => {
            setisLoading(false);
            navigate(`/design/${duonutId}`);
        }, 1000);
    }


    useEffect(() => {
        getMyDuonuts((err, res) => {
            if (err) console.log(err.response);
            else {
                if (res.data.length > 0) {
                    setisDuonut(true);
                };
            }
        });
    }, []);


    // Function to fetch participant name
    const getParticipantName = async (id) => {
        const token = localStorage.getItem("access");
        try {
            const response = await axios.post(`${domain}/duonut/get_participant`, {
                profile_id: id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const p_name =
                    response?.data?.respondent_participant?.firstName +
                    " " +
                    response?.data?.respondent_participant?.lastInitial;
                return p_name;
            } else {
                return "Anonymous";
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return "Anonymous";
        }
    };

    // Fetch participant names when analytics data is loaded
    useEffect(() => {
        const fetchNames = async () => {
            const namesMap = {};
            for (const item of aiDuonutAnalytics?.analytics || []) {
                if (item?.url_params?.respondent_profile_id) {
                    const name = await getParticipantName(item.url_params.respondent_profile_id);
                    namesMap[item.url_params.respondent_profile_id] = name;
                }
            }
            setParticipantNames(namesMap);
        };

        fetchNames();
    }, [aiDuonutAnalytics]);

    // const handleMouseEnter = (event) => {
    //     if (!isSmallScreen && event.currentTarget === event.target) {
    //         setIsHovered(true);
    //     }
    // };

    // const handleMouseLeave = (event) => {
    //     if (!isSmallScreen && event.currentTarget === event.target && !selectedPage) {
    //         setIsHovered(false);
    //     }
    // };
    const whatsapp_reports = async () => {
        const token = localStorage.getItem("access");
        const data = { data: aiData1[aiDataNub].ai_data.id };
        console.log(data);
        try {
            const response = await fetch(`${domain}/duonut/get_whatsapp_conversation`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            console.log(responseData);
            if (response.status === 200) {
                setselectedWhatsappAnalyticsQue(1);
                setwebsiteDataWhatsappQuestions(responseData);
                return { success: true, data: responseData };
            } else {
                console.error("Error submitting data:", responseData);
                return { success: false, data: responseData };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, data: error };
        }
    }
    return (
        <div className='AIDPN1App'>
            <div className='duonutContainer' style={{ display: "flex" }}>
                {/* <div className="sidebar-toggle" onClick={() => setIsCollapsed(!isCollapsed)} style={{ paddingTop: "5vh" }}>
                            <i className={`fa ${isCollapsed ? 'fa-times' : 'fa-bars'}`} style={{ color: "#3184c9" }} aria-hidden="true"></i>
                        </div> */}
                {isSmallScreen && (
                    <div
                        className="sidebar-toggle"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        style={{
                            paddingTop: "5vh", cursor: "pointer",
                        }}
                    >
                        <i
                            className={`fa ${!isCollapsed ? 'fa-bars' : 'fa-times'}`}
                            style={{
                                color: isCollapsed ? "white" : "#3184c9",
                            }}
                            aria-hidden="true"
                        ></i>
                    </div>
                )}
                {/* Sidebar */}
                <div
                    className={`AIDPN1leftContainer  ${isHovered || !isCollapsed ? '' : 'collapsed'}`}
                    style={{
                        fontSize: "17px",
                        width: "100%",
                        minWidth: isHovered ? "12%" : "3%",
                        overflow: "hidden", // Hide content when collapsed
                        transition: "all 0.3s ease",
                        display: "flex", flexDirection: "column", alignItems: "center",
                    }}
                    onMouseEnter={() => {
                        if (!isSmallScreen) setIsHovered(true);
                    }}
                    onMouseLeave={() => {
                        if (!isSmallScreen) setIsHovered(false);
                    }}
                >
                    {(isCollapsed || isHovered) ? (
                        <>
                            <div style={{ marginBottom: '2.5rem', display: "flex", alignItems: "center", gap: "10px" }}>
                                <img style={{ width: "auto", height: "2.5rem", borderRadius: "10px", cursor: "pointer" }}
                                    onClick={() => window.location = "/home"}
                                    src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
                                <img style={{ width: "auto", height: "2.5rem", borderRadius: "10px", cursor: "pointer" }}
                                    onClick={() => window.location = "/home"}
                                    src="/assets/logo-name-white.png" alt="duonut" loading="lazy" />
                                <br /><br />

                            </div>
                            {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                                                (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                                </div>} */}
                            {isLoggedIn &&
                                <div>
                                    {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                                        (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                            <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                        </div>} */}
                                    <span style={{
                                        color: 'white',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                        {total_credit - credit}&nbsp;
                                        {(isCollapsed || isHovered) && (total_credit - 100 !== 0) && <>/ 100 + {total_credit - 100}</>}
                                        {(isCollapsed || isHovered) && (total_credit - 100 === 0) && <>/ 100 </>}
                                    </span>
                                    <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }} onClick={() => { navigate("/home") }}>
                                        <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                    </div>
                                    {aiData1 && aiData1?.length > 1 && <div className='AIDPN1tabName'>
                                        <div style={{ padding: "5px 0px" }}>Company Name</div>
                                        <select value={aiDataNub} className='text_editor_dropdown'
                                            style={{
                                                width: "155px",
                                            }}
                                            onChange={e => { setaiDataNub(parseInt(e.target.value)); setdashboardSummary("") }} >
                                            <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled> Select...</option>
                                            {aiData1.map((item, idx) => {
                                                return (
                                                    item?.ai_data?.title ? <option key={idx} value={idx} > {item?.ai_data?.title} </option>
                                                        : <option key={idx} value={idx} > {item?.ai_data?.websiteData?.company_name} </option>
                                                )
                                            }
                                            )}
                                        </select>
                                        {/* 
                        <div className='text_editor_dropdown-options'>
                            <p value={aiDataNub} className='text_editor_dropdown'
                                style={{
                                    width: "155px",
                                }}
                                onChange={e => { setaiDataNub(parseInt(e.target.value)) }} ></p>
                        </div> */}
                                    </div>}
                                    <div className='AIDPN1tabName' onClick={() => { handelSelectTab(3); }} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 3
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class=" fa fa-line-chart" aria-hidden="true" style={{ fontSize: "22px" }}></i> Dashboard
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => { handelSelectTab(1); }} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 1
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class="fa fa-file-text-o" aria-hidden="true" style={{ fontSize: "22px" }}></i> Company Summary
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => { handelSelectTab(2); }} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 2
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class="fa fa-question-circle-o" aria-hidden="true" style={{ fontSize: "22px" }}></i>Questions
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => { handelSelectTab(4); }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            gap: "20px",
                                            ...(selectedPage === 4
                                                ? { fontWeight: "600", color: "#337ACD" }
                                                : {}),
                                        }}
                                    >
                                        {/* <i class="fa-solid fa-chart-diagram"></i> */}
                                        <i class="fa fa-pie-chart" aria-hidden="true" style={{ fontSize: "22px" }}></i>  Reports
                                    </div>
                                    {/* {isDuonut &&  */}
                                    <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                                        <i class="fa fa-list-ul" aria-hidden="true" style={{ fontSize: "22px" }}></i><p>My Duonuts</p>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => navigate("/credit")} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}
                                    // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                                    >
                                        <i class="fa fa-database" aria-hidden="true" style={{ fontSize: "22px" }}></i><p>Credits</p>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")} style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}
                                    // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                                    >
                                        <i class="fa fa-table" aria-hidden="true" style={{ fontSize: "22px" }}></i><p>Panels</p>
                                    </div>
                                </div>
                            }
                            {isLoggedIn ? (
                                <div ref={logoutRef} style={{
                                    display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
                                }}>
                                    {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
                                        Support
                                      </div> */}
                                    <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", gap: "25px", alignItems: "center" }} onClick={() => setopenLogout(!openLogout)}>


                                        <ReactTooltip id="email-hover" place="top" effect="solid" delayHide={900} interactive={true} style={{ fontSize: "12px" }}> </ReactTooltip>

                                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                            <i class="fa fa-user-circle-o" aria-hidden="true" style={{ fontSize: "26px" }}></i>
                                            <div style={{ display: "flex", flexDirection: "column" }} >
                                                <span style={{ cursor: "pointer", }}
                                                    data-tooltip-content={
                                                        localStorage.getItem("userEmail") || "Email not available"
                                                    }
                                                    data-tooltip-id="email-hover">
                                                    {/* <i class="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i> */}
                                                    <p>{localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""}</p>
                                                    <p>  {localStorage.getItem("userEmail")
                                                        ? localStorage.getItem("userEmail").length > 12
                                                            ? localStorage.getItem("userEmail").substring(0, 12) + '...'
                                                            : localStorage.getItem("userEmail")
                                                        : localStorage.getItem("user.email")}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ fontWeight: "600", paddingBottom: "4px" }}>

                                            {/* &nbsp; &nbsp; &nbsp; */}
                                            {openLogout ? (
                                                <i
                                                    className="fa fa-angle-down"
                                                    aria-hidden="true" style={{ fontSize: "24px" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setopenLogout(false);
                                                    }}
                                                ></i>
                                            ) : (
                                                <i className="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "24px" }}></i>
                                            )}
                                        </div>
                                    </div>
                                    {openLogout && (
                                        <div className='open-logout'>
                                            <ul style={{ textDecoration: "none", width: "100%" }}>
                                                <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                        <i className="fa fa-credit-card" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Pricing
                                                    </a>
                                                </li>
                                                <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                        <i class="fa fa-envelope-o" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Support
                                                    </a>
                                                </li>
                                                {/* Logout */}
                                                <li onClick={() => dispatch(updateLogoutModalActive(true))} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <i className="fa fa-sign-out" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Log Out
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div style={{ position: "absolute", bottom: "0", fontSize: "22px", left: "0" }}>
                                    <ul style={{ textDecoration: "none", width: "100%", margin: "20px", display: "flex", flexDirection: "column", gap: "15px" }}>
                                        <li>
                                            <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                <i className="fa fa-credit-card" aria-hidden="true"></i>&nbsp; Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp; Support
                                            </a>
                                        </li>
                                        {/* LogIn */}
                                        <li onClick={() => window.location.href = '/login'} style={{ cursor: "pointer" }}>
                                            <i className="fa fa-sign-in" aria-hidden="true"></i>&nbsp; Log In
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </>) : (
                        <>
                            <div style={{ marginBottom: '2.5rem', display: "flex", alignItems: "center" }}>
                                <img style={{ width: "auto", height: "2.5rem", borderRadius: "10px", cursor: "pointer" }}
                                    onClick={() => window.location = "/home"}
                                    src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />

                            </div>
                            {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                                                (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                                                </div>} */}

                            {isLoggedIn &&
                                <div>
                                    <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }} onClick={() => { navigate("/home") }}>
                                        {/* <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i> */}
                                    </div>
                                    {/* {aiData1 && aiData1?.length > 1 && <div className='AIDPN1tabName'>
                                        <div style={{ padding: "5px 0px" }}>Company Name</div>
                                        <select value={aiDataNub} className='text_editor_dropdown'
                                            style={{
                                                width: "155px",
                                            }}
                                            onChange={e => { setaiDataNub(parseInt(e.target.value)); setdashboardSummary("") }} >
                                            <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled> Select...</option>
                                            {aiData1.map((item, idx) => {
                                                return (
                                                    item?.ai_data?.title ? <option key={idx} value={idx} > {item?.ai_data?.title} </option>
                                                        : <option key={idx} value={idx} > {item?.ai_data?.websiteData?.company_name} </option>
                                                )
                                            }
                                            )}
                                        </select>
                                        {/* 
                        <div className='text_editor_dropdown-options'>
                            <p value={aiDataNub} className='text_editor_dropdown'
                                style={{
                                    width: "155px",
                                }}
                                onChange={e => { setaiDataNub(parseInt(e.target.value)) }} ></p>
                        </div> 
                                    </div>} */}
                                    <span style={{
                                        color: 'white',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                        {total_credit - credit}&nbsp;
                                        {(isCollapsed || isHovered) && (total_credit - 100 !== 0) && <>/ 100 + {total_credit - 100}</>}
                                        {(isCollapsed || isHovered) && (total_credit - 100 === 0) && <>/ 100 </>}
                                    </span>
                                    <div className='AIDPN1tabName' onClick={() => handelSelectTab(3)} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 3
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class=" fa fa-line-chart" aria-hidden="true" style={{ fontSize: "22px" }}></i>

                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => handelSelectTab(1)} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 1
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class="fa fa-file-text-o" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => handelSelectTab(2)} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 2
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class="fa fa-question-circle-o" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => handelSelectTab(4)} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "20px",
                                        ...(selectedPage === 4
                                            ? { fontWeight: "600", color: "#337ACD" }
                                            : {}),
                                    }}
                                    >
                                        <i class="fa fa-pie-chart" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>

                                    {/* <div className='AIDPN1tabName' onClick={() => { dispatch(toggleUserSettingModalDuonut(true)); }}
                                    // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                                    >
                                      Duonut Mapping
                                    </div> */}
                                    {/* {isDuonut &&  */}
                                    <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")} >
                                        <i class="fa fa-list-ul" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => navigate("/credit")}
                                    // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                                    >
                                        <i class="fa fa-database" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>
                                    <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")}
                                    // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                                    >
                                        <i class="fa fa-table" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                                    </div>
                                </div>
                            }
                            {isLoggedIn ? (
                                <div ref={logoutRef} style={{
                                    display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
                                }}>
                                    {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
                                        Support
                                      </div> */}
                                    <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", gap: "10px" }} onClick={() => setopenLogout(!openLogout)}>


                                        <div >
                                            <i class="fa fa-user-circle-o" aria-hidden="true" style={{ fontSize: "26px" }}></i>

                                        </div>

                                    </div>
                                    {openLogout && (
                                        <div className='open-logout'>
                                            <ul style={{ textDecoration: "none", width: "100%" }}>
                                                <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                        <i className="fa fa-credit-card" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Pricing
                                                    </a>
                                                </li>
                                                <li style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                        <i class="fa fa-envelope-o" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Support
                                                    </a>
                                                </li>
                                                <li onClick={() => dispatch(updateLogoutModalActive(true))} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <i className="fa fa-sign-out" aria-hidden="true" style={{ fontSize: "20px" }}></i>&nbsp; Log Out
                                                </li>
                                                {/* <li>
                                                        <i class="fa fa-id-badge" aria-hidden="true"></i>  {localStorage.getItem("userEmail")}
                                                </li> */}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div style={{ position: "absolute", bottom: "0", fontSize: "22px", left: "0" }}>
                                        <ul style={{ textDecoration: "none", width: "100%", margin: "20px", display: "flex", flexDirection: "column", gap: "15px" }}>
                                            <li>
                                                <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                    <i className="fa fa-credit-card" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li onClick={() => window.location.href = '/login'} style={{ cursor: "pointer" }}>
                                                <i className="fa fa-sign-in" aria-hidden="true"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                </div>
                <div className='AIDPN1rightContainer' id="scrollableDiv" style={{ minWidth: "90%" }}>
                    {!userProAccess && topbar && <div style={{ position: "absolute", top: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "75.8vw", textAlign: "center" }}>
                        You’ve reached your monthly response limit. Upgrade now to unlock more conversations and features.
                        <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
                            onClick={() => window.open('https://duonut.com/pricing')} >Upgrade Now</button>
                        <label onClick={() => settopbar(false)}
                            style={{ position: "absolute", right: "20px", top: '14px', cursor: "pointer" }}>
                            <img style={{ width: '14px' }} src="/assets/icons/close-white.svg" /></label>
                    </div>}

                    {selectedPage === 1 && <> {websiteData.company_name ? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDPN1container1' style={{ marginBottom: "7rem" }}>
                            <div><strong>Company:</strong> {websiteData.company_name}</div>
                            <div><strong>Category:</strong> {websiteData.category}</div>
                            <div><strong>Summary:</strong> {websiteData.summary}</div>
                            <div>
                                <div><strong>Products and Services</strong></div>
                                {websiteData?.products_services?.map((product, index) => (
                                    <div key={index}>
                                        <li>{product.name}</li>
                                        <p><strong>Features: </strong> {Array.isArray(product.features) ? product.features.join(', ') : product.features}</p>
                                        <p><strong>Benefits: </strong>{Array.isArray(product.benefits) ? product.benefits.join(', ') : product.benefits} </p>
                                        <p><strong>Unique Selling Points: </strong>{Array.isArray(product.unique_selling_points) ? product.unique_selling_points.join(', ') : product.unique_selling_points} </p>

                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='AIDPN1container1' style={{ position: "fixed", bottom: "0", margin: "0px 0px 0rem 0px", padding: "8px", width: "75.8vw", alignItems: "center" }}>

                            <button className='AIDPN1button' style={{ fontSize: "1rem" }} onClick={() => setselectedPage(2)}>Click here to review questions</button>
                        </div>
                    </div>
                        : <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <div className='AIDPN1container1' style={{ marginBottom: "7rem" }}>
                                <div><strong>Title:</strong> {websiteData.title}</div>
                                <div><strong>Summary:</strong> {websiteData.summary}</div>
                                <div>
                                    <div><strong>Products and Services</strong></div>
                                    {websiteData?.products_services?.map((product, index) => (
                                        <div key={index}>
                                            <li>{product.name}</li>
                                            <p><strong>Features: </strong> {Array.isArray(product.features) ? product.features.join(', ') : product.features}</p>
                                            <p><strong>Benefits: </strong>{Array.isArray(product.benefits) ? product.benefits.join(', ') : product.benefits} </p>

                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='AIDPN1container1' style={{ position: "fixed", bottom: "0", margin: "0px 0px 0rem 0px", padding: "8px", width: "75.8vw", alignItems: "center" }}>

                                <button className='AIDPN1button' style={{ fontSize: "1rem" }} onClick={() => setselectedPage(2)}>Click here to review questions</button>
                            </div>
                        </div>}
                        {/* <div className='coming-soon' style={{ position: "fixed", bottom: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "75.8vw", textAlign: "center" }}>
                            Coming soon: Upload internal documents, PDFs, FAQs, and more as data sources to generate customer-focused questions.
                        </div> */}
                        {/* {!isCollapsed && (
                            <div className='coming-soon' style={{ position: "fixed", bottom: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "75.8vw", textAlign: "center" }}>
                                Coming soon: Upload internal documents, PDFs, FAQs, and more as data sources to generate customer-focused questions.
                            </div>
                        )} */}

                    </>}

                    {selectedPage === 2 && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDPN1container2'>
                            {/* {websiteDataQuestions?.map((item, index) => ( */}
                            <div
                                // key={index} 
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem" }}>
                                <h2 style={{ flex: 1, textAlign: "center", fontSize: "1.5rem", fontWeight: "bold", margin: 0 }}>
                                    Questions
                                </h2>
                                {/* <button
                                    className="AIDPN1button"
                                    style={buttonStyles}
                                    disabled={selectedQuestion.length === 0}  // Disable button if no questions are selected
                                    onClick={() => {
                                        if (selectedQuestion.length > 0) {
                                            // Only trigger modal if questions are selected
                                            dispatch(toggleAiNewModal(true));
                                            // dispatch(updateAiNewData(item));
                                            // No need to pass "item" here since it's handled inside the map loop
                                            setOpenPreview(true);  // Open the preview section when clicking
                                        }
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    Preview
                                </button> */}
                            </div>

                            {/* ))} */}

                            <h4 style={{ fontSize: "14px", textAlign: "center", fontWeight: "500", color: "#999" }}>Each question offers shareable links for SMS or email, embeddable iFrame or HTML code,<br /> and QR codes for signage or events to collect feedback</h4>
                            <ul class="AIDPN1question-list">
                                {websiteDataQuestions?.map((item, index) => (
                                    <li className="AIDPN1question-item" key={index}
                                        onMouseEnter={() => sethoverQuestion(item.id)}
                                        onMouseLeave={() => sethoverQuestion(0)}>

                                        {editQuestionId === item.id ? (
                                            <input
                                                type='text'
                                                ref={inputRef}
                                                id={`aiQuestionInput${item.id}`}
                                                value={editQuestionText}
                                                style={{
                                                    width: "90%",
                                                    minHeight: "30px",
                                                    backgroundColor: "transparent",
                                                    border: editQuestionId === item.id ? "1px solid black" : "0",
                                                    borderRadius: "5px",
                                                    fontSize: "1rem",
                                                    padding: "5px"
                                                }}
                                                onChange={(e) => seteditQuestionText(e.target.value)}
                                                readOnly={editQuestionId === item.id ? false : true}
                                            />
                                        ) : (<div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                            <input type="checkbox" id={"question_checkbox" + index} name="question_checkbox"
                                                style={{ width: "1rem", height: "1rem", minWidth: "1rem", minHeight: "1rem", marginRight: "10px" }}
                                                checked={selectedQuestion.includes(item.display_id)}
                                                onChange={() => {
                                                    if (selectedQuestion.includes(item.display_id)) {
                                                        // Remove item from the array
                                                        setselectedQuestion(selectedQuestion.filter(q => q !== item.display_id));
                                                    } else {
                                                        // Add item to the array
                                                        setselectedQuestion([...selectedQuestion, item.display_id]);
                                                    }
                                                }} />
                                            <label>
                                                {item.question} &nbsp;
                                                <span
                                                    onClick={() => {
                                                        seteditQuestionId(item.id);
                                                        seteditQuestionText(item.question);
                                                    }}
                                                    className="AIDPN1icon"
                                                >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                        </div>
                                        )}

                                        {/* Floppy icon always visible */}
                                        {editQuestionId === item.id ? (
                                            <span onClick={() => handleSave()} className="AIDPN1icon">
                                                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                            </span>
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    dispatch(toggleAiNewModal(true));
                                                    dispatch(updateAiNewData(item));
                                                }}
                                                className="AIDPN1icon"
                                            // style={{ display: "flex", flexDirection: "row", justifContent: "center", alignItems: "center" }}
                                            >
                                                <button className='AIDPN1button-publish' style={{ fontSize: "1rem", margin: "auto" }}>Publish</button>
                                            </span>
                                        )}
                                    </li>
                                ))}


                            </ul>
                        </div>

                        <div className='AIDPN1container2' style={{ flexDirection: "row", justifyContent: "space-between", margin: selectedQuestion.length >= 1 ? "0px 0px 1rem 0px" : "0px 0px 1rem 0px", alignItems: "center" }}>
                            <input className='AIDVP1Ai-input_box'
                                style={{ padding: "8px", maxWidth: "52vw" }}
                                value={userQuestionText}
                                id="question-input_box"
                                type="text"
                                placeholder="Write your own question here..."
                                onChange={e => setuserQuestionText(e.target.value)}
                            />
                            <button className='GPT-submitBtn' style={{ fontSize: "1rem" }} onClick={() => handleAddUserQuestion()}>Add Your Custom Question</button>
                        </div>

                        {selectedQuestion.length >= 1 &&
                            <div className='AIDPN1container2' style={{ flexDirection: "row", justifyContent: "space-between", margin: "0px 0px 1rem 0px", alignItems: "center" }}>
                                <button className='AIDPN1button' style={{ fontSize: "1rem", margin: "auto" }} onClick={() => handleCreateDuonut()}>{!isLoading ? "Create Multiple slide Surveys" : "Creating Multiple slide Surveys..."}</button>
                            </div>
                        }

                    </div>}

                    {selectedPage === 3 && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDPN1container2'>
                            <h2 style={{ margin: "auto", paddingBottom: "1rem" }}>{websiteData.company_name} Dashboard</h2>
                            <div className='AIDPN1reportAnalyticsContainer-top'>
                                <div className='AIDPN1reportAnalyticsContainer'>
                                    <label><label>Insights</label><h3 style={{ marginTop: "10px" }}>
                                        <br />
                                        {
                                            websiteDataQuestions.reduce((total, question) => {
                                                return total + question.analytics.length;
                                            }, 0)
                                        }</h3></label>
                                </div>
                                <div className='AIDPN1reportAnalyticsContainer'>
                                    <label><label>Avg Score</label><h3 style={{ marginTop: "10px" }}><br />
                                        {toatlAverage}
                                    </h3></label>
                                </div>
                                <div className='AIDPN1reportAnalyticsContainer'>
                                    <label><label style={{ marginRight: "10px" }}>Total Positive Signals</label><h3 style={{ marginTop: "10px" }}>
                                        {
                                            websiteDataQuestions.reduce((total, question) => {
                                                return total + question.analytics.reduce((acc, curr) => acc + (curr.positive_signal || 0), 0);
                                            }, 0)
                                        }
                                    </h3></label>
                                </div>
                                <div className='AIDPN1reportAnalyticsContainer'>
                                    <label><label style={{ marginRight: "10px" }}>Total Negative Signals</label><h3 style={{ marginTop: "10px" }}>
                                        {
                                            websiteDataQuestions.reduce((total, question) => {
                                                return total + question.analytics.reduce((acc, curr) => acc + (curr.negative_signal || 0), 0);
                                            }, 0)
                                        }
                                    </h3></label>
                                </div>
                            </div>

                            <div className='AIDPN1DashboardContainerItem' style={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div className="AS1row">
                                        <h3>Here’s what your users have to say.</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>View AI generated summary from your customers feedback.</label>
                                    </div>
                                    {!dashboardSummary && <button className='AIDPN1button' style={{ fontSize: "1rem" }} disabled={toatlAverage === 0} onClick={() => handelGenerateSummary()}>Generate Summary</button>}
                                </div>
                                {dashboardSummary && <div>{dashboardSummary}</div>}
                            </div>

                            <div className='AIDPN1DashboardContainerItem' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <h3>{websiteData.company_name} overall NPS rating -</h3>
                                {ratingData?.length > 1 && ratingData?.map(({ rating, percentage }) => {
                                    {/* console.log(rating) */ }
                                    if (percentage === '0.00') return null;

                                    return <div key={rating} style={{ marginBottom: '10px' }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <div> {rating} </div>
                                            <div> {percentage}% </div>
                                        </div>
                                        <div
                                            style={{
                                                width: `100%`,
                                                height: "10px",
                                                backgroundColor: "#f4f4f5",
                                                borderRadius: "4px",
                                                margin: "5px 0px"
                                            }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: `${percentage}%`,
                                                    height: "10px",
                                                    backgroundColor: "#3184c9",
                                                    borderRadius: "4px",
                                                }}
                                                aria-valuenow={percentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>}

                    {selectedPage === 4 && (
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <div className='AIDPN1container2'>
                                <h3 style={{ margin: "auto", paddingBottom: "1rem" }}>{websiteData.company_name} Ratings on Individual Questions</h3>
                                <div className="LeftSidebarContainer" style={{ borderTop: "2px solid #dbdfe8" }}>
                                    <div className="LeftSidebarTab" onClick={() => setReports_tab(1)}>Individual Questions
                                        {reports_tab === 1 && <div className="selected-line"></div>}
                                    </div>
                                    <div className="LeftSidebarTab" onClick={() => { setReports_tab(2); whatsapp_reports(); }}>Individual Number
                                        {reports_tab === 2 && <div className="selected-line"></div>}
                                    </div>
                                </div>
                                {reports_tab === 1 && selectedAnalyticsQue === 0 && (
                                    <div style={{ margin: "auto" }}>
                                        {websiteDataQuestions && websiteDataQuestions.every(item => item.analytics?.length === 0) ? (
                                            <div style={{ margin: "4rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                                <img style={{ width: '300px' }} src="/assets/report.gif" loading="lazy" />
                                                <div>Reports will be generated after you share questions with your users. Currently, no reports are available.</div>
                                            </div>
                                        ) : (
                                            websiteDataQuestions?.map((item, index) => {
                                                if (item.analytics?.length === 0) return null;

                                                // Calculate the average NPS score
                                                const totalAnswers = item.analytics.reduce((acc, curr) => acc + parseInt(curr.answer), 0);
                                                let avgScore = item.analytics.length ? (totalAnswers / item.analytics.length).toFixed(1) : 0;
                                                const positiveSignalCount = item.analytics.reduce((acc, curr) => acc + (curr.positive_signal || 0), 0);
                                                const negativeSignalCount = item.analytics.reduce((acc, curr) => acc + (curr.negative_signal || 0), 0);

                                                return (
                                                    <div key={index} className='AIDPN1reportContainer' onClick={() => setselectedAnalyticsQue(item.id)}>
                                                        <div>{item.question}</div>
                                                        <div className='AIDPN1reportContainerItem'>
                                                            <label style={{ display: "flex" }}><b style={{ marginRight: "10px" }}>Insights:</b><h3>{item.analytics.length}</h3></label>
                                                            <label style={{ display: "flex" }}><b style={{ marginRight: "10px" }}>Avg Score: </b><h3>{avgScore}</h3></label>
                                                            <div style={{ display: "flex" }}>
                                                                <label style={{ display: "flex", alignItems: "center", borderRadius: "10px", marginRight: "10px", background: "#ccffeb", padding: "5px 10px" }}>
                                                                    <label style={{ marginRight: "10px", color: "green" }}>
                                                                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
                                                                        {/* Positive Signal */}
                                                                    </label>
                                                                    <h3 style={{ color: "green" }}>{positiveSignalCount}</h3>
                                                                </label>
                                                                <label style={{ display: "flex", alignItems: "center", borderRadius: "10px", marginRight: "10px", background: "#ffcccc", padding: "5px 10px" }}>
                                                                    <label style={{ marginRight: "10px", color: "red" }}>
                                                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;
                                                                        {/* Negative Signal */}
                                                                    </label>
                                                                    <h3 style={{ color: "red" }}>{negativeSignalCount}</h3>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                )}
                                {reports_tab === 2 && selectedWhatsappAnalyticsQue === 0 && (
                                    <div style={{ margin: "auto" }}>
                                        {websiteDataWhatsappQuestions && (
                                            <div style={{ margin: "4rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                                <img style={{ width: '300px' }} src="/assets/report.gif" loading="lazy" />
                                                <div>Reports will be generated after you share questions with your users. Currently, no reports are available.</div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {selectedAnalyticsQue > 0 && reports_tab === 1 && (
                                    <div style={{ width: "100%" }}>
                                        <div className='ADP1backBtn' style={{ padding: "0", alignItems: "unset", margin: '1rem 0rem' }} onClick={() => { setselectedAnalyticsQue(0); setshowConversation(0); }}>
                                            <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                                        </div>
                                        <h3>{aiDuonutAnalytics.question}</h3>
                                        <div style={{ display: "flex" }} className='AIDPN1reportAnalyticsContainer-all'>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Insights<h3>{aiDuonutAnalytics.analytics.length}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Avg Score<h3>{aiDuonutAnalytics?.analytics?.length === 0 ? 0 : (aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + parseInt(curr.answer), 0) / aiDuonutAnalytics.analytics.length).toFixed(1)}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Positive Signal<h3>{aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + (curr.positive_signal || 0), 0)}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Negative Signal<h3>{aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + (curr.negative_signal || 0), 0)}</h3></label>
                                            </div>
                                        </div>

                                        <ul className="AIDPN1question-list">
                                            {aiDuonutAnalytics?.analytics?.map((item, index) => (
                                                <li className="AIDPN1analytics-item-container" key={index}>
                                                    <div className="AIDPN1analytics-item">
                                                        <label>{item.name ? item.name : "Anonymous"}</label>
                                                        {item?.url_params?.respondent_profile_id && <label>
                                                            Respondent Participant -{" "}
                                                            {participantNames[item.url_params.respondent_profile_id] || "Loading..."}
                                                        </label>}
                                                        <label style={{ display: "flex" }}><label style={{ marginRight: "10px" }}>Score</label><h3>{item.answer}</h3></label>
                                                        {item.positive_signal > 0 && (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "green" }}>
                                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                                                </label>
                                                                {/* <h3 style={{ color: "green" }}>{item.positive_signal}</h3> */}
                                                            </label>
                                                        )}

                                                        {item.negative_signal > 0 && (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "red" }}>
                                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                                </label>
                                                                {/* <h3 style={{ color: "red" }}>{item.negative_signal}</h3> */}
                                                            </label>
                                                        )}

                                                        <label style={{ cursor: "pointer" }} onClick={() => { (showConversation === 0 || showConversation !== item.id) ? setshowConversation(item.id) : setshowConversation(0) }}>
                                                            See full conversation
                                                            <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (showConversation === 0 || showConversation !== item.id) ? "rotate(0deg)" : "rotate(180deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                                        </label>
                                                    </div>
                                                    {showConversation === item.id && (
                                                        <div className='AIDVP1chat-container'>
                                                            {item?.ai_conversation?.map((item, index) => (
                                                                <div key={index} className={`AIDVP1message-row ${item.sender}`}>
                                                                    <img
                                                                        src={item.sender === "bot" ? "/assets/duonut_icon.png" : "/assets/user.png"}
                                                                        alt={`${item.sender}-avatar`}
                                                                        className="AIDVP1avatar"
                                                                    />
                                                                    <div className="AIDVP1message">
                                                                        {item.message.split('\n').map((str, idx, arr) => {
                                                                            const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)|(\*\*(?!\[.*\]\(.*\))([^*]+)\*\*)/g;

                                                                            const parts = [];
                                                                            let lastIndex = 0;
                                                                            let match;

                                                                            while ((match = linkRegex.exec(str)) !== null) {
                                                                                if (match.index > lastIndex) {
                                                                                    parts.push(str.substring(lastIndex, match.index));
                                                                                }

                                                                                if (match[1] && match[2]) {
                                                                                    parts.push(
                                                                                        <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
                                                                                            {match[1]}
                                                                                        </a>
                                                                                    );
                                                                                } else if (match[3]) {
                                                                                    parts.push(
                                                                                        <a key={match.index} href={match[3]} target="_blank" rel="noopener noreferrer">
                                                                                            {match[3]}
                                                                                        </a>
                                                                                    );
                                                                                } else if (match[4]) {
                                                                                    parts.push(<strong key={match.index}>{match[4]}</strong>);
                                                                                }

                                                                                lastIndex = match.index + match[0].length;
                                                                            }

                                                                            if (lastIndex < str.length) {
                                                                                parts.push(str.substring(lastIndex));
                                                                            }

                                                                            if (parts.length === 0) return null;

                                                                            return (
                                                                                <React.Fragment key={idx}>
                                                                                    <p>{parts}</p>
                                                                                    {idx < arr.length - 1 && <br />}
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {selectedWhatsappAnalyticsQue > 0 && reports_tab === 2 && (
                                    <div style={{ width: "100%" }}>
                                        {/* <div className='ADP1backBtn' style={{ padding: "0", alignItems: "unset", margin: '1rem 0rem' }} onClick={() => { selectedWhatsappAnalyticsQue(0); setshowWhatsappConversation(0); }}>
                                            <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                                        </div> */}
                                        {/* <h3>{aiDuonutAnalytics.question}</h3> */}
                                        {/* <div style={{ display: "flex" }} className='AIDPN1reportAnalyticsContainer-all'>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Insights<h3>{aiDuonutAnalytics.analytics.length}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Avg Score<h3>{aiDuonutAnalytics?.analytics?.length === 0 ? 0 : (aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + parseInt(curr.answer), 0) / aiDuonutAnalytics.analytics.length).toFixed(1)}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Positive Signal<h3>{aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + (curr.positive_signal || 0), 0)}</h3></label>
                                            </div>
                                            <div className='AIDPN1reportAnalyticsContainer'>
                                                <label>Negative Signal<h3>{aiDuonutAnalytics.analytics.reduce((acc, curr) => acc + (curr.negative_signal || 0), 0)}</h3></label>
                                            </div>
                                        </div> */}
                                        <ul className="AIDPN1question-list">
                                            {websiteDataWhatsappQuestions?.map((item, index) => (
                                                <li className="AIDPN1analytics-item-container" key={index}>
                                                    <div className="AIDPN1analytics-item">
                                                        <label>{item.whatsapp_no ? item.whatsapp_no : "Anonymous"}</label>
                                                        {/* {item?.url_params?.respondent_profile_id && <label>
                                                            Respondent Participant -{" "}
                                                            {participantNames[item.url_params.respondent_profile_id] || "Loading..."}
                                                        </label>} */}
                                                        {/* <label style={{ display: "flex" }}><label style={{ marginRight: "10px" }}>Score</label><h3>{item.answer}</h3></label>
                                                        {item.positive_signal > 0 && (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "green" }}>
                                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                                                </label>
                                                                {/* <h3 style={{ color: "green" }}>{item.positive_signal}</h3> 
                                                            </label>
                                                        )}

                                                        {item.negative_signal > 0 && (
                                                            <label style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ marginRight: "10px", color: "red" }}>
                                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                                </label>
                                                                {/* <h3 style={{ color: "red" }}>{item.negative_signal}</h3> 
                                                            </label>
                                                        )} */}

                                                        <label style={{ cursor: "pointer" }} onClick={() => { (showWhatsappConversation === 0 || showWhatsappConversation !== item.id) ? setshowWhatsappConversation(item.id) : setshowWhatsappConversation(0) }}>
                                                            See full conversation
                                                            <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (showWhatsappConversation === 0 || showWhatsappConversation !== item.id) ? "rotate(0deg)" : "rotate(180deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                                        </label>
                                                    </div>
                                                    {showWhatsappConversation === item.id && (
                                                        <div className="AIDVP1chat-container">
                                                            {Array.isArray(item.ai_conversation) && item.ai_conversation.length > 0 ? (
                                                                item.ai_conversation.map((message, idx) => (
                                                                    <div key={idx} className={`AIDVP1message-row ${message.sender}`}>
                                                                        <img
                                                                            src={message.sender === "bot" ? "/assets/duonut_icon.png" : "/assets/user.png"}
                                                                            alt={`${message.sender}-avatar`}
                                                                            className="AIDVP1avatar"
                                                                        />
                                                                        <div className="AIDVP1message">
                                                                            {message.message.split('\n').map((str, idx, arr) => {
                                                                                const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)|(\*\*(?!\[.*\]\(.*\))([^*]+)\*\*)/g;
                                                                                const parts = [];
                                                                                let lastIndex = 0;
                                                                                let match;

                                                                                while ((match = linkRegex.exec(str)) !== null) {
                                                                                    if (match.index > lastIndex) {
                                                                                        parts.push(str.substring(lastIndex, match.index));
                                                                                    }

                                                                                    if (match[1] && match[2]) {
                                                                                        parts.push(
                                                                                            <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
                                                                                                {match[1]}
                                                                                            </a>
                                                                                        );
                                                                                    } else if (match[3]) {
                                                                                        parts.push(
                                                                                            <a key={match.index} href={match[3]} target="_blank" rel="noopener noreferrer">
                                                                                                {match[3]}
                                                                                            </a>
                                                                                        );
                                                                                    } else if (match[4]) {
                                                                                        parts.push(<strong key={match.index}>{match[4]}</strong>);
                                                                                    }

                                                                                    lastIndex = match.index + match[0].length;
                                                                                }

                                                                                if (lastIndex < str.length) {
                                                                                    parts.push(str.substring(lastIndex));
                                                                                }

                                                                                return (
                                                                                    <React.Fragment key={idx}>
                                                                                        <p>{parts}</p>
                                                                                        {idx < arr.length - 1 && <br />}
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>No conversation available.</p>
                                                            )}
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                    {selectedPage === 5 &&
                        <Credit />}

                    {selectedPage === 6 &&
                        <Panel />
                    }

                </div>

                <AiSettingNew />
            </div>
        </div >
    );

}

export default AiDataNew;